import React, {useEffect, useState} from "react";
import Selector from "../../../components/elements/Selector/Selector";
import moment from "moment/moment";
import RangePicker from "../../../components/RangePicker/RangePicker";
import Button from "../../../components/elements/Button/Button";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import {toast} from "react-toastify";

export default function Export() {
    const jwt = localStorage.getItem('jwt')

    const [loading, setLoading] = useState(true)
    const [exportLoading, setExportLoading] = useState(false)
    const [type, setType] = useState('')
    const [company, setCompany] = useState(0)
    const [companies, setCompanies] = useState(false)
    const [startDate, setStartDate] = useState(new Date(moment().startOf('year')))
    const [endDate, setEndDate] = useState(new Date(moment().endOf('year')))

    useEffect(() => {
        getCompanies()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCompanies = async () => {
        axios.get(process.env.REACT_APP_API + '/companies', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                response.data.forEach(item => {
                    temp.push({
                        value: item.id,
                        label: item.title
                    })
                })
                setCompanies(temp)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err)
            })
    }
    const exportDoc = (e) => {
        e.preventDefault()
        if (type === '' || (type === 'oferted_products' && company === 0)) {
            toast.error('Vă rugăm să completați toate câmpurile!')
            return
        }
        setExportLoading(true)
        let data = {}
        data.type = type
        if (type === 'oferted_products') {
            data.company = company
            data.start = startDate
            data.end = endDate
        }
        fetch(process.env.REACT_APP_API + '/companies/export', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
        })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "Stoc.xlsx";

                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
                setExportLoading(false)
            })
            .catch(e => console.log(e))
            .catch(e => console.log(e))
    }
    if (loading) {
        return <LoadingComp/>
    }
    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>Export</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card">
                        <form onSubmit={(e) => exportDoc(e)}>
                            <div className={"header"}>
                                <div className={"row"}>
                                    <div className={"col-md-12 col-sm-12"}>
                                        <p>Export</p>
                                    </div>
                                </div>
                            </div>
                            <div className={"content"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Tip<span
                                                className={"form-required"}>*</span></div>
                                            <Selector
                                                options={[{
                                                    value: 'stock',
                                                    label: 'Stoc'
                                                }, {
                                                    value: 'oferted_products',
                                                    label: 'Produse ofertate'
                                                }]}
                                                value={type}
                                                required
                                                onChange={(option) => setType(option.value)}
                                                isSearchable={false}
                                                isOptionDisabled={(option) => option.disabled}
                                            />

                                        </div>
                                    </div>
                                    {type === 'oferted_products' && <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Companie<span
                                                className={"form-required"}>*</span></div>
                                            <Selector
                                                options={companies}
                                                required
                                                value={company}
                                                onChange={(option) => setCompany(option.value)}
                                                isSearchable={false}
                                                isOptionDisabled={(option) => option.disabled}
                                            />

                                        </div>
                                    </div>}
                                    {type === 'oferted_products' && <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Perioada<span
                                                className={"form-required"}>*</span></div>
                                            <RangePicker
                                                startDate={startDate}
                                                endDate={endDate}
                                                setEndDate={setEndDate}
                                                setStartDate={setStartDate}
                                            />

                                        </div>
                                    </div>}
                                </div>

                            </div>

                            <div className={"footer d-flex justify-content-center"}>
                                <Button
                                    className={"btn-secondary mt-2 ms-auto " + (exportLoading ? 'disabled' : '')}
                                    icon={"fa-regular fa-floppy-disk"}
                                    value={'Descarcă'}
                                    disabled={exportLoading}
                                    type={"submit"}
                                    loading={exportLoading}
                                />
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
}

