import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import Paginate from "../../../components/elements/Paginate/Paginate";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import ModalComp from "../../../components/ModalComp/ModalComp";
import Selector from "../../../components/elements/Selector/Selector";

export default function Acquisitions() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [acquisitions, setAcquisitions] = useState([])
    const [search, setSearch] = useState('')
    const [projectType, setProjectType] = useState('all')

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [itemSelected, setItemSelected] = useState(null)
    const [loading, setLoading] = useState(true)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [totalTemplates, setTotalTemplates] = useState(0)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData()
        }, 600)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, page, projectType])

    const getData = async () => {
        axios.post(process.env.REACT_APP_API + '/acquisitions/list/' + page, {
            search,
            project_type: projectType
        }, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setAcquisitions(response.data.acquisitions ?? [])
                setTotalTemplates(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const deleteAcquisition = async (e) => {
        e.preventDefault()
        setDeleteLoading(true)
        axios.delete(process.env.REACT_APP_API + '/acquisitions/' + itemSelected.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setDeleteLoading(true)
        setModalIsOpen(false)
        setItemSelected(null)
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>Beneficiari</h1>
                </div>

                <div className={"col-12 pb-4"}>

                    <div className="card">

                        <div className={"header"}>
                            <div className={"row"}>
                                <div className={"col-md-4 col-sm-12"}>
                                    <div className={"search-input-container"}>
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                        <input type={"text"} className={"form-input"}
                                               placeholder={"Căutare"}
                                               value={search} onChange={(e) => {
                                            localStorage.setItem('search', e.target.value)
                                            setSearch(e.target.value)
                                        }}/>
                                    </div>
                                </div>
                                <div className={"col-md-4 col-sm-12"}>
                                    <div className={"search-input-container"}>
                                        <Selector
                                            options={[{
                                                value: 'all',
                                                label: 'Toate tipurile de proiect'
                                            },{
                                                value: 'pnras',
                                                label: 'PNRAS'
                                            }, {
                                                value: 'smartlab',
                                                label: 'SMARTLAB'
                                            }]}
                                            value={projectType}
                                            onChange={(option) => setProjectType(option.value)}
                                            isSearchable={false}
                                            isOptionDisabled={(option) => option.disabled}
                                        />
                                    </div>
                                </div>
                                <div className={"col-md-4 col-sm-12"}>
                                    <Button
                                        className={"btn-primary d-flex align-items-center ms-auto"}
                                        icon={"fa-solid fa-plus"}
                                        value={'Adaugă'}
                                        onClick={() => navigate('/add')}
                                    />
                                </div>
                            </div>
                        </div>


                        {
                            acquisitions.length === 0 ?
                                <div className={"content"}>
                                    <div className={"alert alert-warning"}>Momentan nu aveți nici o
                                        achiziție adăugată!
                                    </div>
                                </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table mb-1"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div className={"col-1 table-td"}>Nr</div>
                                                <div className={"col-2 table-td"}>Proiect</div>
                                                <div className={"col-4 table-td"}>Categorii</div>
                                                <div className={"col-3 table-td"}>Institutia</div>
                                                <div className={"col-2 table-td"}></div>
                                            </div>
                                        </div>

                                        <div className={"table-body"}>
                                            {
                                                acquisitions.map((acquisition, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                onClick={() => navigate('/acquisitions/' + acquisition.id + '/projects')}
                                                                className={"col-1 table-td"}>
                                                                {i + 1 + (page - 1) * 10}
                                                            </div>
                                                            <div
                                                                onClick={() => navigate('/acquisitions/' + acquisition.id + '/projects')}
                                                                className={"col-2 table-td"}>
                                                                {acquisition.projects.length > 0 ? acquisition.projects[0].project_type.toUpperCase() : ''}
                                                            </div>
                                                            <div
                                                                onClick={() => navigate('/acquisitions/' + acquisition.id + '/projects')}
                                                                className={"col-4 table-td"}>
                                                                {acquisition.projects.length > 0 && acquisition.projects.map((project, j) =>
                                                                    (intl.formatMessage({id: 'projects.type.' + project.type})) + (j !== acquisition.projects.length - 1 ? ', ' : ''))}
                                                            </div>
                                                            <div
                                                                onClick={() => navigate('/acquisitions/' + acquisition.id + '/projects')}
                                                                className={"col-3 table-td"}>
                                                                {acquisition.client ? acquisition.client.company_title : acquisition.client_name}
                                                            </div>
                                                            <div className={"col-2 table-td"}>
                                                                <Button
                                                                    className={"btn-light btn-edit ms-auto"}
                                                                    icon={"fa-solid fa-pen"}
                                                                    onClick={() => navigate('/acquisitions/' + acquisition.id + '/projects')}
                                                                />
                                                                <Button
                                                                    className={"btn-light btn-delete"}
                                                                    icon={"fa-solid fa-trash"}
                                                                    onClick={() => {
                                                                        setModalIsOpen(true)
                                                                        setItemSelected(acquisition)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className={"footer d-flex justify-content-center"}>
                            <Paginate
                                page={page}
                                setPage={setPage}
                                totalItems={totalTemplates}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ModalComp
                title={'Ștergere proiect'}
                isOpen={modalIsOpen}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalIsOpen(false)}
            >
                <form onSubmit={(e) => deleteAcquisition(e)}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <p>Sigur dorești ștergerea proiectului?</p>
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-6 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4"}
                                    value={'Nu'}
                                    type={'button'}
                                    onClick={() => setModalIsOpen(false)}
                                />
                            </div>
                            <div className={"col-6 d-flex"}>
                                <Button
                                    className={"btn-gray mt-4 ms-auto"}
                                    value={'Da'}
                                    type={'submit'}
                                    disabled={deleteLoading}
                                    loading={deleteLoading}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
        </div>
    );
}

