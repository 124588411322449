import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import LineItems from "../../../../components/elements/LineItems/LineItems";
import '../Projects.scss';
import Selector from "../../../../components/elements/Selector/Selector";

export default function AddProjectType1({type, setType, categoriesOptions, getCategories}) {

    const intl = useIntl();
    const navigate = useNavigate()
    let params = useParams();
    const jwt = localStorage.getItem('jwt')

    const [total, setTotal] = useState(0)
    const [sum, setSum] = useState(0)
    const [percent, setPercent] = useState(100)
    const [client, setClient] = useState('')
    const [requirements, setRequirements] = useState([])
    const [totalValue, setTotalValue] = useState(0)
    const [totalForCategory, setTotalForCategory] = useState(0)
    const [categories, setCategories] = useState([])

    const [category, setCategory] = useState('')
    const [clients, setClients] = useState([])
    const [search, setSearch] = useState('')
    const [profFind, setProdFind] = useState(0)

    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)

    useEffect(() => {
        if (total && percent)
            setSum(parseFloat(parseFloat(total * percent / 100) - totalValue).toFixed(2))
        if (totalForCategory)
            setSum(parseFloat(parseFloat(totalForCategory) - totalValue).toFixed(2))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalValue, total, percent, totalForCategory])
    useEffect(() => {
        async function fetchData() {
            let resp = await getCategories()
            setCategories(resp)
        }
        fetchData();
        setRequirements([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type])
    useEffect(() => {
        if (params.acquisition && parseInt(params.acquisition) !== 0) {
            getAcquisition()
        } else {
            getClients()
        }
        async function fetchData() {
            let resp = await getCategories()
            setCategories(resp)
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getAcquisition = async () => {
        axios.get(process.env.REACT_APP_API + '/acquisitions/' + params.acquisition ?? 0, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setClient(response.data.client_name ?? response.data.client.company_title)
                    setTotal(response.data.total)
                }
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getClients = async () => {
        axios.post(process.env.REACT_APP_API + '/clients/list/0',
            {}, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
            .then(async (response) => {
                let temp = [];
                response.data.clients.forEach((client) => {
                    temp.push({
                        value: client.id,
                        label: client.company_title,
                        projectTitle: client.projectTitle,
                        number_financing_contract: client.number_financing_contract,
                        dateFinancingContract: client.dateFinancingContract
                    })
                })
                setClients(temp)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = (e) => {
        e.preventDefault();

        setSaveLoading(true)
        fetch(process.env.REACT_APP_API + '/projects', {
            method: 'POST',
            body: JSON.stringify({
                client_name: client,
                percent: percent,
                total: totalForCategory && totalForCategory.replaceAll(',', '.'),
                totalGrant: total !== 0 ? total : totalForCategory,
                acquisition: parseInt(params.acquisition ?? 0),
                type: type,
                data: JSON.stringify({
                    requirements: requirements,
                    totalValue: totalValue,
                }),
                project_type: ['educational','connect_devices','teacher_training_sessions','ergonomic_furniture'].includes(type) ? 'smartlab' : 'pnras'
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
        })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "Configurator-" + client + ".xlsx";

                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
                setSaveLoading(false)
                navigate('/acquisitions' + (parseInt(params.acquisition ?? 0) !== 0 ? '/' + params.acquisition + '/projects' : ''))
            })
            .catch(e => console.log(e))
            .catch(e => console.log(e))
    }

    if (loading) {
        return <LoadingComp/>
    }
    const getProd = (item) => {
        let content = []
        content.push(<div></div>)
        if (item.value === category)
            item.products.forEach((product, j) => {
                if (product.hided !== true)
                    content.push(<div className={"d-flex ps-5 pe-3 pb-2"}>
                        <div style={{cursor: 'pointer', color: profFind === product.id ? '#3F8CFF' : ''}}
                             onClick={() => {
                                 setRequirements(requirements.concat([{
                                     price: product.price_request,
                                     price_request: item.price_request,
                                     id: item.id,
                                     value: item.value,
                                     label: item.label,
                                     product: product.id,
                                     product_request: product.id,
                                     guarantee: product.guarantee_request,
                                     qty: 1
                                 }]))
                                 setTotalValue(parseFloat(totalValue + (product.price_request ?? 0)))
                             }}>
                            {j + 1}. {product.title} ({product.price_request} Lei) {product.part_number ? (' - ' + product.part_number) : ''}
                        </div>

                    </div>)
            })
        return content

    }

    return (
        <div className="container add-project">
            <div className={"row"}>

                <div className={"col-12"}>
                    <form onKeyDown={(e) => {
                        if (e.key === 'Enter')
                            e.preventDefault()
                    }}
                          onSubmit={(e) => save(e)
                          }>
                        <div className={"row"}>
                            <div className={"col-4 mb-4"}>
                                <div className="card">
                                    <div className={"content"}>
                                        <div className={"row"}>
                                            <div className={"col-12"}>
                                                <div className={"form-control  select-client"}>
                                                    <div
                                                        className={"form-label"}>Instituție<span
                                                        className={"form-required"}>*</span></div>

                                                    <input type={"text"}
                                                           value={client} onChange={(e) => setClient(e.target.value)}
                                                           required
                                                           className={"form-input"}
                                                           disabled={parseInt(params.acquisition ?? 0) !== 0}
                                                           list="psi_list"
                                                    />
                                                    <datalist id="psi_list">
                                                        <select>
                                                            {
                                                                clients.map((option) => (
                                                                    <option key={option.value} value={option.label}/>
                                                                ))
                                                            }
                                                        </select>
                                                    </datalist>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-4 mb-4"}>
                                <div className="card">
                                    <div className={"content"}>
                                        <div className={"row"}>
                                            <div className={"col-12"}>
                                                <div className={"form-control"}>
                                                    <div
                                                        className={"form-label"}>Valoare totală grant
                                                    </div>
                                                    <div className={"price-input-container"}>
                                                        <p>Lei</p>
                                                        <input type={"number"} className={"form-input"}
                                                               onFocus={(event) => event.target.select()}
                                                               disabled={parseInt(params.acquisition ?? 0) !== 0}
                                                               value={total} onChange={(e) => {
                                                            setTotal(e.target.value)
                                                            setTotalForCategory(parseFloat((parseFloat(e.target.value) * parseFloat(percent)) / 100).toFixed(2))
                                                        }}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-4 mb-4"}>
                                <div className="card">
                                    <div className={"content"}>
                                        <div className={"row"}>
                                            <div className={"col-12"}>
                                                <div className={"form-control"}>
                                                    <div
                                                        className={"form-label"}>Categorie<span
                                                        className={"form-required"}>*</span></div>
                                                    <Selector
                                                        options={categoriesOptions}
                                                        value={type}
                                                        onChange={(option) => setType(option.value)}
                                                        isSearchable={false}
                                                        isOptionDisabled={(option) => option.disabled}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-4 mb-4"}>
                                <div className="card">
                                    <div className={"content"}>
                                        <div className={"row"}>
                                            <div className={"col-12"}>
                                                <div className={"form-control"}>
                                                    <div
                                                        className={"form-label"}>Procent
                                                    </div>
                                                    <div className={"price-input-container"}>
                                                        <p>%</p>
                                                        <input type={"number"} className={"form-input"}
                                                               onFocus={(event) => event.target.select()}
                                                               value={percent} onChange={(e) => {
                                                            setPercent(e.target.value)
                                                            setTotalForCategory(parseFloat((parseFloat(total) * parseFloat(e.target.value)) / 100).toFixed(2))
                                                        }}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-4 mb-4"}>
                                <div className="card">
                                    <div className={"content"}>
                                        <div className={"row"}>
                                            <div className={"col-12"}>
                                                <div className={"form-control"}>
                                                    <div
                                                        className={"form-label"}>Valoare totală categorie
                                                    </div>
                                                    <div className={"price-input-container"}>
                                                        <p>Lei</p>
                                                        <input type={"text"} className={"form-input"}
                                                               onFocus={(event) => event.target.select()}
                                                               value={totalForCategory} onChange={(e) => {
                                                            setTotalForCategory(e.target.value)
                                                            if (total !== 0)
                                                                setPercent(parseFloat(100 * parseFloat(e.target.value) / total).toFixed(2))
                                                        }}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-4 mb-4"}>
                                <div className="card">
                                    <div className={"content"}>
                                        <div className={"row"}>
                                            <div className={"col-12"}>
                                                <div className={"form-control"}>
                                                    <div
                                                        className={"form-label"}>Suma rămasă
                                                    </div>
                                                    <div className={"price-input-container"}>
                                                        <p>Lei</p>
                                                        <input type={"text"}
                                                               className={"form-input " + (sum < 0 && 'text-red')}
                                                               onFocus={(event) => event.target.select()}
                                                               value={sum} readOnly/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>

                                <div className={"col-md-12 col-lg-4 col-xl-3 pb-4"}>

                                    <div className={"secondary-navigation"}>
                                        <div className={"item"}>
                                            <input type={"text"} className={"form-input"}
                                                   onFocus={(event) => event.target.select()}
                                                   placeholder={"Caută"}
                                                   value={search} onChange={(e) => {
                                                setSearch(e.target.value)
                                                if (e.target.value.length >= 3) {
                                                    for (let i = 0; i < categories.length; i++) {
                                                        for (let j = 0; j < categories[i].products.length; j++) {
                                                            if (categories[i].products[j].title.toLowerCase().includes(e.target.value.toLowerCase()) ||
                                                                categories[i].products[j].part_number?.toLowerCase().includes(e.target.value.toLowerCase())) {
                                                                setCategory(categories[i].id)
                                                                setProdFind(categories[i].products[j].id)
                                                            }
                                                        }
                                                    }
                                                }

                                            }}/>
                                        </div>

                                        {
                                            categories.length ? categories.filter(item => item.products.filter(it => it.hided !== true).length > 0).map((item, i) => (
                                                    <div key={i}>
                                                        <div className={"item d-block " + (item.value === category && 'active')} key={i}
                                                             style={{cursor: 'pointer'}}
                                                             onClick={() => {
                                                                 if (item.value === category) {
                                                                     setCategory(0)
                                                                     setProdFind(0)
                                                                 } else
                                                                     setCategory(item.value)
                                                             }}>
                                                            <div className={"title d-flex"} style={{width: '-webkit-fill-available'}}>
                                                                {item.label} <i
                                                                className={"ms-auto " + (item.products && item.value === category ? 'fa-solid fa-caret-up' : item.products ? 'fa-solid fa-caret-down' : null)}></i>
                                                            </div>

                                                        </div>
                                                        {getProd(item)}
                                                    </div>
                                                ))
                                                :
                                                <div>
                                                    <div className={"alert alert-warning ms-4 me-4"}>Momentan nu aveți nici un produs
                                                        adaugat in acesta categorie de cheltuieli!
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>

                                <div className={"col-md-12 col-lg-8 col-xl-9 pb-4"}>

                                    <div className="card">

                                        <div className={"header"}><p>Adaugă achiziție</p></div>

                                        <div className={"content"}>
                                            <div className={"row"}>

                                                <LineItems
                                                    items={requirements}
                                                    setItems={setRequirements}
                                                    setTotalValue={setTotalValue}
                                                    totalValue={totalValue}
                                                />

                                                <div className={"d-flex pb-2 mt-2"}>
                                                    <Button
                                                        className={"btn-secondary"}
                                                        size={"small"}
                                                        value={"Adaugă produs personalizat"}
                                                        style={{cursor: 'pointer'}} onClick={() => {
                                                        setRequirements(requirements.concat([{
                                                            price: 0,
                                                            price_request: 0,
                                                            id: null,
                                                            value: null,
                                                            label: '',
                                                            product: null,
                                                            guarantee: 36,
                                                            qty: 1
                                                        }]))
                                                    }}>

                                                    </Button>

                                                </div>
                                                {
                                                    requirements.length > 0 &&
                                                    <strong className={"mb-4"}>Valoarea
                                                        totală: {parseFloat(totalValue).toLocaleString('ro-RO')} RON</strong>
                                                }

                                            </div>
                                        </div>

                                        <div className={"footer"}>
                                            <div className={"row"}>
                                                <div className={"col-12 col-md-6"}>

                                                </div>
                                                <div className={"col-12 col-md-6 d-flex"}>
                                                    <Button
                                                        className={"btn-secondary mt-2 ms-auto"}
                                                        icon={"fa-solid fa-plus"}
                                                        value={"Generează și salveză"}
                                                        disabled={saveLoading || requirements.length === 0}
                                                        loading={saveLoading}
                                                        //  onClick={() => sub}
                                                        type={'submit'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>


        </div>
    );
}

