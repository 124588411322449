import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import LineItems from "../../../components/elements/LineItems/LineItems";

export default function ViewProject() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const navigate = useNavigate()

    const [project, setProject] = useState('')

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/projects/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {

                    response.data.documents.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))

                    setProject(response.data)
                    setLoading(false)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const downloadDoc = (title,id) => {

        fetch(process.env.REACT_APP_API + '/documents/download/' + id, {
            method: 'GET', headers: {
                'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt
            },
        })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = title +  ".docx";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
            })
            .catch(e => console.log(e))
    }
    if (loading) {
        return <LoadingComp/>
    }
    return (
        <div className="container">
            <div className={"header"}>
                <h1 className={"container-title"}>{project.data.projectTitle}</h1>
            </div>

            <div className={"row"}>

                <div className={"col-12 "}>

                    <div className="card">
                         <div className={"content"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-12"}>
                                        <div className={"form-label"}>Denumire achiziție: {project.title}</div>
                                        <div className={"form-label"}>Instituție: {project.client ? project.client.company_title : project.client_name}</div>
                                        <div className={"form-label"}>Termen de livrare (zile): {project.data.deliveryTime}</div>
                                        <div className={"form-label"}>Termen depunere oferte: {project.data.submissionDeadline}</div>
                                        <div className={"form-label"}>Cod proiect: {project.project_code}</div>
                                        <div className={"form-label"}>Motivație: {project.motivation}</div>
                                        <div className={"form-label"}>Numărul de zile înainte de data limită de depunere a clarificărilor: {project.data.daysClarification}</div>
                                        <div className={"form-label"}>Numărul de zile pentru care ofertantul trebuie să mențină valabilitatea ofertei: {project.data.validityOffer}</div>
                                    </div>
                                    <LineItems
                                        items={project.data.requirements}
                                        isInvGenerated={true}
                                    />

                                    {
                                        project.data.requirements.length > 0 &&
                                        <strong className={"mb-4"}>Valoarea totală: {parseFloat(project.data.totalValue).toLocaleString('ro-RO', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })} RON</strong>
                                    }
                                    <div className={"col-12 mt-4"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Furnizori: {project.companies.map((company) => {
                                                     return company.title + ", "
                                                })}
                                            </div>

                                        </div>
                                    </div>
                                    <div className={"col-12 mt-4"}>
                                        <div className={"table mb-1"}>

                                            <div className={"table-head"}>
                                                <div className={"row"}>
                                                    <div className={"col-4 table-td"}>Document</div>
                                                    <div className={"col-8 table-td"}></div>
                                                </div>
                                            </div>

                                            <div className={"table-body"}>
                                                <div className={"table-row"} key={1}>
                                                    {
                                                        project.documents.map((document, i) => (

                                                            <div className={"row"} key={i}>
                                                                <div
                                                                    className={"col-4 table-td"}>
                                                                    {intl.formatMessage({id: 'templates.type.' + document.type})}
                                                                </div>
                                                                <div className={"col-8 table-td"}>
                                                                  <Button
                                                                      className={"btn-success ms-auto"}
                                                                      value={"Descarcă"}
                                                                        onClick={() => {
                                                                            downloadDoc(intl.formatMessage({id: 'templates.type.' + document.type}),document.id)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"footer"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <Button
                                            className={"btn-light mt-2"}
                                            icon={"fa-solid fa-chevron-left"}
                                            value={"Înapoi"}
                                            onClick={() => navigate(-1)}
                                        />
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

