import React, {useEffect, useState} from "react";
import LineItems from "../../../../../../../components/elements/LineItems/LineItems";
import Selector from "../../../../../../../components/elements/Selector/Selector";
import ModalComp from "../../../../../../../components/ModalComp/ModalComp";
import Button from "../../../../../../../components/elements/Button/Button";

export default function OtherRequirementsItemDetails({
                                                         item,
                                                         setItem,
                                                         isInvGenerated,
                                                         categories,
                                                         SingleValue,
                                                         category,
                                                         setCategory,
                                                         setAddTo,
                                                         projectType,
                                                         type
                                                     }) {

    const [requirements, setRequirements] = useState(item.requirements)
    const [totalValue, setTotalValue] = useState(item.totalValue)
    const [title, setTitle] = useState(item.title)
    const [update, setUpdate] = useState(0)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [product, setProduct] = useState('')
    const [products, setProducts] = useState([])

    useEffect(() => {
        let temp = item
        temp.title = title
        temp.totalValue = totalValue
        temp.requirements = requirements
        setItem(temp)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requirements, totalValue, title, update])
    return (
        <>
            <div className={"col-12 mt-3 pt-3"} style={{borderTop: '1px solid #E6E8F0'}}>
                <div className={"form-control"}>
                    <div
                        className={"form-label"}>Titlu categorie
                    </div>
                    <input
                        defaultValue={title}
                        onChange={(e) => {
                            setTitle(e.target.value)
                        }}
                        className={"form-input"}/>
                </div>
            </div>
            <LineItems
                items={requirements}
                setItems={setRequirements}
                setTotalValue={setTotalValue}
                totalValue={totalValue}
                isInvGenerated={isInvGenerated}
            />

            <div className={"d-flex pb-2 mt-2"}>
                <Button
                    className={"btn-secondary"}
                    size={"small"}
                    value={"Adaugă produs personalizat"}
                    style={{cursor: 'pointer'}} onClick={() => {
                    let temp = requirements
                    temp.push({
                        price: 0,
                        price_request: 0,
                        id: null,
                        value: null,
                        label: '',
                        product: null,
                        guarantee: 36,
                        qty: 1
                    })
                    setUpdate(update+1)
                    setRequirements(temp)
                }}>

                </Button>

            </div>
            {
                requirements.length > 0 &&
                <strong className={"mb-4"}>Valoarea totală {item.title}
                    : {parseFloat(totalValue).toLocaleString('ro-RO', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })} RON</strong>
            }

            <div className={"col-12"}>
                <div className={"form-control"}>
                    <div
                        className={"form-label"}>Adaugă element
                    </div>
                    <Selector
                        options={categories}
                        value={category} disabled={isInvGenerated}
                        onChange={(option) => {
                            setAddTo('diff')
                            let temp = []
                            option.products.forEach((prod) => {
                                if (prod.type === type && prod.project_type === projectType)
                                temp.push({
                                    price: prod.price_request,
                                    price_request: prod.price_request,
                                    id: prod.id,
                                    value: prod.id,
                                    label: prod.title,
                                    product: prod.id,
                                    guarantee: prod.guarantee_request,
                                    qty: 1
                                })
                            })
                            setProducts(temp)
                            setCategory(option)
                            setModalIsOpen(true)
                        }}
                        isSearchable={true}
                        isOptionDisabled={(option) => option.disabled}
                        components={{SingleValue}}
                    />
                </div>
            </div>

            <ModalComp
                title={'Adaugă element'}
                isOpen={modalIsOpen}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalIsOpen(false)}
            >
                <form>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <div
                            className={"form-label"}>Produs
                        </div>
                        <Selector
                            options={products}
                            value={product}
                            required={true}
                            onChange={(option) => setProduct(option)}
                            isSearchable={false}
                            isOptionDisabled={(option) => option.disabled}
                        />
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={'Salvează'}
                                    type={'button'}
                                    onClick={() => {
                                        let temp = item
                                        temp.requirements = requirements.push({
                                            "price": product.price,
                                            "price_request": category.price,
                                            "id": category.id,
                                            "value": category.id,
                                            "label": category.label,
                                            "product": product.value,
                                            "guarantee": product.guarantee,
                                            "qty": 1
                                        })
                                        let tempTotal = totalValue + parseFloat(product.price)
                                        setTotalValue(tempTotal)

                                        setCategory('')
                                        setModalIsOpen(false)
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
        </>

    );
}

