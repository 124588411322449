import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import ModalComp from "../../../../../../components/ModalComp/ModalComp";
import Selector from "../../../../../../components/elements/Selector/Selector";
import moment from "moment/moment";
import {deleteDocument, downloadDoc, getProjectInfo} from "../../../../../../utils/project_functions";

export default function Appendix20Smartlab() {

    const navigate = useNavigate()
    const intl = useIntl();
    let params = useParams();
    const jwt = localStorage.getItem('jwt')

    const [update, setUpdate, templates] = useOutletContext();
    const [loading, setLoading] = useState(true)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [template, setTemplate] = useState('')
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [loadingGenerate, setLoadingGenerate] = useState(false)
    const [appendix20, setAppendix20] = useState(null)
    const [draft, setDraft] = useState(false)
    const [docIdToDelete, setDocIdToDelete] = useState(0)

    useEffect(() => {
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const init = async () => {

        let proj = await getProjectInfo(params.id, intl)
        setAppendix20(proj.documents.find(doc => doc.type === 'appendix_20') ?? null)
        setLoading(false)
    }

    const generateDoc = async (e) => {
        e.preventDefault()
        setLoadingGenerate(true)

        if (docIdToDelete !== 0) {
            await deleteDocument(docIdToDelete, intl)
        }

        fetch(process.env.REACT_APP_API + '/projects/generate-doc/' + params.id, {
            method: "POST",
            body: JSON.stringify({template, company: 0, status: draft ? 'draft' : 'generated'}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
        })
            .then(response => response.blob())
            .then(async blob => {
                if (!draft) {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = 'Pv receptie.docx';
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                }

                setUpdate(update + 1)
                setModalIsOpen(false)
                setLoadingGenerate(false)
                let proj = await getProjectInfo(params.id, intl)
                setAppendix20(proj.documents.find(doc => doc.type === 'appendix_20') ?? [])
            })
            .catch(e => console.log(e))
    }

    if (loading) {
        return <LoadingComp/>
    }
    return (<div className="card">
            <div className={"header"}><p>Documente finale</p></div>
            <div className={"content p-0"}>
                <div className={"table mb-1"}>
                    <div className={"table-head"} style={{minWidth: '770px'}}>
                        <div className={"row"}>
                            <div className={"col-4 table-td"}>Denumire</div>
                            <div className={"col-2 table-td"}>Status</div>
                            <div className={"col-1 table-td"}>Dată generare</div>
                            <div className={"col-1 table-td"}>Dată descărcare</div>
                            <div className={"col-4 table-td"}></div>
                        </div>
                    </div>

                    <div className={"table-body"} style={{minWidth: '770px'}}>
                        <div className={"table-row"}>
                            <div className={"row"}>
                                <div
                                    className={"col-4 table-td"}>
                                    Pv receptie
                                </div>

                                <div
                                    className={"col-2 table-td"}>
                                    <div className={"p-2 alert " + (appendix20 !== null && appendix20.status === 'generated' ? 'alert-success' : 'alert-danger')}>
                                        {appendix20 ? appendix20.status === 'draft' ? 'Draft' : 'Generat' : 'Negenerat'}
                                    </div>
                                </div>
                                <div
                                    className={"col-1 table-td"}>
                                    {appendix20 && moment(appendix20.created_at).format('DD/MM HH:mm')}
                                </div>
                                <div
                                    className={"col-1 table-td"}>
                                    {appendix20 && appendix20.downloaded_at && moment(appendix20.downloaded_at).format('DD/MM HH:mm')}
                                </div>
                                <div className={"col-4 table-td"}>
                                    {appendix20 ?

                                        <>{
                                            appendix20.status === 'draft' ?

                                                <Button
                                                    className={"btn-light btn-edit ms-auto"}
                                                    icon={"fa-solid fa-pen"}
                                                    onClick={() => {
                                                        navigate('/acquisitions/projects/smartlab/' + params.id + '/documents/' +
                                                            appendix20.id)
                                                    }}
                                                />
                                                :

                                                <Button
                                                    className={"btn-success ms-auto"}
                                                    value={"Descarcă"}
                                                    loading={loadingDownload}
                                                    onClick={async () => {
                                                        await downloadDoc('Pv receptie', appendix20.id, setLoadingDownload)
                                                    }}
                                                />
                                        }
                                            <Button
                                                className={"btn-primary ms-2"}
                                                value={"Regenerează"}
                                                onClick={() => {
                                                    setDocIdToDelete(appendix20.id)
                                                    if (templates.filter((item) => item.type === 'appendix_20').length === 1)
                                                        setTemplate(templates.filter((item) => item.type === 'appendix_20')[0].value)
                                                    setModalIsOpen(true)

                                                }}
                                            />
                                        </>
                                        : <Button
                                            className={"btn-primary ms-auto"}
                                            value={"Generează"}
                                            onClick={() => {
                                                setDocIdToDelete(0)
                                                if (templates.filter((item) => item.type === 'appendix_20').length === 1)
                                                    setTemplate(templates.filter((item) => item.type === 'appendix_20')[0].value)
                                                setModalIsOpen(true)
                                            }}
                                        />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className={"footer d-flex justify-content-center"}>

            </div>
            <ModalComp
                title={(docIdToDelete === 0 ? 'Generează' : 'Regenerează') + ' Pv receptie'}
                isOpen={modalIsOpen}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalIsOpen(false)}
            >
                <form onSubmit={(e) => generateDoc(e)}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <div
                            className={"form-label"}>Șablon<span
                            className={"form-required"}>*</span></div>
                        <Selector
                            options={templates.filter((item) => item.type === 'appendix_20')}
                            value={template}
                            required={true}
                            onChange={(option) => setTemplate(option.value)}
                            isSearchable={false}
                            isOptionDisabled={(option) => option.disabled}
                        />
                        <div
                            className={"form-label mt-3 me-2"}>

                            <input type={"checkbox"} checked={draft} onChange={(e) => setDraft(!draft)}/>
                            Salvează ca draft
                        </div>

                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={'Generează'}
                                    type={'submit'}
                                    disabled={loadingGenerate}
                                    loading={loadingGenerate}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
        </div>

    );
}

