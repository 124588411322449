import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import {toast} from "react-toastify";

export default function CompaniesAdmin() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [companies, setCompanies] = useState([])

    const [loading, setLoading] = useState(true)


    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/companies', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setCompanies(response.data ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const deleteCompany = async (id) => {
        axios.delete(process.env.REACT_APP_API + '/companies/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                } else {
                    toast.error("Acestă companie nu poate fi ștersă!")
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container companies-list">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>Companii</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card">

                        {
                            companies.length === 0 ?
                                <div className={"content"}>
                                    <div className={"alert alert-warning"}>
                                        Nu există companii!
                                    </div>
                                </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table mb-1 companies-table"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div className={"col-4 table-td"}>Denumire</div>
                                                <div className={"col-3 table-td"}>Email</div>
                                                <div className={"col-3 table-td"}>Utilizator</div>
                                                <div className={"col-2 table-td"}></div>
                                            </div>
                                        </div>


                                        <div className={"table-body"}>
                                            {
                                                companies.map((user, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-4 table-td"}>{user.title}</div>
                                                            <div className={"col-3 table-td"}>{user.email}</div>
                                                            <div className={"col-3 table-td"}>{user.user.first_name} { user.user.last_name}</div>
                                                            <div className={"col-2 table-td"}>
                                                                <Button
                                                                    className={"btn-light btn-edit ms-auto"}
                                                                    icon={"fa-solid fa-eye"}
                                                                    onClick={() => navigate('/companies/' + user.id)}
                                                                />
                                                                <Button
                                                                    className={"btn-light btn-delete"}
                                                                    icon={"fa-solid fa-trash"}
                                                                    onClick={() => deleteCompany(user.id)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

