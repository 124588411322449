import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import Paginate from "../../../components/elements/Paginate/Paginate";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import {toast} from "react-toastify";

export default function ProductsCategories() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [categories, setCategories] = useState([])
    const [search, setSearch] = useState('')

    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [totalCategories, setTotalCategories] = useState(0)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData()
        }, 600)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, page])

    const getData = async () => {
        axios.post(process.env.REACT_APP_API + '/product-categories/list/' + page, {search}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setCategories(response.data.categories ?? [])
                setTotalCategories(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }
    const deleteProductCategory = async (id) => {
        axios.delete(process.env.REACT_APP_API + '/product-categories/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                } else {
                    toast.error("Acestă categorie nu poate fi ștersă!")
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    return (
        <div className="card">

                        <div className={"header"}>
                            <div className={"row"}>
                                <div className={"col-lg-6 col-md-6 col-sm-12"}>
                                    <div className={"search-input-container"}>
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                        <input type={"text"} className={"form-input"}
                                               placeholder={"Căutare"}
                                               value={search} onChange={(e) => setSearch(e.target.value)}/>
                                    </div>
                                </div>
                                <div className={"col-lg-6 col-md-6  col-sm-12 float-right"}>
                                    <Button
                                        className={"btn-primary d-flex align-items-center ms-auto"}
                                        icon={"fa-solid fa-plus"}
                                        value={"Adaugă"}
                                        onClick={() => navigate('/products/categories/add')}
                                    />
                                </div>
                            </div>
                        </div>


                        {
                            categories.length === 0 ?

                                    <div className={"content"}>

                                        <div className={"alert alert-warning"}>Momentan nu aveți nici o categorie adaugată!</div>
                                    </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table mb-1"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div className={"col-2 table-td"}>Nr</div>
                                                <div className={"col-5 table-td"}>Denumire</div>
                                                <div className={"col-2 table-td"}>Produse</div>
                                                <div className={"col-3 table-td"}></div>
                                            </div>
                                        </div>

                                        <div className={"table-body"}>
                                            {
                                                categories.map((category, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-2 table-td"}>
                                                                {i + 1 + (page - 1) * 10}
                                                            </div>
                                                            <div
                                                                className={"col-5 table-td"}>
                                                                {category.title}
                                                            </div>
                                                            <div
                                                                className={"col-2 table-td"}>
                                                                {category.products.length}
                                                            </div>
                                                            <div className={"col-3 table-td"}>
                                                                <Button
                                                                    className={"btn-light btn-edit ms-auto"}
                                                                    icon={"fa-solid fa-pen"}
                                                                    onClick={() => navigate('/products/categories/' + category.id)}
                                                                />
                                                                <Button
                                                                    className={"btn-light btn-delete"}
                                                                    icon={"fa-solid fa-trash"}
                                                                    onClick={() => deleteProductCategory(category.id)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className={"footer d-flex justify-content-center"}>
                            <Paginate
                                page={page}
                                setPage={setPage}
                                totalItems={totalCategories}
                            />
                        </div>
                    </div>
    );
}

