import {useIntl} from "react-intl";
import React, {useState} from "react";
import '../Projects.scss';
import AddProjectType1 from "./AddProjectType1";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";

export default function AddProjectPNRAS() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [type, setType] = useState('products')


    const getCategories = async () => {
        return axios.post(process.env.REACT_APP_API + '/product-categories/list/0', {}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = [];
                response.data.categories.forEach((category) => {
                    let prods = category.products.filter((product) => product.type === type && product.project_type === 'pnras')
                    if (prods.length > 0)
                        temp.push({
                            price: category.price,
                            id: category.id,
                            value: category.id,
                            label: category.title,
                            products: prods,
                            qty: 1
                        })
                })
                return temp
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    return (
        <AddProjectType1 type={type} setType={setType} categoriesOptions={[{
            value: 'services',
            label: intl.formatMessage({id: 'projects.type.services'})
        }, {
            value: 'events',
            label: intl.formatMessage({id: 'projects.type.events'})
        }, {
            value: 'products',
            label: intl.formatMessage({id: 'projects.type.products'})
        }, {
            value: 'other_services',
            label: intl.formatMessage({id: 'projects.type.other_services'})
        }, {
            value: 'teachers_training',
            label: intl.formatMessage({id: 'projects.type.teachers_training'})
        }, {
            value: 'furniture',
            label: intl.formatMessage({id: 'projects.type.furniture'})
        }]} getCategories={getCategories}/>
    );
}

