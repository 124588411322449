import "./SecondaryNavigation.scss"
import {NavLink} from "react-router-dom";

export default function SecondaryNavigation(
    {
        items
    }
) {
    return (
        <div className={"secondary-navigation"}>

            {
                items.map((item,i) => (
                    <NavLink key={i} to={item.url} end={!!item.end} className={item.disabled ? 'disabled' : ''}
                             onClick={(e) => {
                                 if(item.disabled)
                                     e.preventDefault()
                             }} >
                        <div className={"item"}>
                            <div className={"icon"}>
                                <i className={item.icon}></i>
                            </div>
                            <div className={"title"}>
                                {item.title}
                            </div>
                        </div>
                    </NavLink>
                ))
            }

        </div>
    );
}