import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import Paginate from "../../../components/elements/Paginate/Paginate";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import './Clients.scss';

export default function ClientsAdmin() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [clients, setClients] = useState([])
    const [search, setSearch] = useState('')

    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [totalMenus, setTotalMenus] = useState(0)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData()
        }, 600)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, page])

    const getData = async () => {
        axios.post(process.env.REACT_APP_API + '/clients/list/' + page,
            {search}, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
            .then(async (response) => {
                setClients(response.data.clients ?? [])
                setTotalMenus(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container clients-list">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>Instituții</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card">

                        <div className={"header"}>

                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <div className={"search-input-container"}>
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                        <input type={"text"} className={"form-input"}
                                               placeholder={'Căutare'}
                                               value={search} onChange={(e) => setSearch(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {
                            clients.length === 0 ?
                                search === '' ?
                                    <div className={"content"}>

                                        <div className={"alert alert-warning"}>Momentan nu aveți nici 0 instituție adăugată!</div>
                                    </div>
                                    :
                                    <div className={"content"}>

                                        <div className={"alert alert-warning"}>Nu au fost gasițe instituții!</div>
                                    </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table mb-1"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-1 table-td"}>Nr</div>

                                                <div
                                                    className={"col-3 table-td"}>Nume</div>
                                                <div
                                                    className={"col-3 table-td"}>Adresa de email</div>
                                                <div className={"col-2 table-td"}>Telefon</div>
                                                <div className={"col-2 table-td"}>Utilizator</div>
                                                <div className={"col-1 table-td"}></div>
                                            </div>
                                        </div>


                                        <div className={"table-body"}>
                                            {
                                                clients.map((client, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-1 table-td"}>
                                                                {i + 1 + (page - 1) * 10}
                                                            </div>
                                                            <div
                                                                className={"col-3 table-td"}>
                                                                {client.company_title}
                                                            </div>
                                                            <div
                                                                className={"col-3 table-td"}>
                                                                {client.email}
                                                            </div>
                                                            <div
                                                                className={"col-2 table-td"}>
                                                                {client.phone}
                                                            </div>
                                                            <div
                                                                className={"col-2 table-td"}>
                                                                {client.user.first_name} {client.user.last_name}
                                                            </div>
                                                            <div className={"col-1 table-td"}>
                                                                <Button
                                                                    className={"btn-light btn-edit ms-auto"}
                                                                    icon={"fa-solid fa-eye"}
                                                                    onClick={() => navigate('/clients/' + client.id)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className={"footer d-flex justify-content-center"}>
                            <Paginate
                                page={page}
                                setPage={setPage}
                                totalItems={totalMenus}
                            />

                        </div>
                    </div>

                </div>
            </div>


        </div>
    );
}

