import './Button.scss';
import LoadingCompSmall from "../LoadingComp/LoadingCompSmall";

export default function Button(
    {
        value = "",
        onClick = () => {},
        loading = false,
        className = "",
        type = "button",
        disabled = false,
        icon = null,
        whiteLoader = true,
        ...props
    }
) {
    return (
        <button type={type}
                disabled={disabled || loading}
                className={"btn " + className}
                onClick={() => onClick()}
                {...props}
        >
            {
                loading ?
                    <LoadingCompSmall white={whiteLoader}/>
                    :
                    <>
                        {
                            icon ?
                                <i className={icon + (value.length > 0 ? " me-2" : "")}></i>
                                :null
                        }
                        {value}
                    </>
            }
        </button>
    );
}

