import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import ModalComp from "../../../../../../components/ModalComp/ModalComp";
import Selector from "../../../../../../components/elements/Selector/Selector";
import moment from "moment";
import {deleteDocument, downloadDoc, getProjectInfo} from "../../../../../../utils/project_functions";
import {toast} from "react-toastify";

export default function Appendix21Smartlab() {

    const navigate = useNavigate();
    const intl = useIntl();
    let params = useParams();
    const jwt = localStorage.getItem('jwt')

    const [project, setProject] = useState([])

    const [update, setUpdate, templates] = useOutletContext();
    const [loading, setLoading] = useState(true)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [company, setCompany] = useState(0)
    const [template, setTemplate] = useState('')
    const [loadingDownload, setLoadingDownload] = useState({})
    const [loadingGenerate, setLoadingGenerate] = useState(false)
    const [docSelected, setDocSelected] = useState(0)
    const [docNumber, setDocNumber] = useState(0)
    const [docDate, setDocDate] = useState('')
    const [modalDocIsOpen, setModalDocIsOpen] = useState(false)
    const [draft, setDraft] = useState(false)
    const [appendix21, setAppendix21] = useState(null)
    const [companies, setCompanies] = useState([])
    const [providers, setProviders] = useState([])
    const [saveLoading, setSaveLoading] = useState(false)
    const [modalProvidersIsOpen, setModalProvidersIsOpen] = useState(false)
    const [docIdToDelete, setDocIdToDelete] = useState(0)

    useEffect(() => {
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const init = async () => {
        let proj = await getProjectInfo(params.id, intl)
        setProject(proj)
        setProviders(proj.companies.map((company) => company.id))
        setAppendix21(proj.documents.filter(doc => doc.type === 'appendix_21') ?? null)
        getCompanies()
        setLoading(false)
    }
    const editDoc = (e) => {
        e.preventDefault()
        axios.post(process.env.REACT_APP_API + '/projects/update-doc/' + params.id, {document: docSelected, number: docNumber, date: docDate}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {

                setModalDocIsOpen(false)
                let proj = await getProjectInfo(params.id, intl)
                setProject(proj)
                setAppendix21(proj.documents.find(doc => doc.type === 'appendix_21') ?? null)

            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const generateDoc = async (e) => {
        e.preventDefault()
        setLoadingGenerate(true)

        if (docIdToDelete !== 0){
           await deleteDocument(docIdToDelete, intl)
        }

        fetch(process.env.REACT_APP_API + '/projects/generate-doc/' + params.id, {
            method: "POST",
            body: JSON.stringify({template, company, status: draft ? 'draft' : 'generated'}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
        })
            .then(response => response.blob())
            .then(async blob => {
                if (!draft) {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = 'Cerere de oferta - '+companies.find(item => item.value === company).label+'.docx';
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                }

                setUpdate(update + 1)
                setModalIsOpen(false)
                setLoadingGenerate(false)
                let proj = await getProjectInfo(params.id, intl)
                setProject(proj)
                setAppendix21(proj.documents.filter(doc => doc.type === 'appendix_21') ?? null)
            })
            .catch(e => console.log(e))
    }
    const getCompanies = async () => {
        axios.get(process.env.REACT_APP_API + '/companies', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = [];
                response.data.forEach((company) => {
                    temp.push({
                        value: company.id, label: company.title,
                    })
                })
                setCompanies(temp)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (e) => {
        e.preventDefault();
        setSaveLoading(true)

        await axios.put(process.env.REACT_APP_API + '/projects/' + params.id, {companies: providers}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success("Informațiile au fost salvate cu succes!")
                setSaveLoading(false)
                setModalProvidersIsOpen(false)
                let proj = await getProjectInfo(params.id, intl)
                setProject(proj)
                setProviders(proj.companies.map((company) => company.id))
                setAppendix21(proj.documents.filter(doc => doc.type === 'appendix_21') ?? null)
                setUpdate(update + 1)
            })
            .catch(err => {
                errorParser(err, intl)
            })

        setSaveLoading(false)
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (<div className="card">


            <div className={"header"}><p className={"d-flex"}>Cerere ofertă
                <Button
                    className={"btn-primary ms-auto mt-0"}
                    value={"Modifică furnizorii"}
                    onClick={() => setModalProvidersIsOpen(true)}
                />
            </p></div>


            <div className={"content p-0"}>
                <div className={"table mb-1"}>

                    <div className={"table-head"} style={{minWidth: '970px'}}>
                        <div className={"row"}>
                            <div className={"col-2 table-td"}>Denumire</div>
                            <div className={"col-2 table-td"}>Status</div>
                            <div className={"col-2 table-td"}>Furnizor</div>
                            <div className={"col-1 table-td"}>Dată generare</div>
                            <div className={"col-1 table-td"}>Dată descărcare</div>
                            <div className={"col-4 table-td"}></div>
                        </div>
                    </div>

                    <div className={"table-body"} style={{minWidth: '970px'}}>
                        <div className={"table-row"} key={1}>
                            {project && project.companies && project.companies.length > 0 && project.companies.map((company, i) => (

                                <div className={"row"} key={'offer-' + i}>
                                    <div
                                        className={"col-2 table-td"}>
                                        Cerere de oferta
                                    </div>
                                    <div
                                        className={"col-2 table-td"}>
                                        <div
                                            className={"p-2 alert " + (appendix21.find((document) => document.company === company.id) && appendix21.find((document) => document.company === company.id).status === 'generated' ? 'alert-success' : 'alert-danger')}>
                                            {appendix21.find((document) => document.company === company.id) ? appendix21.find((document) => document.company === company.id).status === 'draft' ? 'Draft' : 'Generat' : 'Negenerat'}
                                        </div>
                                    </div>

                                    <div
                                        className={"col-2 table-td"}>
                                        {company.title}
                                    </div>

                                    <div
                                        className={"col-1 table-td"}>
                                        {appendix21.find((document) => document.company === company.id)
                                            && moment(appendix21.find((document) => document.company === company.id).created_at).format('DD/MM HH:mm')}
                                    </div>
                                    <div
                                        className={"col-1 table-td"}>
                                        {appendix21.find((document) => document.company === company.id) &&
                                            appendix21.find((document) => document.company === company.id).downloaded_at &&
                                            moment(appendix21.find((document) => document.company === company.id).downloaded_at).format('DD/MM HH:mm')}
                                    </div>
                                    <div className={"col-4 table-td"}>
                                        {appendix21.find((document) => document.company === company.id) ? <>
                                            {appendix21.find((document) => document.company === company.id).status === 'draft' ? <Button
                                                className={"btn-light btn-edit ms-auto"}
                                                icon={"fa-solid fa-pen"}
                                                onClick={() => {
                                                    navigate('/acquisitions/projects/smartlab/' + params.id + '/documents/' + appendix21.find((document) => document.company === company.id).id)
                                                }}
                                            /> : <>
                                                <Button
                                                    className={"btn-light btn-edit ms-2"}
                                                    icon={"fa-solid fa-pen-ruler"}
                                                    onClick={() => {
                                                        let doc = appendix21.find((document) => document.company === company.id)
                                                        setDocNumber(doc.number)
                                                        setDocDate(doc.date)
                                                        setDocSelected(doc.id)
                                                        setModalDocIsOpen(true)
                                                    }}
                                                />
                                                <Button
                                                    className={"btn-success ms-auto"}
                                                    value={"Descarcă"}
                                                    loading={loadingDownload && loadingDownload[i]}
                                                    onClick={async () => {

                                                        let arr = {}
                                                        arr[i] = true
                                                        setLoadingDownload(arr)
                                                        await downloadDoc('Cerere de oferta-' + company.title, appendix21.find((document) => document.company === company.id).id, setLoadingDownload, false)
                                                    }}
                                                />

                                            </>}

                                            <Button
                                                className={"btn-primary ms-2"}
                                                value={"Regenerează"}
                                                onClick={() => {
                                                    setDocIdToDelete(appendix21.find((document) => document.company === company.id).id)
                                                    setCompany(company.id)
                                                    if (templates.filter((item) => item.type === 'appendix_21').length === 1)
                                                        setTemplate(templates.filter((item) => item.type === 'appendix_21')[0].value)
                                                    setModalIsOpen(true)

                                                }}
                                            />
                                        </> : <Button
                                            className={"btn-primary ms-auto"}
                                            value={"Generează"}
                                            onClick={() => {
                                                setDocIdToDelete(0)
                                                setCompany(company.id)
                                                if (templates.filter((item) => item.type === 'appendix_21').length === 1)
                                                    setTemplate(templates.filter((item) => item.type === 'appendix_21')[0].value)
                                                setModalIsOpen(true)
                                            }}
                                        />}
                                    </div>
                                </div>))}
                        </div>
                    </div>
                </div>
            </div>


            <div className={"footer d-flex justify-content-center"}>

            </div>
            <ModalComp
                title={(docIdToDelete === 0 ? 'Generează' : 'Regenerează')+' Cerere de oferta'}
                isOpen={modalIsOpen}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalIsOpen(false)}
            >
                <form onSubmit={(e) => generateDoc(e)}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <div
                            className={"form-label"}>Șablon<span
                            className={"form-required"}>*</span></div>
                        <Selector
                            options={templates.filter((item) => item.type === 'appendix_21')}
                            value={template}
                            required={true}
                            onChange={(option) => setTemplate(option.value)}
                            isSearchable={false}
                            isOptionDisabled={(option) => option.disabled}
                        />

                        <div
                            className={"form-label mt-3 me-2"}>

                            <input type={"checkbox"} checked={draft} onChange={(e) => setDraft(!draft)}/>
                            Salvează ca draft
                        </div>
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={'Generează'}
                                    type={'submit'}
                                    disabled={loadingGenerate}
                                    loading={loadingGenerate}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
            <ModalComp
                title={'Modifică furnizorii'}
                isOpen={modalProvidersIsOpen}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalProvidersIsOpen(false)}
            >
                <form onSubmit={(e) => save(e)}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>Furnizori
                            </div>
                            <Selector
                                options={companies}
                                value={providers}
                                onChange={(option) => {
                                    setProviders(Array.from(option, option => option.value))
                                }}
                                isSearchable={true}
                                isMulti={true}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={'Salvează'}
                                    type={'submit'}
                                    disabled={saveLoading}
                                    loading={saveLoading}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
            <ModalComp
                title={'Editare cerere '}
                isOpen={modalDocIsOpen}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalDocIsOpen(false)}
            >
                <form onSubmit={(e) => editDoc(e)}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <div>
                            <div
                                className={"form-label"}>Numar<span
                                className={"form-required"}>*</span></div>
                            <input type={"text"} className={"form-input"}
                                   value={docNumber} required
                                   onChange={(e) => setDocNumber(e.target.value)}
                            />
                        </div>
                        <div>
                            <div
                                className={"form-label"}>Data<span
                                className={"form-required"}>*</span></div>
                            <input type={"date"} className={"form-input"}
                                   value={docDate} required
                                   onChange={(e) => setDocDate(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={'Generează'}
                                    type={'submit'}
                                    disabled={loadingGenerate}
                                    loading={loadingGenerate}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
        </div>

    );
}

