import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import Selector from "../../../components/elements/Selector/Selector";
import {Editor} from "@tinymce/tinymce-react";
import {templates, templatesVariables} from "../../../utils/constants";

export default function AddTemplate() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')
    const editorRef = useRef(null);

    const [title, setTitle] = useState('')
    const [type, setType] = useState('appendix_13')
    const [projectType, setProjectType] = useState('all')
    const [initContent, setInitContent] = useState('')
    const [content, setContent] = useState('')

    const [saveLoading, setSaveLoading] = useState(false)

    useEffect(() => {
        setInitContent(templates[type])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type])

    const save = async (e) => {
        e.preventDefault();

        setSaveLoading(true)

        let payload = {
            title: title,
            type: type,
            project_type: projectType,
            content: content !== '' ? JSON.stringify(content) : JSON.stringify(initContent),
        }
        await axios.post(process.env.REACT_APP_API + '/templates', payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success("Informațiile au fost salvate cu succes!")
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })

        setSaveLoading(false)
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>Șabloane</h1>
                </div>

                <div className={"col-12 pb-4"}>

                    <div className="card">

                        <div className={"header"}><p>Adaugă șablon</p></div>

                        <form onSubmit={(e) => save(e)}>
                            <div className={"content"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Titlu<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required
                                                   value={title} onChange={(e) => setTitle(e.target.value)}/>
                                        </div>
                                    </div>

                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Tip<span
                                                className={"form-required"}>*</span></div>
                                            <Selector
                                                options={[{
                                                    value: 'appendix_13',
                                                    label: intl.formatMessage({id: 'templates.type.appendix_13'})
                                                },{
                                                    value: 'estimated',
                                                    label: intl.formatMessage({id: 'templates.type.estimated'})
                                                }, {
                                                    value: 'appendix_26',
                                                    label: intl.formatMessage({id: 'templates.type.appendix_26'})
                                                }, {
                                                    value: 'appendix_21',
                                                    label: intl.formatMessage({id: 'templates.type.appendix_21'})
                                                }, {
                                                    value: 'offer',
                                                    label: intl.formatMessage({id: 'templates.type.offer'})
                                                }, {
                                                    value: 'appendix_14',
                                                    label: intl.formatMessage({id: 'templates.type.appendix_14'})
                                                }, {
                                                    value: 'appendix_15',
                                                    label: intl.formatMessage({id: 'templates.type.appendix_15'})
                                                }, {
                                                    value: 'contract',
                                                    label: intl.formatMessage({id: 'templates.type.contract'})
                                                }, {
                                                    value: 'appendix_20',
                                                    label: intl.formatMessage({id: 'templates.type.appendix_20'})
                                                }, {
                                                    value: 'series',
                                                    label: intl.formatMessage({id: 'templates.type.series'})
                                                }]}
                                                value={type}
                                                onChange={(option) => setType(option.value)}
                                                isSearchable={false}
                                                isOptionDisabled={(option) => option.disabled}
                                            />

                                        </div>
                                    </div>
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Tip proiect<span
                                                className={"form-required"}>*</span></div>
                                            <Selector
                                                options={[{
                                                    value: 'all',
                                                    label: 'Toate'
                                                },{
                                                    value: 'pnras',
                                                    label: 'PNRAS'
                                                }, {
                                                    value: 'smartlab',
                                                    label: 'SMARTLAB'
                                                }, {
                                                    value: 'smartlab_smis',
                                                    label: 'SMARTLAB SMIS'
                                                }]}
                                                value={projectType}
                                                onChange={(option) => setProjectType(option.value)}
                                                isSearchable={false}
                                                isOptionDisabled={(option) => option.disabled}
                                            />

                                        </div>
                                    </div>
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Conținut<span
                                                className={"form-required"}>*</span></div>
                                            <Editor
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                apiKey='1rb156cyg9vg206vb6cdpcvzrnh4iiwcewgc0owv61j1t8ev'
                                                initialValue={initContent}
                                                onEditorChange={setContent}
                                                init={{
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'searchreplace'
                                                    ],
                                                    toolbar: 'undo redo | blocks | ' +
                                                        'bold italic forecolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | image link media | searchreplace |' +
                                                        'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | ' +
                                                        'help | code',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                            />
                                        </div>

                                        {
                                            templatesVariables[type].map((item) => (
                                                <Button key={item}
                                                        className={"btn-light mt-2 me-2"}
                                                        value={item}
                                                        onClick={() => editorRef.current.execCommand('mceInsertContent', false, '< ' + item + ' >')}
                                                />

                                            ))
                                        }

                                    </div>
                                </div>
                            </div>

                            <div className={"footer"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                        <Button
                                            className={"btn-light mt-2"}
                                            icon={"fa-solid fa-chevron-left"}
                                            value={"Înapoi"}
                                            onClick={() => navigate(-1)}
                                        />
                                    </div>
                                    <div className={"col-12 col-md-6 d-flex"}>
                                        <Button
                                            className={"btn-secondary mt-2 ms-auto"}
                                            icon={"fa-solid fa-plus"}
                                            value={"Adaugă"}
                                            disabled={saveLoading}
                                            loading={saveLoading}
                                            type={'submit'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

