import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import React from "react";
import LoadingCompHover from "../../../components/elements/LoadingCompHover/LoadingCompHover";
import UploadImageComp from "../../../components/Upload/UploadImageComp";
import SignaturePad from "react-signature-canvas";
import './Company.scss';
import {useNavigate} from "react-router-dom";

export default function AddCompany() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()

    const [title, setTitle] = useState('')
    const [fiscalCode, setFiscalCode] = useState('')
    const [change, setChange] = useState(0)
    const [address, setAddress] = useState('')
    const [county, setCounty] = useState('')
    const [city, setCity] = useState('')
    const [regComNumber, setRegComNumber] = useState('')
    const [legalRepresentativeName, setLegalRepresentativeName] = useState('')
    const [legalRepresentativeFunction, setLegalRepresentativeFunction] = useState('')
    const [bankAccount, setBankAccount] = useState('')
    const [bank, setBank] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [fax, setFax] = useState('')
    const [offerNumber, setOfferNumber] = useState(0)
    const [certificateNumber, setCertificateNumber] = useState(0)

    const [newImageStamp, setNewImageStamp] = useState(null)
    const [imageUrlStamp, setImageUrlStamp] = useState('')
    const [useImage, setUseImage] = useState(false)
    const [newImage, setNewImage] = useState(null)
    const [imageUrl, setImageUrl] = useState('')
    const [sigPad, setSigPad] = useState({})

    const [update, setUpdate] = useState(0)
    const [clientSecretOblio, setClientSecretOblio] = useState('')
    const [clientIdOblio, setClientIdOblio] = useState('')

    const [loadingCui, setLoadingCui] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)

    useEffect(() => {
        getCompanyInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [change])

    const getCompanyInfo = async () => {

        if ((fiscalCode.length === 8 && !fiscalCode.includes('RO')) || (fiscalCode.length === 10 && fiscalCode.includes('RO'))) {

            setLoadingCui(true)
            axios.post(process.env.REACT_APP_API + '/company-info',
                {fiscal_code: fiscalCode}, {
                    headers: {
                        'Authorization': 'Bearer ' + jwt
                    }
                })
                .then(async (response) => {
                    if (response.data.data) {
                        setRegComNumber(response.data.data.cod_inmatriculare)
                        setCounty(response.data.data.judet)
                        setCity(response.data.data.loc)
                        setAddress(response.data.data.adresa)
                        setTitle(response.data.data.nume)

                    }
                    setLoadingCui(false)
                })
                .catch(err => {
                    errorParser(err, intl)
                    setLoadingCui(false)
                })
        }
    }
    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }


        return new File([u8arr], filename, {type: mime});
    }
    const save = async (e) => {

        e.preventDefault();
        setSaveLoading(true)
        let formData = new FormData()
        if (newImage || sigPad) {
            formData.append('signature', useImage ? newImage : dataURLtoFile(sigPad.getTrimmedCanvas().toDataURL('image/png'), 'sign.png'))
        }
        if (newImageStamp) {
            formData.append('signatureAndStamp', newImageStamp)
        }
        formData.append('data', JSON.stringify({
            title: title,
            fiscal_code: fiscalCode,
            county: county,
            city: city,
            address: address,
            reg_com_number: regComNumber,
            legal_representative_name: legalRepresentativeName,
            legal_representative_function: legalRepresentativeFunction,
            bank_account: bankAccount,
            bank: bank,
            phone: phone,
            fax: fax,
            email: email,
            offer_number: offerNumber,
            certificateNumber: certificateNumber,
            client_id_oblio: clientIdOblio,
            client_secret_oblio: clientSecretOblio
        }))

        await axios.put(process.env.REACT_APP_API + '/companies', formData, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (parseInt(response.data.status) === 1) {
                    toast.success("Informațiile au fost salvate cu succes!")
                    navigate(-1)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })

        setSaveLoading(false)
    }

    const clear = () => {
        sigPad.clear()
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>Adaugă companie</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card" style={{position: "relative"}}>

                        {
                            loadingCui &&
                            <LoadingCompHover/>
                        }
                        <form onSubmit={(e) => save(e)}>
                            <div className={"content"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>CUI<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required={true}
                                                   value={fiscalCode} onChange={(e) => {
                                                setChange(change + 1)
                                                setFiscalCode(e.target.value)
                                            }}/>
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Denumire societate<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required={true}
                                                   value={title} onChange={(e) => setTitle(e.target.value)}/>
                                        </div>
                                    </div>


                                    <div className={"col-md-6 col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Nr. înreg. Registrul Comerțului<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} id={'reg-com-number-1'} required={true}
                                                   value={regComNumber} onChange={(e) => setRegComNumber(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Județ<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required={true}
                                                   value={county}
                                                   onChange={(e) => setCounty(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Oraș<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required={true}
                                                   value={city}
                                                   onChange={(e) => setCity(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Adresa<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required={true}
                                                   value={address} onChange={(e) => setAddress(e.target.value)}/>
                                        </div>
                                    </div>

                                    <div className={"col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Adresa de email<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"email"} className={"form-input"} id={'reg-com-number-1'} required={true}
                                                   value={email} onChange={(e) => setEmail(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Telefon<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} id={'reg-com-number-1'} required={true}
                                                   value={phone} onChange={(e) => setPhone(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Fax<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} id={'reg-com-number-1'} required={true}
                                                   value={fax} onChange={(e) => setFax(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Nume reprezentant legal<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required={true}
                                                   value={legalRepresentativeName}
                                                   onChange={(e) => setLegalRepresentativeName(e.target.value)}/>
                                        </div>
                                    </div>

                                    <div className={"col-md-6 col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Funcție reprezentant legal<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required={true}
                                                   value={legalRepresentativeFunction}
                                                   onChange={(e) => setLegalRepresentativeFunction(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Cont bancar<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required={true}
                                                   value={bankAccount} onChange={(e) => setBankAccount(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Bancă<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required={true}
                                                   value={bank} onChange={(e) => setBank(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Ultimul număr de ofertă
                                            </div>
                                            <input type={"text"} className={"form-input"}
                                                   value={offerNumber} onChange={(e) => setOfferNumber(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Număr certificat constatator
                                            </div>
                                            <input type={"text"} className={"form-input"}
                                                   value={certificateNumber} onChange={(e) => setCertificateNumber(e.target.value)}/>
                                        </div>
                                    </div>

                                    <div className={"col-md-6 col-md-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Email OBLIO (facturare)</div>
                                            <input type={"text"} className={"form-input"}
                                                   value={clientIdOblio} onChange={(e) => setClientIdOblio(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-md-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Client secret OBLIO (facturare)</div>
                                            <input type={"text"} className={"form-input"}
                                                   value={clientSecretOblio} onChange={(e) => setClientSecretOblio(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-12"}>

                                        <div className={"form-label"}>Adaugă semnătura și ștampila</div>
                                        <div className={"tab-content p-2"}>

                                            <UploadImageComp
                                                image={newImageStamp}
                                                setImage={setNewImageStamp}
                                                imageUrl={imageUrlStamp}
                                                setImageUrl={setImageUrlStamp}
                                                className={"p-3"}
                                            />
                                        </div>

                                    </div>
                                    <div className={"col-12"}>

                                        <div className={"form-label"}>Adaugă semnătura</div>
                                        <div className={"tab-switch with-content"}>
                                            <div className={"tab " + (!useImage ? "active" : "")} onClick={() => setUseImage(false)}>
                                                Semnează aici
                                            </div>
                                            <div className={"tab " + (useImage ? "active" : "")} onClick={() => setUseImage(true)}>
                                                Încarcă propria imagine
                                            </div>
                                        </div>
                                        <div className={"tab-content p-2"}>
                                            {
                                                useImage ?
                                                    <UploadImageComp
                                                        image={newImage}
                                                        setImage={setNewImage}
                                                        imageUrl={imageUrl}
                                                        setImageUrl={setImageUrl}
                                                        className={"p-3"}
                                                    />
                                                    :
                                                    <div>
                                                        <div className={"signature-container"}
                                                             onMouseUp={() => {
                                                                 setUpdate(update + 1)
                                                             }
                                                             }
                                                        >
                                                            <SignaturePad ref={(ref) => {
                                                                setSigPad(ref)
                                                            }} canvasProps={{className: 'sigCanvas'}}/>
                                                            <div key={update} className={((sigPad && Object.keys(sigPad).length === 0)
                                                            || (sigPad && sigPad.isEmpty()) ? 'signature-overlay' : 'd-none')}>
                                                                <i className="fa-solid fa-pencil icon"></i>
                                                                <div className={"title"}>Adaugă aici semnătura ta</div>
                                                            </div>
                                                        </div>


                                                        <Button key={update}
                                                                className={"btn-gray mt-2 ms-auto " + ((sigPad && Object.keys(sigPad).length === 0)
                                                                || (sigPad && sigPad.isEmpty()) ? 'disabled' : '')}
                                                                style={{width: '100%'}}
                                                                icon={"fa-solid fa-trash"}
                                                                value={'Șterge'}
                                                                type={'button'}
                                                                disabled={(sigPad && Object.keys(sigPad).length === 0)
                                                                    || (sigPad && sigPad.isEmpty())}
                                                                onClick={() => clear()}
                                                        />
                                                    </div>
                                            }
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div className={"footer"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                        <Button
                                            className={"btn-light mt-2"}
                                            icon={"fa-solid fa-chevron-left"}
                                            value={"Înapoi"}
                                            onClick={() => navigate(-1)}
                                        />
                                    </div>
                                    <div className={"col-12 col-md-6 d-flex"}>
                                        <Button
                                            className={"btn-secondary mt-2 ms-auto"}
                                            icon={"fa-regular fa-floppy-disk"}
                                            value={"Adaugă"}
                                            disabled={saveLoading}
                                            loading={saveLoading}
                                            type={"submit"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

