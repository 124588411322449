export const templates = {
    'appendix_13': `<div style="font-family: Calibri;">
<div>
<p style="text-align: right;">Anexa 13 - Model Referat de necesitate</p>
<p style="text-align: left;"><br>Schema de Granturi: Programul Național pentru Reducerea Abandonului Școlar<br>Beneficiar: &lt; Denumirea instituției &gt;<br>Titlul proiectului: &lt; Titlul proiectului &gt;<br>Contract de finanțare Nr. &lt; Numărul contractului de finanțare &gt; din data: &lt; Data contractului de finanțare &gt;</p>
<p style="text-align: right;"><strong>APROB.</strong><br><strong>Reprezentant legal</strong></p>
<p style="text-align: center;"><strong>Referat de necesitate<span style="color: rgb(251, 238, 184);"> <span style="color: rgb(224, 62, 45);">nr.... din data........</span></span></strong><br><strong>privind achiziția de &lt; Denumirea achiziției &gt;</strong></p>
<p>Introducere: Reforma sistemului de invatamant obligatoriu pentru prevenirea si reducerea parasirii timpurii a scolii<br>Descrierea obiectului contractului: Implementarea Proiectului cod &lt; Cod proiect &gt;&nbsp;<br>Motivația achiziției: &lt; Motivație &gt;<br>Valoarea estimată: &lt; Valoarea totală estimată &gt;</p>
&lt; Tabel cu necesități &gt;
<p>Cheltuielile cu achiziția de &lt; Denumirea achiziției &gt; sunt prevăzute in bugetul proiectului &ndash; Anexa 3 la Contractul de finanțare, la capitolul bugetar&nbsp;<span style="color: rgb(224, 62, 45);">ACTIVITĂȚI MATE, PREVENIRE, INTERVENȚIE / ACTIVITĂȚI DE COMPENSARE / DIGITALIZAREA PROCESULUI EDUCAȚIONAL</span></p>
<p>&Icirc;n urma analizei de piață a fost identificată următoarea listă a furnizorilor <span style="color: rgb(224, 62, 45);">care conform informațiilor disponibile pe Internet / &icirc;n baza unor colaborări profesionale anterioare</span>, dispun de calificarea și capacitatea de a furniza bunurile vizate.</p>
&lt; Tabel cu furnizori &gt;
<p>Ca urmare a celor de mai sus, s-a &icirc;ntocmit prezentul referat de necesitate, &icirc;n scopul inițierii procedurii de achiziție &icirc;n cadrul grantului.</p>
<p>Nume &lt; Reprezentant instituției &gt;<br>Funcție &lt; Funcție reprezentant instituție &gt;</p>
<p>Semnătură</p>
</div>
<div style="display: block; page-break-before: always;">&nbsp;</div>
<div>
<p style="text-align: right;">Anexa 13.1 - Specificații tehnice (produse)</p>
<p style="text-align: center;"><br><strong>FORMULAR DE SPECIFICAȚII TEHNICE&nbsp;</strong><br><strong>&nbsp; &nbsp; Achiziția de produse</strong></p>
<p style="text-align: left;"><strong>Denumirea achiziției: &lt; Denumirea achiziției &gt;</strong><br><strong>Specificații tehnice solicitate: Conform Ordinului de Ministru 3.677 din 2023</strong></p>
&lt; Tabel specificații tehnice &gt;
<p style="text-align: left;"><br>Nume &lt; Reprezentant instituției &gt;<br>Funcție &lt; Funcție reprezentant instituție &gt;<br>Semnătură</p>
<div dir="ltr" align="center">&nbsp;</div>
<p>&nbsp;</p>
</div>
</div>`,
    'estimated': `<div style="font-family: Calibri;">
<div style="font-family: Calibri;">
<p class="MsoBodyText3" align="right"><strong><span lang="FR">Anexa 6-- Modele documente pentru achiziții</span></strong></p>
<h4><strong><span lang="RO">Model Notă estimativă</span></strong></h4>
<p class="MsoNormal"><span lang="RO">Apelul </span><span lang="RO">Dotarea cu laboratoare inteligente a unităților de &icirc;nvățăm&acirc;nt secundar superior, a palatelor și a cluburilor copiilor</span></p>
<p>Beneficiar: &lt; Denumirea instituției &gt;<br>Titlul proiectului: &lt; Titlul proiectului &gt;<br>Contract de finanțare Nr. &lt; Numărul contractului de finanțare &gt; din data &lt; Data contractului de finanțare &gt;</p>
<p style="text-align: right;"><br><strong>APROB.</strong><br><strong>Reprezentant legal</strong></p>
<p>&nbsp;</p>
<p style="text-align: center;"><strong>Notă estimativă <span style="color: rgb(224, 62, 45);">nr.... din data........</span></strong><br><strong>privind <span lang="RO">valoarea achiziției</span>&nbsp;de &lt; Denumirea achiziției &gt;</strong></p>
<p>&nbsp;</p>
<p class="MsoNormal"><span lang="RO">Valoarea estimată: &lt; Valoarea totală estimată &gt;</span></p>
<p class="MsoNormal">&lt; Tabel cu necesități &gt;</p>
<p class="MsoListParagraphCxSpFirst"><!-- [if !supportLists]--><strong><span lang="EN-US">A.&nbsp;&nbsp;&nbsp; </span></strong><!--[endif]--><span lang="EN-US">Total, fară TVA = &lt; Valoarea evaluată totală, fără TVA &gt;lei</span></p>
<p class="MsoListParagraphCxSpMiddle"><!-- [if !supportLists]--><strong><span lang="EN-US">B.&nbsp;&nbsp;&nbsp; </span></strong><!--[endif]--><span lang="EN-US">TVA= &lt; Valoarea TVA &gt;lei</span></p>
<p class="MsoListParagraphCxSpLast"><!-- [if !supportLists]--><strong><span lang="EN-US">C.&nbsp;&nbsp;&nbsp; </span></strong><!--[endif]--><span lang="EN-US">TOTAL= &lt; Valoarea totală, inclusiv TVA &gt;lei</span></p>
<p class="MsoNormal"><span lang="EN-US">Estimarea valorii s-a realizat pe baza urm</span><span lang="RO">ă</span><span lang="EN-US">toarelor documente/ informații:</span></p>
<p class="MsoListParagraphCxSpFirst"><!-- [if !supportLists]--><span lang="EN-US">&yuml;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><!--[endif]--><span lang="EN-US">studiu de piată</span></p>
<p class="MsoListParagraphCxSpMiddle"><!-- [if !supportLists]--><span lang="EN-US">&yuml;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><!--[endif]--><span lang="EN-US">cerere de ofertă</span></p>
<p class="MsoListParagraphCxSpMiddle"><!-- [if !supportLists]--><span lang="EN-US">&yuml;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><!--[endif]--><span lang="EN-US">contracte similare precedente</span></p>
<p class="MsoListParagraphCxSpLast"><!-- [if !supportLists]--><span lang="FR">&yuml;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><!--[endif]--><span lang="FR">alte surse (cataloage, website, etc.)</span></p>
<p class="MsoNormal">&nbsp;</p>
<p class="MsoNormal"><span lang="RO">Nume/Prenume</span></p>
<p class="MsoNormal"><span lang="RO">Funcție</span></p>
<p class="MsoNormal"><span lang="RO">Semnătură</span></p>
<p class="MsoNormal"><em><span lang="RO">&nbsp;</span></em></p>
</div>
</div>`,
    'appendix_26': `<div style="font-family: Calibri;">
<div>
<p style="font-family: Calibri; text-align: right;">Anexa 26 - Model Notă justificativă privind aplicarea achiziției directe</p>
<p>Schema de Granturi: Programul Național pentru Reducerea Abandonului Școlar<br>Beneficiar: &lt; Denumirea instituției &gt;<br>Titlul proiectului: &lt; Titlul proiectului &gt;<br>Contract de finanțare Nr. &lt; Numărul contractului de finanțare &gt; din data &lt; Data contractului de finanțare &gt;</p>
<p style="text-align: right;"><br><strong>APROB.</strong><br><strong>Reprezentant legal</strong></p>
<p>&nbsp;</p>
<p style="text-align: center;"><strong>Notă justificativă <span style="color: rgb(224, 62, 45);">nr.... din data........</span></strong><br><strong>privind aplicarea achiziției directe pentru realizarea achizitiei de &lt; Denumirea achiziției &gt;</strong></p>
<p>&nbsp;</p>
<p>Introducere: Reforma sistemului de invatamant obligatoriu pentru prevenirea si reducerea parasirii timpurii a scolii</p>
<p>Descrierea obiectului contractului: Implementarea Proiectului cod &lt; Cod proiect &gt;&nbsp;</p>
<p>Valoarea estimată aprobată prin Referatul de necesitate <span style="color: rgb(224, 62, 45);">nr..... din data..., </span>prentru achiziția de &lt; Denumirea achiziției &gt; este de &lt; Valoarea totală estimată &gt;.</p>
&lt; Tabel cu necesități &gt;
<p>&nbsp;</p>
<p>Metoda de achiziție aplicată:&nbsp;<span style="color: rgb(224, 62, 45);"> <span style="color: rgb(0, 0, 0);">Metoda </span>1/2/3/4, folosind o oferta/ 3 oferte <span style="color: rgb(0, 0, 0);">și ulterior achiziția se va efectua prin catalogul electronic utiliz&acirc;nd SEAP-ul.</span></span></p>
<p>Ca urmare a celor de mai sus, s-a &icirc;ntocmit prezenta notă justificativă, &icirc;n scopul inițierii procedurii de achiziție &icirc;n cadrul grantului.</p>
<p>Nume &lt; Reprezentant instituției &gt;<br>Funcție &lt; Funcție reprezentant instituție &gt;<br>Semnătură</p>
</div>
</div>`,
    'appendix_21': `<div style="font-family: Calibri;">
<div>
<p style="text-align: right;">Anexa 23 &ndash; Model cerere de ofertă (produse)&nbsp;</p>
<p>Schema de Granturi: Programul Național pentru Reducerea Abandonului Școlar<br>Beneficiar: &lt; Denumirea instituției &gt;<br>Titlul proiectului: &lt; Titlul proiectului &gt;<br>Contract de finanțare Nr. &lt; Numărul contractului de finanțare &gt; din data &lt; Data contractului de finanțare &gt;</p>
<p style="text-align: center;"><strong>CERERE DE OFERTĂ&nbsp;</strong><br><span style="color: rgb(224, 62, 45);"><strong>nr.... din data........</strong></span><br>Achiziția de &lt; Denumirea achiziției &gt;</p>
<p><br>Către: &lt; Denumire ofertant &gt;<br>Adresa: &lt; Adresă ofertant &gt;<br>Telefon/e-mail: &lt; Telefon si e-mail ofertant &gt;<br>&Icirc;n atenția doamnei/ domnului &lt; Reprezentant ofertant &gt;</p>
<p><br>Stimată doamnă/ Stimate domnule &lt; Reprezentant ofertant &gt;,</p>
<p>&lt; Denumirea instituției &gt; implementează, in calitate de beneficiar, proiectul &lt; Titlul proiectului &gt;, &icirc;n cadrul Schemei de Granturi Programul Național pentru Reducerea Abandonului Școlar, parte a Planului Național pentru Redresare și Reziliență al Rom&acirc;niei (PNRR) şi intenționează să utilizeze o parte din fonduri pentru achiziția produselor pentru care a fost emisă prezenta cerere de ofertă.</p>
<p>&Icirc;n acest sens, sunteti invitaţi să trimiteţi oferta dumneavoastră de preţ pentru următoarele produse:</p>
&lt; Tabel cu necesități &gt;
<p>Specificații tehnice solicitate pentru fiecare dintre produse:</p>
&lt; Tabel specificații tehnice &gt;
<p dir="ltr">Valoarea estimată a achiziției este de: &lt; Valoarea totală estimată &gt; lei fără TVA</p>
<p>Oferta dumneavoastră, &icirc;n formatul indicat &icirc;n Anexa la prezenta Cerere de Ofertă, va fi depusă &icirc;n conformitate cu termenii şi condiţiile de prestare precizate și va fi trimisă la:<br>Adresa:&lt; Adresă instituției &gt;<br>Telefon/Fax:&lt; Telefon instituție &gt;<br>E-mail:&lt; E-mail instituției &gt;<br>Persoană de contact: &lt; Reprezentant instituției &gt;<br>Se acceptă oferte transmise &icirc;n original, prin e-mail sau fax.</p>
<p>Preţul total ofertat trebuie să includă şi preţul pentru transport, instalare şi orice alte costuri necesare livrării produsului la următoarea destinatie finală: &lt; Adresă instituției &gt; &nbsp;Oferta va fi exprimată &icirc;n Lei, iar TVA va fi indicat separat, si va avea valoarea de 19%</p>
<p dir="ltr">Livrarea se efectuează &icirc;n cel mult &lt; Termen de livrare &gt; de zile de la semnarea Contractului, la destinația finală indicată.</p>
<p>Plata facturii se va efectua &icirc;n lei, dupa livrarea efectivă a produselor la destinaţia finală indicată, pe baza facturii Furnizorului şi a procesului - verbal de recepţie, in termen de 90 de zile.</p>
<p>Oferta dvs. trebuie să fie &icirc;nsoțită de o copie a Certificatului de &Icirc;nregistrare sau a Certificatului Constatator eliberat de Oficiul Registrului Comerțului din care să rezulte numele complet, sediul și domeniul de activitate.</p>
<p>Vă informăm asupra: (i) obligației noastre, &icirc;n calitate de autoritate contractantă, de a aplica prevederile Instrucțiunii nr. 6/30.08.2022 emisă de Ministerul Investițiilor și Proiectelor Europene, referitoare la colectarea și accesul la datele privind beneficiarii reali ai destinatarilor fondurilor/contractanților din cadrul PNRR și, totodată, asupra (ii) obligației dvs. de a transmite datele și informațiile cu privire la beneficiarii reali ai destinatarilor fondurilor alocate din PNRR, &icirc;naintea semnării contractului de achiziție. &Icirc;n acest din urmă caz, se va semna, &icirc;n mod obligatoriu, &icirc;naintea semnării contractului de achiziție, o declarație pe propria răspundere care va conține datele privind beneficiarii reali (cel puțin nume, prenume și data nașterii). Această obligație va fi aplicabilă inclusiv subcontractorilor.</p>
<p>Doar ofertele depuse de ofertanți calificați și care &icirc;ndeplinesc cerințele tehnice vor fi evaluate prin compararea preţurilor. Contractul se va acorda firmei care &icirc;ndeplinește toate specificațiile tehnice solicitate și care oferă cel mai mic preţ total evaluat, fără TVA.</p>
<p>Vă rugăm să transmiteți oferta dvs. prin depunere direct la dresa menționată mai sus sau prin e-mail sau fax, p&acirc;nă cel t&acirc;rziu la data de &lt; Termen depunere oferte &gt;.</p>
<p>Orice potențial ofertant are dreptul de a solicita clarificări legate de prezenta cerere de ofertă, p&acirc;nă cel t&acirc;r<span style="color: rgb(0, 0, 0);">ziu cu de &lt; Numărul de zile &icirc;nainte de data limită de depunere a clarificărilor &gt; zile &icirc;nainte de data limită de depunere a ofertelor, prin transmitere direct la dresa menționată mai sus sau prin e-mail sau fax a solicitărilor de clarificări.</span></p>
<p><span style="color: rgb(0, 0, 0);">Oferta dvs. trebuie să fie valabilă timp de &lt; Numărul de zile pentru care ofertantul trebuie să mențină valabilitatea ofertei &gt; zile de la data limită pentru transmiterea ofertei.</span><br><span style="color: rgb(0, 0, 0);">&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span><br>Vă rugăm să confirmaţi &icirc;n scris primirea prezentei Cereri de ofertă şi să menţionaţi dacă urmează să depuneţi o ofertă sau nu.<br>&nbsp; &nbsp;&nbsp;<br>Nume &lt; Reprezentant instituției &gt;<br>Funcție &lt; Funcție reprezentant instituție &gt;<br>Semnătură</p>
<p dir="ltr">&nbsp;</p>
<p>&nbsp;</p>
</div>
</div>`,
    'offer': `<div style="font-family: Calibri;">
<div>
<p>&nbsp;Ofertant: &lt; Denumire ofertant &gt;<br>Adresa: &lt; Adresă ofertant &gt;<br>Telefon/e-mail: &lt; Telefon si e-mail ofertant &gt;</p>
<p style="text-align: center;"><strong>OFERTĂ&nbsp;</strong><br><strong>nr &lt; Numar ofertă &gt; din data &lt; Dată ofertă &gt;</strong><br>Achiziția de &lt; Denumirea achiziției &gt;</p>
<p>Stimate doamne, stimați domni,</p>
<p>Ca răspuns la cererea dvs. de ofertă nr. &lt; Numar cerere ofertă &gt; din data.&lt; Dată cerere ofertă &gt;, vă transmitem &icirc;n cele ce urmează oferta noastră de preț pentru achiziția de &lt; Denumirea achiziției &gt;:</p>
&lt; Tabel cu produsele ofertate &gt;
<p>Preţul indicat mai sus este ferm şi fix şi nu va fi modificat pe durata executării contractului.<br>Preţul total ofertat include şi preţul orice alte costuri necesare prestării serviciilor.</p>
<p>Prestarea se efectuează &icirc;n cel mult &lt; Termen de livrare &gt; zile de la semnarea Contractului/ Notei de Comandă, conform următorului grafic:&nbsp;</p>
&lt; Tabel cu termenul de livrare &gt;
<p>&Icirc;nțelegem că plata facturii se va efectua &icirc;n lei, 100% la prestarea efectivă a serviciilor, pe baza facturii Prestatorului şi a procesului - verbal de recepţie.</p>
<p>Specificații tehnice pentru fiecare dintre serviciile ofertate:</p>
&lt; Tabel cu specificațiile tehnice ofertate &gt;
<p>Oferta noastră este valabilă timp de de &lt; Valabilitate ofertă &gt; zile de la data limită pentru transmiterea ofertei.</p>
<p><br>Oferta este &icirc;nsoțită de o copie a Certificatului de &Icirc;nregistrare sau a Certificatului Constatator eliberat de Oficiul Registrului Comerțului din care să rezulte numele complet, sediul și domeniul de activitate al firmei.</p>
<p>NUMELE OFERTANTULUI&nbsp;<br>Semnătură autorizată&nbsp;<br>Locul:&lt; Adresă ofertant &gt;<br>Data:&lt; Dată ofertă &gt;</p>
<p>&lt; Semnătura și ștampila ofertant &gt;</p>
</div>
</div>`,
    'appendix_14': `<div style="font-family: Calibri;">
<div>
<p style="text-align: right;"><br>Anexa 14 &ndash; Model PV de selecție oferte</p>
<p>Schema de Granturi: Programul Național pentru Reducerea Abandonului Școlar<br>Beneficiar: &lt; Denumirea instituției &gt;<br>Titlul proiectului: &lt; Titlul proiectului &gt;<br>Contract de finanțare Nr. &lt; Numărul contractului de finanțare &gt; din data &lt; Data contractului de finanțare &gt;</p>
<p style="text-align: center;"><br><strong>PROCES - VERBAL DE SELECȚIE A OFERTELOR</strong></p>
<p style="text-align: center;"><strong>privind achiziția de &lt; Denumirea achiziției &gt;</strong></p>
<p><span style="color: rgb(224, 62, 45);">&Icirc;n data de ..........................</span>, a fost lansată Cererea de Ofertă/ a fost publicat anunțul <span style="color: rgb(224, 62, 45);">nr. ............................... </span>&icirc;n vederea achiziție de &lt; Denumirea achiziției &gt;, p&acirc;nă la expirarea termenului limită &lt; Termen depunere oferte &gt; au fost primite următoarele oferte:</p>
<p>&lt; Listă oferte primite &gt;<br>etc.</p>
<p>Ofertele primite după expirarea termenului nu au mai fost analizate, fiind respinse, acestea fiind următoarele:<br>- Nu au fost primite oferte dupa expirarea termenului.&nbsp;<br>etc.</p>
<p>&Icirc;n urma examinării documentelor depuse, Comisia de evaluare a stabilit că următorii ofertanți se califică pentru furnizarea produselor/ execuția lucrărilor/ prestarea serviciilor care fac obiectul prezentei proceduri de achiziție, &icirc;ndeplinind cerințele de calificare stipulate &icirc;n Cererea de Ofertă/ Anunțul <span style="color: rgb(224, 62, 45);">nr.&nbsp; &nbsp;.......................</span>, iar valabilitatea ofertei respectă termenul minim solicitat.</p>
&lt; Tabel ofertanti calificați &gt;
<p><br>&Icirc;n urma evaluării din punct de vedere tehnic a ofertelor calificate, s-au constatat următoarele:</p>
&lt; Tabele cu evaluarea ofertelor tehnic &gt;<br>
<p>&Icirc;n urma verificării financiare a ofertelor s-au constatat următoarele:</p>
&lt; Tabele cu evaluarea ofertelor financiar &gt;<br>&nbsp;<br>Clasificarea ofertelor: &lt; Tabel clasificarea ofertelor &gt;
<p><strong>Atribuirea contractului:</strong><br>&Icirc;n urma ofertelor primite, ofertantul &nbsp;c&acirc;știgător al contractului pentru achiziția de &lt; Denumirea achiziției &gt; este: &lt; Denumire ofertant c&acirc;știgător &gt;, cu următoarea valoare a contractului:</p>
<table style="border-collapse: collapse; width: 100%; border: 1px solid rgb(0, 0, 0); height: 58.7814px;" border="1"><colgroup><col style="width: 50%;"><col style="width: 50%;"></colgroup>
<tbody>
<tr style="height: 19.5938px;">
<td style="border-width: 1px; border-color: rgb(0, 0, 0); height: 58.7814px;" rowspan="3">
<p dir="ltr"><strong>Valoarea contractului </strong><strong>(LEI)</strong></p>
<strong id="docs-internal-guid-1a827415-7fff-652a-dc1a-ba3f07aca816"></strong></td>
<td style="border-width: 1px; border-color: rgb(0, 0, 0); height: 19.5938px;">&lt; Valoarea evaluată totală, fără TVA &gt;</td>
</tr>
<tr style="height: 19.5938px;">
<td style="border-width: 1px; border-color: rgb(0, 0, 0); height: 19.5938px;">&lt; Valoarea TVA &gt;</td>
</tr>
<tr style="height: 19.5938px;">
<td style="border-width: 1px; border-color: rgb(0, 0, 0); height: 19.5938px;">&lt; Valoarea totală, inclusiv TVA &gt;</td>
</tr>
</tbody>
</table>
<p><br>Semnătura persoanei &icirc;nsărcinate cu realizarea achizițiilor &icirc;n cadrul grantului:<br>___________________________</p>
<p>Data: ____/______/_________</p>
<p>&nbsp;</p>
</div>
</div>`,
    'appendix_15': `<div style="font-family: Calibri;">
<div>
<p style="text-align: right;">Anexa 15 - Model comunicare rezultat selecție oferte</p>
<p><br>Schema de Granturi: Programul Național pentru Reducerea Abandonului Școlar<br>Beneficiar: &lt; Denumirea instituției &gt;<br>Titlul proiectului: &lt; Titlul proiectului &gt;<br>Contract de finanțare Nr. &lt; Numărul contractului de finanțare &gt; din data &lt; Data contractului de finanțare &gt;</p>
<p>Către: &lt; Denumire ofertant &gt;<br>Adresa: &lt; Adresă ofertant &gt;<br>Telefon/e-mail: &lt; Telefon si e-mail ofertant &gt;<br>&Icirc;n atenția doamnei/ domnului &lt; Reprezentant ofertant &gt;</p>
<p style="text-align: center;"><br><strong>COMUNICARE PRIVIND REZULTATUL PROCESULUI DE SELECȚIE A OFERTELOR</strong><br><strong>privind achiziția de &lt; Denumirea achiziției &gt;</strong></p>
<p><br>Stimată doamnă/ Stimate domnule &lt; Reprezentant ofertant &gt;,</p>
<p>&lt;următorul text se introduce &icirc;n cazul comunicării cu ofertantul clasat pe locul I&gt;<br>Vă facem cunoscut că, &icirc;n urma evaluării ofertelor depuse &icirc;n cadrul procesului de achiziție de&lt; Denumirea achiziției &gt;, oferta dvs. s-a clasat pe locul I și este recomandată pentru acordarea contractului, cu un preț total evaluat de &lt; Valoarea evaluată totală, fără TVA &gt; <strong>lei</strong> fara TVA, &lt; Valoarea TVA &gt; <strong>lei </strong>TVA si &lt; Valoarea totală, inclusiv TVA &gt; <strong>lei</strong> Total cu TVA</p>
<p>&nbsp;</p>
<p>&lt;următorul text se introduce &icirc;n cazul comunicării cu ofertanții clasați &icirc;ncep&acirc;nd cu locul II&gt;<br>Vă facem cunoscut că, &icirc;n urma evaluării ofertelor depuse &icirc;n cadrul procesului de achiziție de &lt; Denumirea achiziției &gt;, oferta dvs. s-a clasat pe locul &lt; Locul in clasament &gt; &nbsp;și nu este recomandată pentru acordarea contractului. &nbsp;<br>Oferta clasată pe locul I are un preț total evaluat de &lt; Valoarea evaluată totală, fără TVA &gt; <strong>lei</strong> fara TVA, &lt; Valoarea TVA &gt; <strong>lei </strong>TVA si &lt; Valoarea totală, inclusiv TVA &gt; <strong>lei</strong> Total cu TVA</p>
<p>&nbsp;</p>
<p>&lt;următorul text se introduce &icirc;n cazul comunicării cu ofertanții respinși din punct de vedere al ne&icirc;ndeplinirii cerințelor de calificare sau din punct de vedere al ne&icirc;ndeplinirii specificațiilor tehnice&gt;</p>
<p>Vă facem cunoscut că, &icirc;n urma evaluării ofertelor depuse &icirc;n cadrul procesului de achiziție de &lt; Denumirea achiziției &gt;, oferta dvs. a fost respinsă din cauza............................................................................................................................................................................................<br>Oferta clasată pe locul I are un preț total evaluat de&lt; Valoarea evaluată totală, fără TVA &gt; <strong>lei</strong> fara TVA, &lt; Valoarea TVA &gt; <strong>lei </strong>TVA si &lt; Valoarea totală, inclusiv TVA &gt; <strong>lei</strong> Total cu TVA.</p>
<p>Semnătura reprezentant unitate de &icirc;nvățăm&acirc;nt,<br>___________________________</p>
<p>Data: ____/______/_________</p>
</div>
</div>`,
    'contract': `<div style="font-family: Calibri;">
<p class="MsoNormal" style="margin-bottom: 0cm; line-height: normal; text-align: right;" align="center"><span lang="RO" style="font-size: 12.0pt; color: #222222;">Anexa 16 - Model Contract (produse)&nbsp;</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: center; line-height: normal;" align="center"><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="font-size: 12.0pt; color: #222222;">CONTRACT</span></strong></p>
<p class="MsoNormal" style="margin-bottom: 0cm; line-height: normal; tab-stops: 1.0cm; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;"><span lang="RO" style="color: black;">&nbsp;</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: center; line-height: normal; tab-stops: 1.0cm;" align="center"><span lang="RO">privind<em style="mso-bidi-font-style: normal;"><span style="mso-spacerun: yes;">&nbsp; </span><strong style="mso-bidi-font-weight: normal;">Achizitia de &lt; Denumirea achiziției &gt;</strong></em></span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: center; line-height: normal; tab-stops: 1.0cm;" align="center"><span lang="RO">&nbsp;</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: center; line-height: normal; tab-stops: 1.0cm;" align="center"><span lang="RO" style="color: rgb(224, 62, 45);">Nr. <span style="background-color: rgb(217, 217, 217);">...........</span><em style="mso-bidi-font-style: normal;"><span style="background: white; mso-highlight: white;">&nbsp;</span></em>din data &hellip;&hellip;&hellip;&hellip;&hellip;&hellip;.</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; line-height: normal; tab-stops: 1.0cm;"><span lang="RO">&nbsp;</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; line-height: normal; tab-stops: 1.0cm;"><span lang="RO">&nbsp;</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: justify; line-height: normal;"><span lang="RO">Prezentul <em style="mso-bidi-font-style: normal;">Contract de achiziție de produse</em>, (denumit &icirc;n continuare &bdquo;<strong style="mso-bidi-font-weight: normal;">Contract&rdquo;</strong>)<em style="mso-bidi-font-style: normal;">, </em>s-a &icirc;ncheiat </span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; line-height: normal; tab-stops: 1.0cm;"><span lang="RO">&icirc;ntre:</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: justify; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;"><span lang="RO">&lt; Denumirea instituției &gt;, cu sediul in &lt; Adresă instituției &gt; <span style="color: black;">telefon</span>/f<span style="color: black;">ax:</span> &lt; Fax instituție &gt;<span style="color: black;">, mobil:</span><span style="mso-spacerun: yes;"> &lt; Telefon instituție &gt;</span>,<span style="color: black;"> e-mail: &lt; E-mail instituției &gt;</span>, cod<span style="color: black;"> de &icirc;nregistrare fiscală &lt; CUI-ul instituției &gt;,</span>&nbsp;<span style="color: black;">cont IBAN nr. &lt; Contul de trezorerie al instituției &gt;, deschis la &lt; Trezoreria instituției &gt;,reprezentată prin &lt; Reprezentant instituției &gt;</span><span style="color: black;">&icirc;n calitate de &lt; Funcție reprezentant instituție &gt;,<em style="mso-bidi-font-style: normal;">&nbsp;</em>și denumită &icirc;n continuare &bdquo;<strong style="mso-bidi-font-weight: normal;"><em style="mso-bidi-font-style: normal;">Achizitor</em>&rdquo;</strong>, pe de o parte</span></span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: justify; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;"><span lang="RO" style="color: black;">și</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: justify; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;"><span lang="RO" style="font-size: 12.0pt; font-family: 'Times New Roman',serif;">&lt; Denumire ofertant &gt;, cu sediul in &lt; Adresă ofertant &gt;, inregistrata la Oficiul Registrului Comertului sub nr. &lt; Nr. &icirc;nreg. Registrul Comerțului ofertant &gt; , CUI &lt; CUI-ul ofertant &gt;, Cont analitic: &lt; Contul de trezorerie al ofertant &gt; deschis la &lt; Trezoreria ofertant &gt;, Tel. &lt; Telefon ofertant &gt;, e-mail: &lt; E-mail ofertant &gt;, societate reprezentata de &lt; Reprezentant ofertant &gt;, <span style="color: rgb(224, 62, 45);">CNP: ...................... , eliberat de ......................................., </span>i</span><span lang="RO" style="color: black;">n calitate de &lt; Funcție reprezentant ofertant &gt; și denumită &icirc;n continuare <strong style="mso-bidi-font-weight: normal;">&bdquo;<em style="mso-bidi-font-style: normal;">Furnizor</em>&rdquo;</strong>, pe de altă parte,</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: justify; line-height: normal;"><span lang="RO">denumite, &icirc;n continuare, &icirc;n mod individual "<strong style="mso-bidi-font-weight: normal;"><em style="mso-bidi-font-style: normal;">Partea</em></strong>" și &icirc;mpreună, "<strong style="mso-bidi-font-weight: normal;"><em style="mso-bidi-font-style: normal;">Părțile</em></strong>".</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: justify; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;"><span lang="RO" style="color: black;">&nbsp;</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: justify; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;"><span lang="RO" style="color: black;">au convenit &icirc;ncheierea prezentului <em style="mso-bidi-font-style: normal;">Contract</em>, astfel:</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: justify; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;"><span lang="RO" style="color: black;">&nbsp;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -36.0pt; line-height: normal; mso-list: l14 level1 lfo3; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><a name="_heading=h.30j0zll"></a><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">Art. 1.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></strong><!--[endif]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="color: black;">Definiții</span></strong></p>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: justify; line-height: normal; tab-stops: 36.0pt;"><span lang="RO"><span style="mso-tab-count: 1;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>&Icirc;n prezentul contract următorii termeni vor fi interpretați astfel:</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l9 level2 lfo6; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">a.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">contract - prezentul contract și toate anexele sale;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l9 level2 lfo6; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">b.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">achizitor și furnizor - p&acirc;rțile contractante, aşa cum sunt acestea numite &icirc;n prezentul contract;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l9 level2 lfo6; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">c.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">preţul contractului - preţul plătibil furnizorului de către achizitor, &icirc;n baza contractului, pentru &icirc;ndeplinirea integrală şi corespunzătoare a tuturor obligaţiilor asumate prin contract;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l9 level2 lfo6; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">d.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">produse - echipamentele, maşinile, utilajele, orice alte bunuri, cuprinse &icirc;n anexa/anexele la prezentul contract, pe care furnizorul se obligă, prin contract, să le furnizeze achizitorului;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l9 level2 lfo6; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">e.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">servicii - serviciile aferente livrării produselor, respectiv activităţile legate de furnizarea produselor, cum ar fi transportul, asigurarea, instalarea, punerea &icirc;n funcţiune, asistenţa tehnică &icirc;n perioada de garanţie şi orice alte asemenea obligaţii care revin furnizorului prin contract;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l9 level2 lfo6; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">f.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">origine - locul unde produsele au fost realizate, fabricate. Produsele sunt fabricate atunci c&acirc;nd prin procesul de fabricare, prelucrare sau asamblare majoră şi esenţială a componentelor rezultă un produs nou, recunoscut comercial, care este diferit, prin caracteristicile sale de bază, prin scop sau prin utilitate, de componentele sale. Originea produselor şi serviciilor poate fi distinctă de naţionalitatea furnizorului;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l9 level2 lfo6; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">g.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">destinaţie finală - locul unde furnizorul are obligaţia de a furniza produsele;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l9 level2 lfo6; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">h.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">termenii comerciali de livrare vor fi interpretaţi conform INCOTERMS 2000 - Camera Internaţională de Comerţ (CIC);</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l9 level2 lfo6; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">i.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">forţa majoră - un eveniment mai presus de controlul părţilor, care nu se datorează greşelii sau vinei acestora, care nu putea fi prevăzut la momentul &icirc;ncheierii contractului şi care face imposibilă executarea şi, respectiv, &icirc;ndeplinirea contractului; sunt considerate asemenea evenimente: războaie, revoluţii, incendii, inundaţii sau orice alte catastrofe naturale, restricţii apărute ca urmare a unei carantine, embargou, enumerarea nefiind exhaustivă, ci enunţiativă. Nu este considerat forţă majoră un eveniment asemenea celor de mai sus care, fără a crea o imposibilitate de executare, face extrem de costisitoare executarea obligaţiilor uneia dintre părţi;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l9 level2 lfo6; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">j.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">zi - zi calendaristică; an - 365 de zile.</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;"><span lang="RO" style="color: black;">&nbsp;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -36.0pt; line-height: normal; mso-list: l14 level1 lfo3; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">Art. 2.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></strong><!--[endif]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="color: black;">Obiectul <em style="mso-bidi-font-style: normal;">Contractului</em></span></strong></p>
<p class="MsoNormal" style="text-align: justify; line-height: normal; background: white; margin: 0cm 0cm 0cm 36.0pt;"><span lang="RO" style="color: black; mso-color-alt: windowtext;">Obiectul prezentului <em style="mso-bidi-font-style: normal;">Contract</em> &icirc;l reprezintă achiziția publică de <em style="mso-bidi-font-style: normal;">Echipamente IT necesare dotarii salii multifunctionale inteligente, </em>denumite &icirc;n continuare <em style="mso-bidi-font-style: normal;">Produsele</em>, pe care <em style="mso-bidi-font-style: normal;">Furnizorul</em> se obligă să le furnizeze &icirc;n conformitate cu prevederile din prezentul <em style="mso-bidi-font-style: normal;">Contract</em>, cu dispozițiile legale, aprobările și standardele tehnice, profesionale și de calitate &icirc;n vigoare și conform cerințelor din<em style="mso-bidi-font-style: normal;"> Cererea de Ofertă </em>și<em style="mso-bidi-font-style: normal;"> Oferta acceptată</em>, la destinația finală, &lt; Denumirea instituției &gt;,&lt; Adresă instituției &gt;</span><span lang="RO" style="color: black; mso-color-alt: windowtext; background: white; mso-highlight: white;">, </span><span lang="RO" style="color: black; mso-color-alt: windowtext;">precum și a celorlalte anexe ale <em style="mso-bidi-font-style: normal;">Contractului</em>.</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: justify; line-height: normal; background: white;"><span lang="RO">&nbsp;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -36.0pt; line-height: normal; mso-list: l14 level1 lfo3; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><a name="_heading=h.1fob9te"></a><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">Art. 3.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></strong><!--[endif]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="color: black;">Prețul Contractului</span></strong></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -36.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><strong style="mso-bidi-font-weight: normal;"><span lang="RO"><span style="mso-tab-count: 1;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></strong><em style="mso-bidi-font-style: normal;"><span lang="RO">Achizitorul</span></em><span lang="RO"> se obligă să plătească <em style="mso-bidi-font-style: normal;">Furnizorului Prețul</em> total convenit prin prezentul <em style="mso-bidi-font-style: normal;">Contract</em> pentru furnizarea <em style="mso-bidi-font-style: normal;"><span style="background: white; mso-highlight: white;">Produselor<strong style="mso-bidi-font-weight: normal;"> </strong></span></em>care fac obiectul prezentului <em style="mso-bidi-font-style: normal;">Contract</em>, &icirc;n sumă de &lt; Valoarea evaluată totală, fără TVA cifre si litere &gt;<span style="background: white; mso-highlight: white;">, la care se adaugă TVA &icirc;n valoare </span>de &lt; Valoarea TVA cifre si litere &gt;<span style="background: white; mso-highlight: white;">, conform prevederilor<span style="mso-spacerun: yes;">&nbsp; </span>legale <em style="mso-bidi-font-style: normal;">19%</em>.</span> </span></p>
<p class="MsoNormal" style="text-align: justify; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><a name="_heading=h.3znysh7"></a><span lang="RO">Prețul total al Contractului, inclusiv TVA, este &icirc;n sumă de &lt; Valoarea totală, inclusiv TVA cifre si litere &gt;<span style="background: white; mso-highlight: white;">.</span></span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -36.0pt; line-height: normal; tab-stops: 36.0pt; margin: 0cm 0cm 0cm 36.0pt;"><strong style="mso-bidi-font-weight: normal;"><span lang="RO"><span style="mso-tab-count: 1;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></strong><em style="mso-bidi-font-style: normal;"><span lang="RO">Prețul Contractului</span></em><span lang="RO"> este ferm și nu se ajustează.</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: justify; line-height: normal; tab-stops: 42.55pt;"><span lang="RO">&nbsp;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -36.0pt; line-height: normal; mso-list: l14 level1 lfo3; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><a name="_heading=h.2et92p0"></a><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">Art. 4.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></strong><!--[endif]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="color: black;">Durata Contractului</span></strong></p>
<p class="MsoNormal" style="text-align: justify; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><span style="color: rgb(224, 62, 45);"><span lang="RO" style="background: white;">Durata prezentului <em style="mso-bidi-font-style: normal;">Contract</em> este de </span><span lang="RO" style="background: white; mso-highlight: white;">&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;.</span><span lang="RO"> ani <span style="mso-spacerun: yes;">&nbsp;</span>&icirc;ncep&acirc;nd de la data intrării &icirc;n vigoare a prezentului <em style="mso-bidi-font-style: normal;">Contract</em>, respectiv din data<span style="background: yellow; mso-highlight: yellow;"> </span><em style="mso-bidi-font-style: normal;"><span style="background: yellow; mso-highlight: yellow;">&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;. </span></em><span style="background: yellow;">p&acirc;nă pe data</span><span style="background: yellow; mso-highlight: yellow;"> &hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;</span></span></span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: justify; line-height: normal;"><strong style="mso-bidi-font-weight: normal;"><span lang="RO">&nbsp;</span></strong></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -36.0pt; line-height: normal; mso-list: l14 level1 lfo3; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><a name="_heading=h.tyjcwt"></a><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">Art. 5.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></strong><!--[endif]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="color: black;">Documentele Contractului</span></strong></p>
<p class="MsoNormal" style="text-align: justify; line-height: normal; tab-stops: 36.0pt; margin: 0cm 0cm 0cm 36.0pt;"><span lang="RO">Documentele prezentului <em style="mso-bidi-font-style: normal;">Contract </em>se completează și se explicitează reciproc, sunt parte integrantă din <em style="mso-bidi-font-style: normal;">Contract</em> și sunt, &icirc;n ordinea importanței lor, următoarele:</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l1 level1 lfo4; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="font-family: 'Arial',sans-serif; mso-fareast-font-family: Arial; color: black;"><span style="mso-list: Ignore;">-<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Anexa 1 - <em style="mso-bidi-font-style: normal;">Cererea de Ofertă </em></span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l1 level1 lfo4; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="font-family: 'Arial',sans-serif; mso-fareast-font-family: Arial; color: black;"><span style="mso-list: Ignore;">-<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Anexa 2<em style="mso-bidi-font-style: normal;"> - Oferta acceptată,</em></span></p>
<p class="MsoNormal" style="text-align: justify; line-height: normal; background: white; margin: 0cm 0cm 0cm 36.0pt;"><em style="mso-bidi-font-style: normal;"><span lang="RO" style="background: lightgrey; mso-highlight: lightgrey;">&nbsp;</span></em></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -36.0pt; line-height: normal; tab-stops: 36.0pt; margin: 0cm 0cm 0cm 36.0pt;"><strong style="mso-bidi-font-weight: normal;"><em style="mso-bidi-font-style: normal;"><span lang="RO" style="background: lightgrey; mso-highlight: lightgrey;">&nbsp;</span></em></strong></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -36.0pt; line-height: normal; mso-list: l14 level1 lfo3; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">Art. 6.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></strong><!--[endif]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="color: black;">Obligaţiile principale ale furnizorului</span></strong></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l0 level1 lfo15; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">a.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Furnizorul se obligă să furnizeze produsele la standardele şi performanţele prezentate &icirc;n oferta sa.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l0 level1 lfo15; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">b.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Furnizorul se obligă să furnizeze produsele in termenul agreat ( maxim 60 de zile) de la data semnării contractului, &icirc;n conformitate cu prevederile prezentului contract.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l0 level1 lfo15; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">c.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Furnizorul se obligă să despăgubească achizitorul &icirc;mpotriva oricăror:</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l0 level2 lfo15; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 67.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">i)<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">reclamaţii şi acţiuni &icirc;n justiţie, ce rezultă din &icirc;ncălcarea unor drepturi de proprietate intelectuală (brevete, nume, mărci &icirc;nregistrate etc.), legate de echipamentele, materialele, instalaţiile sau utilajele folosite pentru sau &icirc;n legătură cu produsele achiziţionate; şi</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l0 level2 lfo15; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 67.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">ii)<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">daune-interese, costuri, taxe şi cheltuieli de orice natură, aferente, cu excepţia situaţiei &icirc;n care o astfel de &icirc;ncălcare rezultă din respectarea specificațiilor tehnice &icirc;ntocmite de către achizitor.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l0 level1 lfo15; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">d.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Furnizorul se obligă să transmită factura fiscală pentru bunurile livrate &icirc;n conformitate cu prezentul contract.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l0 level1 lfo15; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">e.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Furnizorul are obligaţia de a asigura complet produsele furnizate prin contract &icirc;mpotriva pierderii sau deteriorării neprevăzute la fabricare, transport, depozitare şi livrare.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l0 level1 lfo15; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">f.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Furnizorul se obligă să aplice prevederile Instrucțiunii nr. 6/30.08.2022 emisă de MIPE, referitoare la colectarea și accesul la datele privind beneficiarii reali ai destinatarilor fondurilor din cadrul PNRR.</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;"><span lang="RO" style="color: black;">&nbsp;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -36.0pt; line-height: normal; mso-list: l14 level1 lfo3; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">Art. 7.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></strong><!--[endif]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="color: black;">Obligaţiile principale ale achizitorului</span></strong></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l7 level1 lfo11; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">a.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Achizitorul se obligă să recepţioneze produsele &icirc;n momentul furnizării acestora la destinația finală.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l7 level1 lfo11; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">b.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Achizitorul se obligă să plătească preţul produselor către furnizor &icirc;n termenul convenit, astfel: plata se va efectua in lei, după recepţia produselor la destinația finală, in termen maxim de<span style="background: white; mso-highlight: white;"> </span></span><em style="mso-bidi-font-style: normal;"><span lang="RO" style="background: white; mso-highlight: white;">30 </span></em><span lang="RO" style="color: black;">zile de la data primirii facturii de catre achizitor. Plata se efectuează cu ordin de plată &icirc;n contul de trezorerie al furnizorului pe baza facturii si a procesului verbal de recepție a produselor.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l7 level1 lfo11; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">c.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Achizitorul se obligă să aplice prevederile Instrucțiunii nr. 6/30.08.2022 emisă de MIPE, referitoare la colectarea și accesul la datele privind beneficiarii reali ai destinatarilor fondurilor din cadrul PNRR</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;"><span lang="RO" style="color: black;">&nbsp;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -36.0pt; line-height: normal; mso-list: l14 level1 lfo3; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">Art. 8.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></strong><!--[endif]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="color: black;">Sancţiuni pentru ne&icirc;ndeplinirea culpabilă a obligaţiilor</span></strong></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l8 level1 lfo14; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">a.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">&Icirc;n cazul &icirc;n care, din vina sa exclusivă, furnizorul nu reuşeşte să-şi &icirc;ndeplinească obligaţiile asumate, atunci achizitorul are dreptul de a scădea, ca penalităţi, o sumă echivalentă cu o cotă procentuală de </span><span lang="RO">0,1 <span style="color: black;">% pe zi din preţul contractului.</span></span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l8 level1 lfo14; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">b.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">&Icirc;n cazul &icirc;n care achizitorul nu &icirc;şi onorează obligaţiile &icirc;n termen de 28 de zile de la expirarea perioadei convenite, atunci acestuia &icirc;i revine obligaţia de a plăti, ca penalităţi, o sumă echivalentă cu o cotă procentuală de </span><em style="mso-bidi-font-style: normal;"><span lang="RO" style="background: white; mso-highlight: white;">0,1</span></em><span lang="RO" style="color: black; background: white; mso-highlight: white;">%</span><span lang="RO" style="color: black;"> pe zi din plata neefectuată.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l8 level1 lfo14; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">c.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Nerespectarea obligaţiilor asumate prin prezentul contract de către una dintre părţi, &icirc;n mod culpabil şi repetat, dă dreptul părţii lezate de a considera contractul reziliat de drept.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l8 level1 lfo14; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">d.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Achizitorul &icirc;şi rezervă dreptul de a renunţa la contract, printr-o notificare scrisă adresată furnizorului, fără nici o compensaţie, dacă acesta din urmă dă faliment, cu condiţia ca această anulare să nu prejudicieze sau să afecteze dreptul la acţiune sau despăgubire pentru furnizor. &Icirc;n acest caz, furnizorul are dreptul de a pretinde numai plata corespunzătoare pentru partea din contract &icirc;ndeplinită p&acirc;nă la data denunţării unilaterale a contractului.</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;"><span lang="RO" style="color: black;">&nbsp;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -36.0pt; line-height: normal; mso-list: l14 level1 lfo3; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">Art. 9.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></strong><!--[endif]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="color: black;">Recepţie, inspecţii şi teste</span></strong></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l12 level1 lfo12; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">a.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Achizitorul, prin reprezentantii săi, are dreptul de a inspecta şi/sau de a testa produsele pentru a verifica conformitatea lor cu specificaţiile din oferta acceptată, anexă la contract. </span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l12 level1 lfo12; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">b.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Inspecţiile şi/sau testările la care vor fi supuse produsele, c&acirc;t şi condiţiile de &icirc;ndeplinire a recepţiei calitative se vor efectua prin verificarea conformităţii cu specificaţiile din oferta acceptată.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l12 level1 lfo12; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">c.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Achizitorul are obligaţia de a notifica, &icirc;n scris, furnizorului identitatea reprezentanţilor săi &icirc;mputerniciţi pentru efectuarea recepţiei, testelor şi inspecţiilor.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l12 level1 lfo12; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">d.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Inspecţiile şi testele din cadrul recepţiei calitative se vor face la destinaţia finală a produselor precizată la Art. 2 al prezentului contract.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l12 level1 lfo12; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">e.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Dacă vreunul din produsele inspectate sau testate nu corespunde specificaţiilor, achizitorul are dreptul să &icirc;l respingă, iar furnizorul are obligaţia, fără a modifica preţul contractului, de a &icirc;nlocui produsele refuzate.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l12 level1 lfo12; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">f.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Dreptul achizitorului de a inspecta, testa şi, dacă este necesar, de a respinge nu va fi limitat sau am&acirc;nat datorită faptului că produsele au fost inspectate şi testate de furnizor, cu sau fără participarea unui reprezentant al achizitorului, anterior livrării acestora la destinaţia finală.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l12 level1 lfo12; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">g.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Prevederile prezentului articol nu &icirc;l vor absolvi pe furnizor de obligaţia asumării garanţiilor sau altor obligaţii prevăzute &icirc;n contract.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l12 level1 lfo12; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">h.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Furnizorul va transmite achizitorului documentele care &icirc;nsoţesc produsele după cum urmează: factura fiscală și certificatul de garanţie acordat de producător pentru fiecare produs.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l12 level1 lfo12; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">i.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Certificarea de către achizitor a faptului că produsele au fost livrate parţial sau total se face după recepţie, prin semnarea de procesului verbal de receptie de către reprezentantii săi autorizați.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l12 level1 lfo12; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">j.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Livrarea produselor se consideră &icirc;ncheiată &icirc;n momentul &icirc;n care sunt &icirc;ndeplinite prevederile clauzelor de recepţie a produselor.</span></p>
<p class="MsoNormal" style="text-align: justify; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><span lang="RO" style="color: black;">&nbsp;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -36.0pt; line-height: normal; mso-list: l14 level1 lfo3; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">Art. 10.<span style="font: 7.0pt 'Times New Roman';">&nbsp; </span></span></span></strong><!--[endif]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="color: black;">Ambalare şi marcare</span></strong></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l4 level1 lfo1; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">a.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Furnizorul are obligaţia de a ambala produsele pentru ca acestea să facă faţă, fără limitare, la manipularea dură din timpul transportului, tranzitului şi expunerii la temperaturi extreme, la soare şi la precipitaţiile care ar putea să apară &icirc;n timpul transportului şi depozitării &icirc;n aer liber, &icirc;n aşa fel &icirc;nc&acirc;t să ajungă &icirc;n bună stare la destinaţia finală.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l4 level1 lfo1; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">b.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">&Icirc;n cazul ambalării greutăţilor şi volumelor &icirc;n cutii, furnizorul va lua &icirc;n considerare, unde este cazul, distanţa mare p&acirc;nă la destinaţia finală a produselor şi absenţa facilităţilor de manipulare grea &icirc;n toate punctele de tranzit.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l4 level1 lfo1; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">c.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Ambalarea, marcarea şi documentaţia din interiorul sau din afara pachetelor vor respecta strict cerinţele ce vor fi special prevăzute &icirc;n contract, inclusiv cerinţele suplimentare.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l4 level1 lfo1; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">d.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Toate materialele de ambalare a produselor, precum şi toate materialele necesare protecţiei coletelor (paleţi de lemn, cutii, foi de protecţie etc.) răm&acirc;n &icirc;n proprietatea achizitorului.</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;"><span lang="RO" style="color: black;">&nbsp;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -36.0pt; line-height: normal; mso-list: l14 level1 lfo3; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">Art. 11.<span style="font: 7.0pt 'Times New Roman';">&nbsp; </span></span></span></strong><!--[endif]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="color: black;">Perioada de garanţie acordată produselor</span></strong></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l13 level1 lfo5; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">a.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Furnizorul are obligaţia de a garanta că produsele furnizate prin contract sunt noi, nefolosite, de ultimă generaţie şi &icirc;ncorporează toate &icirc;mbunătăţirile recente &icirc;n proiectare şi structura materialelor. De asemenea, furnizorul are obligaţia de a garanta că toate produsele furnizate prin contract nu vor avea niciun defect ca urmare a proiectului, materialelor sau manoperei (cu excepţia cazului c&acirc;nd proiectul şi/sau materialul sunt cerute &icirc;n mod expres de către achizitor) ori oricărei alte acţiuni sau omisiuni a furnizorului şi că acestea vor funcţiona &icirc;n condiţii normale de funcţionare.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l13 level1 lfo5; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">b.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Perioada de garanţie acordată produselor de către furnizor este cea declarată &icirc;n oferta acceptată, respectiv d<span style="background: white; mso-highlight: white;">e </span></span><em style="mso-bidi-font-style: normal;"><span lang="RO" style="background: white; mso-highlight: white;">36 l</span></em><span lang="RO" style="color: black; background: white; mso-highlight: white;">uni</span><span lang="RO" style="background: white; mso-highlight: white;">.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l13 level1 lfo5; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">c.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Perioada de garanţie a produselor &icirc;ncepe cu data recepţiei efectuate după livrarea acestora la destinaţia finală.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l13 level1 lfo5; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">d.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Achizitorul are dreptul de a notifica imediat furnizorului, &icirc;n scris, orice pl&acirc;ngere sau reclamaţie ce apare &icirc;n conformitate cu această garanţie.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l13 level1 lfo5; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">e.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">La primirea unei astfel de notificări, furnizorul are obligaţia de a remedia defecţiunea sau de a &icirc;nlocui produsul &icirc;n termen de<span style="background: white; mso-highlight: white;"> </span></span><em style="mso-bidi-font-style: normal;"><span lang="RO" style="background: white; mso-highlight: white;">30 de</span></em><span lang="RO" style="color: black; background: white; mso-highlight: white;"> </span><span lang="RO" style="color: black;"><span style="mso-spacerun: yes;">&nbsp;</span>zile lucrătoare de la data notificării, fără costuri suplimentare pentru achizitor. Produsele care, &icirc;n timpul perioadei de garanţie, le &icirc;nlocuiesc pe cele defecte beneficiază de o nouă perioadă de garanţie care curge de la data &icirc;nlocuirii produsului.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l13 level1 lfo5; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">f.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Dacă furnizorul, după ce a fost &icirc;nştiinţat, nu reuşeşte să remedieze defectul &icirc;n perioada de timp menţionată, achizitorul are dreptul de a lua măsuri de remediere pe riscul şi pe cheltuiala furnizorului şi fără a aduce niciun prejudiciu oricăror alte drepturi pe care achizitorul le poate avea faţă de furnizor prin contract.</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;"><span lang="RO" style="color: black;">&nbsp;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -36.0pt; line-height: normal; mso-list: l14 level1 lfo3; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">Art. 12.<span style="font: 7.0pt 'Times New Roman';">&nbsp; </span></span></span></strong><!--[endif]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="color: black;">Ajustarea preţului contractului</span></strong></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l2 level1 lfo7; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">a.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Pentru produsele livrate, plăţile datorate de achizitor furnizorului sunt cele declarate &icirc;n oferta acceptată, anexă la contract, și indicate in Art. 3 al contractului.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l2 level1 lfo7; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">b.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Preţul contractului nu se ajustează.</span></p>
<p class="MsoNormal" style="text-align: justify; line-height: normal; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><span lang="RO" style="color: black;">&nbsp;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -36.0pt; line-height: normal; mso-list: l14 level1 lfo3; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">Art. 13.<span style="font: 7.0pt 'Times New Roman';">&nbsp; </span></span></span></strong><!--[endif]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="color: black;">Amendamente</span></strong></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l5 level1 lfo2; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">a.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Părţile contractante au dreptul, pe durata &icirc;ndeplinirii contractului, de a conveni modificarea clauzelor contractului, prin act adiţional, numai &icirc;n cazul apariţiei unor circumstanţe care nu au putut fi prevăzute la data &icirc;ncheierii contractului.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -13.5pt; line-height: normal; mso-list: l5 level1 lfo2; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">b.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Orice act adițional va avea la baza documente justificative.</span></p>
<p class="MsoNormal" style="text-align: justify; line-height: normal; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 49.5pt;"><span lang="RO" style="color: black;">&nbsp;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -36.0pt; line-height: normal; mso-list: l14 level1 lfo3; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">Art. 14.<span style="font: 7.0pt 'Times New Roman';">&nbsp; </span></span></span></strong><!--[endif]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="color: black;">Forţa majoră</span></strong></p>
<p class="MsoNormal" style="text-align: justify; text-indent: 0cm; line-height: normal; mso-list: l6 level1 lfo13; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">a.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Forţa majoră este constatată de o autoritate competentă.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l6 level1 lfo13; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">b.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Forţa majoră exonerează părţile contractante de &icirc;ndeplinirea obligaţiilor asumate prin prezentul contract, pe toată perioada &icirc;n care aceasta acţionează.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l6 level1 lfo13; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">c.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">&Icirc;ndeplinirea contractului va fi suspendată &icirc;n perioada de acţiune a forţei majore, dar fără a prejudicia drepturile ce li se cuveneau părţilor p&acirc;nă la apariţia acesteia.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l6 level1 lfo13; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">d.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Partea contractantă care invocă forţa majoră are obligaţia de a notifica celeilalte părţi, imediat şi &icirc;n mod complet, producerea acesteia şi să ia orice măsuri care &icirc;i stau la dispoziţie &icirc;n vederea limitării consecinţelor.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l6 level1 lfo13; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">e.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Dacă forţa majoră acţionează sau se estimează că va acţiona o perioadă mai mare de 6 luni, fiecare parte va avea dreptul să notifice celeilalte părţi &icirc;ncetarea de plin drept a prezentului contract, fără ca vreuna dintre părţi să poată pretinde celeilalte daune-interese.</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;"><span lang="RO" style="color: black;">&nbsp;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -36.0pt; line-height: normal; mso-list: l14 level1 lfo3; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">Art. 15.<span style="font: 7.0pt 'Times New Roman';">&nbsp; </span></span></span></strong><!--[endif]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="color: black;">Soluţionarea litigiilor</span></strong></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l3 level1 lfo8; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">a.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Achizitorul şi furnizorul vor depune toate eforturile pentru a rezolva pe cale amiabilă, prin tratative directe, orice ne&icirc;nţelegere sau dispută care se poate ivi &icirc;ntre ei &icirc;n cadrul sau &icirc;n legătură cu &icirc;ndeplinirea contractului.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l3 level1 lfo8; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">b.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Dacă, după 15 de zile de la &icirc;nceperea acestor tratative, achizitorul şi furnizorul nu reuşesc să rezolve &icirc;n mod amiabil o divergenţă contractuală, fiecare poate solicita ca disputa să se soluţioneze prin adresarea la instanțele competente.</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;"><span lang="RO" style="color: black;">&nbsp;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -36.0pt; line-height: normal; mso-list: l14 level1 lfo3; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">Art. 16.<span style="font: 7.0pt 'Times New Roman';">&nbsp; </span></span></span></strong><!--[endif]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="color: black;">Limba care guvernează contractul; Legea aplicabilă contractului</span></strong></p>
<p class="MsoNormal" style="text-align: justify; text-indent: 0cm; line-height: normal; mso-list: l10 level1 lfo9; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">a.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Limba care guvernează contractul este limba rom&acirc;nă.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -18.0pt; line-height: normal; mso-list: l10 level1 lfo9; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 54.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">b.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Contractul va fi interpretat conform legilor din Rom&acirc;nia.</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;"><span lang="RO" style="color: black;">&nbsp;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -36.0pt; line-height: normal; mso-list: l14 level1 lfo3; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">Art. 17.<span style="font: 7.0pt 'Times New Roman';">&nbsp; </span></span></span></strong><!--[endif]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="color: black;">Comunicări</span></strong></p>
<p class="MsoNormal" style="text-align: justify; text-indent: 0cm; line-height: normal; mso-list: l11 level1 lfo10; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">a.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Orice comunicare &icirc;ntre părţi, referitoare la &icirc;ndeplinirea prezentului contract, trebuie să fie transmisă &icirc;n scris.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: 0cm; line-height: normal; mso-list: l11 level1 lfo10; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">b.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Orice document scris trebuie &icirc;nregistrat at&acirc;t &icirc;n momentul transmiterii, c&acirc;t şi &icirc;n momentul primirii.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: 0cm; line-height: normal; mso-list: l11 level1 lfo10; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">c.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Comunicările dintre părţi se pot face şi prin telefon, fax sau e-mail, cu condiţia confirmării &icirc;n scris a primirii comunicării.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: 0cm; line-height: normal; mso-list: l11 level1 lfo10; tab-stops: 54.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><!-- [if !supportLists]--><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">d.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="RO" style="color: black;">Comunicările referitoare la prezentul contract vor fi adresate la urmatoarele coordonate:</span></p>
<p class="MsoNormal" style="text-align: justify; margin: 0cm 0cm 0cm 54.0pt;"><span lang="RO">Pentru Achizitor: </span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: 36.0pt; margin: 0cm 0cm 0cm 54.0pt;"><span lang="RO">mobil:<span style="mso-spacerun: yes;"> &nbsp;&lt; Telefon instituție &gt;</span></span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: 36.0pt; margin: 0cm 0cm 0cm 54.0pt;"><span lang="RO">fax: &lt; Fax instituție &gt;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: 36.0pt; margin: 0cm 0cm 6.0pt 54.0pt;"><span lang="RO">e-mail:<span style="mso-spacerun: yes;"> &nbsp;&lt; E-mail instituției &gt;</span></span></p>
<p class="MsoNormal" style="text-align: justify; margin: 0cm 0cm 0cm 54.0pt;"><span lang="RO">Pentru Furnizor: </span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: 36.0pt; margin: 0cm 0cm 0cm 54.0pt;"><span lang="RO">telefon: &lt; Telefon ofertant &gt;<span style="color: black;">,</span> </span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: 36.0pt; margin: 0cm 0cm 0cm 54.0pt;"><span lang="RO">fax: <em style="mso-bidi-font-style: normal;"><span style="color: black; mso-color-alt: windowtext; background: #D9D9D9;"><span style="mso-spacerun: yes;">&nbsp;</span>NU</span></em></span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: 36.0pt; margin: 0cm 0cm 6.0pt 54.0pt;"><span lang="RO">e-mail: &lt; E-mail ofertant &gt;</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -36.0pt; line-height: normal; mso-list: l14 level1 lfo3; tab-stops: 36.0pt; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes; margin: 0cm 0cm 0cm 36.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="mso-fareast-font-family: Calibri; color: black;"><span style="mso-list: Ignore;">Art. 18.<span style="font: 7.0pt 'Times New Roman';">&nbsp; </span></span></span></strong><!--[endif]--><em style="mso-bidi-font-style: normal;"><span lang="RO" style="color: black;">Contractantul</span></em><span lang="RO" style="color: black;"> declară expres că a citit cuprinsul clauzelor contractuale și declară, &icirc;n mod expres, că a &icirc;nțeles și că acceptă pe deplin conținutul acestora precum și efectele lor juridice.</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: justify; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;"><span lang="RO" style="color: black;">&nbsp;</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: justify; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;"><span lang="RO" style="color: black;">Prezentul <em style="mso-bidi-font-style: normal;">Contract</em> reprezintă voința liberă a <em style="mso-bidi-font-style: normal;">Părților</em> și se semnează de către acestea astfel cum au fost agreate clauzele <em style="mso-bidi-font-style: normal;">Contractului</em> și &icirc;ntinderea obligațiilor asumate, orice alte &icirc;nțelegeri anterioare, scrise sau verbale, fiind lipsite de valoare juridică.</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: justify; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;"><span lang="RO" style="color: black;">&nbsp;</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: justify; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;"><span lang="RO" style="color: black;">Drept pentru care, <em style="mso-bidi-font-style: normal;">Părțile</em> au &icirc;ncheiat prezentul <strong style="mso-bidi-font-weight: normal;"><em style="mso-bidi-font-style: normal;">Contract</em></strong> azi,<span style="background: yellow; mso-highlight: yellow;"> </span></span><em style="mso-bidi-font-style: normal;"><span lang="RO" style="background: yellow; mso-highlight: yellow;">&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;..</span></em><strong style="mso-bidi-font-weight: normal;"><span lang="RO" style="color: black; background: white; mso-highlight: white;">,</span></strong><span lang="RO" style="color: black;"> &icirc;<span style="background: white; mso-highlight: white;">n </span></span><span lang="RO" style="background: white; mso-highlight: white;">2<span style="color: black;">(</span><em style="mso-bidi-font-style: normal;">doua)</em><span style="color: black;"> ex</span></span><span lang="RO" style="color: black;">emplare.</span></p>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: justify; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;">&nbsp;</p>
<div dir="ltr" align="left">
<table style="border-collapse: collapse; width: 100%; border: 1px solid rgb(0, 0, 0); height: 58.7814px;" border="1"><colgroup><col style="width: 50%;"><col style="width: 50%;"></colgroup>
<tbody>
<tr style="height: 19.5938px;">
<td style="border-width: 1px; border-color: rgb(0, 0, 0); height: 58.7814px;">
<p><strong style="mso-bidi-font-weight: normal;"><span lang="RO">Pentru <em style="mso-bidi-font-style: normal;">Achizitor</em>,</span></strong></p>
<strong id="docs-internal-guid-1a827415-7fff-652a-dc1a-ba3f07aca816"></strong></td>
<td style="border-width: 1px; border-color: rgb(0, 0, 0); height: 19.5938px;"><strong style="mso-bidi-font-weight: normal;"><span lang="RO">Pentru <em style="mso-bidi-font-style: normal;">Furnizor</em>,</span></strong></td>
</tr>
<tr>
<td style="border-width: 1px; border-color: rgb(0, 0, 0);">
<p><span lang="RO" style="color: black; mso-color-alt: windowtext;">&lt; Denumirea instituției &gt;</span></p>
</td>
<td style="border-width: 1px; border-color: rgb(0, 0, 0);"><em style="mso-bidi-font-style: normal;"><span lang="RO" style="background: white; mso-highlight: white;">&lt; Denumire ofertant &gt;</span></em></td>
</tr>
<tr>
<td style="border-width: 1px; border-color: rgb(0, 0, 0);">
<p><em style="mso-bidi-font-style: normal;"><span lang="RO" style="background: white; mso-highlight: white;">&lt; Reprezentant instituției &gt;</span></em></p>
</td>
<td style="border-width: 1px; border-color: rgb(0, 0, 0);"><em style="mso-bidi-font-style: normal;"><span lang="RO" style="background: white; mso-highlight: white;">&lt; Reprezentant ofertant &gt;</span></em></td>
</tr>
<tr>
<td style="border-width: 1px; border-color: rgb(0, 0, 0);">
<p><em style="mso-bidi-font-style: normal;"><span lang="RO" style="background: white; mso-highlight: white;">&lt; Funcție reprezentant instituție &gt;</span></em></p>
</td>
<td style="border-width: 1px; border-color: rgb(0, 0, 0);"><em style="mso-bidi-font-style: normal;"><span lang="RO" style="background: white; mso-highlight: white;">&lt; Funcție reprezentant ofertant &gt;</span></em></td>
</tr>
<tr>
<td style="border-width: 1px; border-color: rgb(0, 0, 0);">
<p>&nbsp;</p>
</td>
<td style="border-width: 1px; border-color: rgb(0, 0, 0);">&nbsp;</td>
</tr>
<tr>
<td style="border-width: 1px; border-color: rgb(0, 0, 0);">
<p>&nbsp;</p>
</td>
<td style="border-width: 1px; border-color: rgb(0, 0, 0);">&lt; Semnătura și ștampila ofertant &gt;</td>
</tr>
</tbody>
</table>
</div>
<p class="MsoNormal" style="margin-bottom: 0cm; text-align: justify; line-height: normal; border: none; mso-padding-alt: 31.0pt 31.0pt 31.0pt 31.0pt; mso-border-shadow: yes;">&nbsp;</p>
<p class="MsoNormal" style="margin-bottom: 0cm; line-height: normal;">&nbsp;</p>
</div>`,
    'appendix_20': `<div style="font-family: Calibri;">
<div>
<p style="text-align: right;">Anexa 20 &ndash; Model Proces Verbal de Recepție&nbsp;</p>
<p dir="ltr">Schema de Granturi: Programul Național pentru Reducerea Abandonului Școlar</p>
<p dir="ltr">Beneficiar: &nbsp; &lt; Denumirea instituției &gt;</p>
<p dir="ltr">Titlul proiectului: &lt; Titlul proiectului &gt;</p>
<p dir="ltr">Contract de finanțare Nr. &lt; Numărul contractului de finanțare &gt;, din data de &lt; Data contractului de finanțare &gt;</p>
<p><strong><br><br></strong></p>
<p dir="ltr" style="text-align: center;"><strong>PROCES - VERBAL DE RECEPȚIE</strong></p>
<p dir="ltr" style="text-align: center;"><span style="color: rgb(224, 62, 45);"><strong>nr....&nbsp; &nbsp; din data........</strong></span></p>
<p dir="ltr" style="text-align: center;">Achiziția de &lt; Denumirea achiziției &gt;</p>
<p><strong><br><br></strong></p>
<p dir="ltr">Echipamente IT (produsele) necesare dotării salii multifunctionale inteligente&nbsp; s-au livrat &icirc;n perioada<span style="color: rgb(224, 62, 45);"> .......................................... </span>&icirc;n conformitate cu contractul de furnizare <span style="color: rgb(224, 62, 45);">nr. ....... din .......................</span>&nbsp; , &icirc;ncheiat &icirc;ntre &lt; Denumirea instituției &gt;,&nbsp; și &lt; Denumire ofertant &gt;</p>
<p dir="ltr">Comisia de recepție a procedat astăzi, ....................... la recepția bunurilor care au făcut obiectul contractului mai sus menționat, după cum urmează:</p>
&lt; Tabel receptie &gt;
<p dir="ltr">La receptia bunurilor a participat dl.&lt; Reprezentant ofertant &gt; ca reprezentant legal al &lt; Denumire ofertant &gt;</p>
<p dir="ltr">Comisia de recepție constată că bunurile menționate mai sus au fost furnizate &icirc;n conformitate cu specificațiile tehnice, cantitățile și &icirc;n perioada stipulate &icirc;n contract.</p>
<p dir="ltr">Punerea in functiune a produselor s-a realizat in data de &nbsp;.......................</p>
<p dir="ltr">Comisia de receptie constată că furnizorul și-a respectat obligațiile stabilite prin contract.</p>
<p dir="ltr">Valoarea bunurilor corespunde cu prețul stabilit &icirc;n contractul de furnizare &nbsp;semnat &icirc;ntre părți.</p>
<p dir="ltr">Procesul verbal conține 2 pagini și a fost semnat astăzi, ......................., la sediul achizitorului, &icirc;n doua exemplare, c&acirc;te unul pentru fiecare parte.</p>
<p><strong>&nbsp;</strong></p>
<p dir="ltr">Unitatea de &icirc;nvățăm&acirc;nt: &lt; Denumirea instituției &gt;</p>
<p><strong>&nbsp;</strong></p>
<p dir="ltr">Comisia de recepție:</p>
<p><strong>&nbsp;</strong></p>
<div dir="ltr" align="left">
<table style="border-collapse: collapse; width: 100%; border: 1px solid rgb(0, 0, 0); height: 58.7814px;" border="1"><colgroup><col style="width: 50%;"><col style="width: 50%;"></colgroup>
<tbody>
<tr style="height: 19.5938px;">
<td style="border-width: 1px; border-color: rgb(0, 0, 0); height: 58.7814px;">
<p>1. Nume și prenume, semnătura</p>
<strong id="docs-internal-guid-1a827415-7fff-652a-dc1a-ba3f07aca816"></strong></td>
<td style="border-width: 1px; border-color: rgb(0, 0, 0); height: 19.5938px;">&nbsp;</td>
</tr>
<tr>
<td style="border-width: 1px; border-color: rgb(0, 0, 0);">
<p>2. Nume și prenume, semnătura</p>
</td>
<td style="border-width: 1px; border-color: rgb(0, 0, 0);">&nbsp;</td>
</tr>
<tr>
<td style="border-width: 1px; border-color: rgb(0, 0, 0);">
<p>3. Nume și prenume, semnătura</p>
</td>
<td style="border-width: 1px; border-color: rgb(0, 0, 0);">&nbsp;</td>
</tr>
</tbody>
</table>
</div>
<p>&nbsp;</p>
<p dir="ltr">Furnizorul: &lt; Denumire ofertant &gt;</p>
<div dir="ltr" align="left">
<table style="border-collapse: collapse; width: 100%; border: 1px solid rgb(0, 0, 0); height: 58.7814px;" border="1"><colgroup><col style="width: 50%;"><col style="width: 50%;"></colgroup>
<tbody>
<tr style="height: 19.5938px;">
<td style="border-width: 1px; border-color: rgb(0, 0, 0); height: 58.7814px;">
<p>Nume și prenume, semnătura&nbsp;</p>
<p>&nbsp;&lt; Reprezentant ofertant &gt;</p>
<strong id="docs-internal-guid-1a827415-7fff-652a-dc1a-ba3f07aca816"></strong></td>
<td style="border-width: 1px; border-color: rgb(0, 0, 0); height: 19.5938px;">&lt; Semnătura ofertant &gt;</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
</div>
<p dir="ltr">&nbsp;</p>
<p><strong id="docs-internal-guid-92d137bd-7fff-c558-640b-abef77f20f3a"></strong></p>
</div>
</div>`,
    'series': `<div style="font-family: Calibri;">
<div dir="ltr" align="left">
<table style="border-collapse: collapse; width: 100%; border: 1px solid rgb(0, 0, 0); height: 58.7814px;" border="1"><colgroup><col style="width: 50%;"><col style="width: 50%;"></colgroup>
<tbody>
<tr style="height: 19.5938px;">
<td style="border-width: 1px; border-color: rgb(0, 0, 0); height: 58.7814px;">
<p><strong>Furnizor</strong>: &lt; Denumire ofertant &gt;<br><strong>R.C.</strong>: &lt; Nr. &icirc;nreg. Registrul Comerțului ofertant &gt;<br><strong>C.I.F.</strong>: &lt; CUI-ul ofertant &gt;<br><strong>Sediul social</strong>: &lt; Adresă ofertant &gt;</p>
</td>
<td style="border-width: 1px; border-color: rgb(0, 0, 0); height: 19.5938px;">
<p><strong>Cumparator</strong>: &lt; Denumirea instituției &gt;<strong>Telefon</strong>: &lt; Telefon instituție &gt;<strong>Persoana de contact</strong>: &lt; Reprezentant instituției &gt;<strong>Data livrarii</strong>:&nbsp;</p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
&lt; Tabel serii &gt;
<p>&nbsp;</p>
<p><br>&lt; Denumire ofertant &gt;</p>
<p>Noi, &lt; Denumire ofertant &gt; declaram pe propria raspundere ca echipamentele din prezentul document respecta cerintele esentiale desecuritate prevazute de HG 457/2003 si HG88/2003 privind echipamentele radio si echipamentele terminale detelecomunicatii si recunoasterea mutuala aacestora, precum si cerintele de protectie prevazute de HG 497/2003</p>
<p><strong>INFO DEEE</strong></p>
<p>Conform HG 448/2005 privind deseurile de echipamente electrice si electronice (DEEE), consumatorii au obligatia sa nu elimine DEEE ca deseuri municipale nesortate si sa colecteze selectiv aceste DEEE.Simbolul care indica faptul ca echipamentele electrice si electronice fac obiectul unei colectariseparate reprezinta o pubela cu roti barata cu doua linii in forma de X, ca in imaginea alaturata:</p>
<p>&nbsp;</p>
<p><strong>CERTIFICAT DE GARANTIE</strong></p>
<p>Prezentul document atesta ca produsul achizitionat beneficiaza de garantie si service in conditiile respectarii instructiunilor de instalare,utilizare,intretinere, manipulare si transport prevazute in manualul deutilizare, documentatia produsului si prezentul certificat de garantie. Garantia se acorda inconformitate cu legile romane in vigoare la data cumpararii.Pentru produsele la care producatorii / importatorii asigura garantia prin proceduri specifice, anexa va avea mentionat acest lucru si &lt; Denumire ofertant &gt; va furniza si certificatul de garantie emis de producator sau importator. Conditiile de garantie inscrise in documentele emise deproducator / importator prevaleaza asupra conditiilor inscrise in prezentul certificat de garantie, inclusiv modalitatea de acordare a garantiei.</p>
<p><strong>CONDITII DE GARANTIE</strong></p>
<p>Art. 1. OBLIGATIILE FURNIZORULUI</p>
<p>1. Termenul de garantie al produsului este cel specificat in prezentul certificat si se calculeaza de la data emiterii certificatului degarantie. Perioada degarantie se prelungeste cu perioada in care produsul s-a aflat in service, cu exceptia cazului in care solicitareade acordare a garantiei estenejustificata.</p>
<p>2. In lipsa conformitatii, cumparatorul are dreptul sa i se aduca produsele aflate in garantie la conformitate, fara plata, prinreparare sau inlocuire, inconformitate cu conditiile din certificatulde garantie.</p>
<p>3. Orice reparare sau inlocuire a produselor va fi facuta intr-o perioada rezonabila de timp, stabilita de comun acord intrevanzator si cumparator.</p>
<p>4. Interventiile pentru garantie se fac la sediul furnizorului, sau lacentrele de service autorizate de producator.</p>
<p>Art. 2 . OBLIGATIILE CUMPARATORULUI</p>
<p>1. La solicitarea acordarii garantiei, cumparatorul va trebui sa prezinte certificatul de garantie, produsul in ambalajul original sau un ambalaj care sa ofereprotectie echivalenta, toate accesoriile cu care a fost livrat si o descriere cat mai detaliata a defectului. Lipsa unuia dintre detaliile mentionate atrageimposibilitatea noastra de a asigura garantia.</p>
<p>2. Cumparatorul este obligat sa respecte instructiunile de instalare, utilizare, intretinere, manipulare si transport ale produselorcumparate, conformindicatiilor tehnice date de furnizor sau celor din manualele de utilizare.</p>
<p>3. Este obligatorie folosirea elementelor de cablare si conectare originale. Alimentarea se va face doar de la priza cu impamantare.</p>
<p>4. Cumparatorul este obligat sa pastreze intacte toate sigiliile si etichetele aplicate pe echipamente, precum si documentatia siaccesoriile primite odatacu produsul.</p>
<p>5. Echipamentul trebuie sa functioneze in urmatoarele conditii:</p>
<p>a. Tensiunea de alimentare: 210V - 240V; frecventa: 50 Hz +/- 2%</p>
<p>b. Temperatura: 10-35C; umiditate: 65% fara condensare;</p>
<p>c. Echipamentele vor fi protejate de umezeala, lichide, praf, fum,vibratii, socuri mecanice, termice sau electrice</p>
<p>Art. 3. SITUATII CARE POT DUCE LA PIERDEREA GARANTIEI</p>
<p>1. Ruperea sau deteriorarea sigiliilor sau a etichetelor aplicate peproduse;</p>
<p>2. Instalarea necorespunzatoare: conectarea la priza fara impamantare, conectarea perifericelor in timp ce echipamentelesunt sub tensiune,nerespectarea instructiunilor de instalare sau acerintelor de sistem date de producator.</p>
<p>3. Folosirea echipamentelor in conditii improprii: fluctuatii de tensiune, praf, umezeala, temperatura necorespunzatoare sau alte conditiicarecontravin specificatiilor producatorului.</p>
<p>4. Interventii asupra echipamentelor, indiferent de natura lor,efectuate de catre personal neautorizat.</p>
<p>5. Modificarea sau completarea datelor din certificatul de garantie de catre personal neautorizat. Orice modificare facuta de furnizortrebuie sa fieinsotita de stampila.</p>
<p>6. Nu se acorda garantie la produsele cu urme de arsuri, sau care aucomponente arse, gaurite sau crapate.</p>
<p>7. Nu se acorda garantie pentru produsele cu urme de coroziune,lichid sau alte corpuri straine patrunse in interior.</p>
<p>8. Manipulare necorespunzatoare: produse cu urme de lovituri, pieserupte, sparte sau indoite, pini rupti sau indoiti, conectori deteriorati.</p>
<p>9. Nu se acorda garantie la produsele folosite in afara specificatiilor date de producator: cu frecventa sau tensiunea de alimentare modificata invederea cresterii performantelor, cu sisteme de racireschimbate, modificate sau insuficiente, sau cu BIOS sau firmwaremodificat sau neoriginal.</p>
<p>10. Produse folosite cu alte accesorii sau consumabile decat cele originale: imprimante cu cartuse neoriginale sau reincarcate,folosirea altoralimentatoare decat cele originale.</p>
<p>11. Nu se acorda garantie pentru situatiile de incompatibilitate cu produsele care nu sunt trecute in documentatia sau listele decompatibilitate date deproducator.</p>
<p>12. Garantia monitoarelor TFT se acorda in conformitate cu standardul ISO 13406-2 clasa II. Astfel, monitoarele cu mai putini pixeli sausubpixelidefecti decat e specificat in standard nu se consideradefecte.</p>
<p>13. Nu se acorda garantie pentru consumabile (cartuse cu banda tusata, cartuse toner, cartuse cu cerneala, cilindru fotoconductor,acumulatoare,baterii) decat in cazurile specificate in prealabil.</p>
<p>14. Nu se acorda garantie pentru produse software, sau pentru defectiuni cauzate de produse software (virusi, softwareoverclocking).</p>
<p>15. Nu se acorda garantie pentru defectiuni cauzate de transportatori. Receptia in vederea acordarii garantiei se face la momentul in care produsul aajuns la sediul furnizorului sau in service-ul autorizat deproducator.</p>
<p>Art. 4. ALTE MENTIUNI</p>
<p>1. In cazul solicitarii nejustificate de acordare a garantiei ne rezervam dreptul de a cere beneficiarului contravaloarea manoperei deinvestigare aprodusului reclamat ca fiind defect, adica 10 USD / ora.</p>
<p>2. Garantia se acorda doar clientilor nostri, noi nefiind in nici un caz raspunzatori in fata tertilor care achizitioneaza produsecomercializate de noi prinacestia.</p>
<p>3. Garantia nu acopera eventualele pierderi de date cauzate dedefectiuni ale echipamentelor aflate in garantie.</p>
<p>4. Prin semnarea prezentului contract, cumparatorul isi asuma obligatia de a lua la cunostinta conditiile de garantie inscrise incertificatul degarantie.</p>
<p>Prin semnarea de catre Cumparator a prezentei Garantii acesta certifica faptul ca produsul achizitionat este intreg, in buna stare de functionare si buneconditii de calitate, ca a primit instructiuni pentru instalarea, folosirea si intretinerea produsului si ca accepta termenii si conditiile prezentei Garantii.</p>
<p>Am primit echipamentele in perfecta stare (semnatura CUMPARATOR)</p>
<p>Semnatura si stampila:</p>
</div>
</div>`,
    'declaration_1': '',
    'declaration_2': '',
    'declaration_3': '',
}
export const templatesVariables = {
    'appendix_13': ['Denumirea instituției','Titlul proiectului', 'Numărul contractului de finanțare','Data contractului de finanțare','Denumirea achiziției',
        'Valoarea totală estimată', 'Tabel cu necesități', 'Tabel cu furnizori', 'Tabel specificații tehnice', 'Reprezentant instituției', 'Funcție reprezentant instituție',
        'Motivație', 'Cod proiect', 'Tabel cu necesități fără preț'
    ],
    'estimated': ['Denumirea instituției','Titlul proiectului', 'Numărul contractului de finanțare','Data contractului de finanțare','Denumirea achiziției',
        'Valoarea totală estimată','Valoarea TVA estimată','Valoarea totală estimată, inclusiv TVA', 'Tabel cu necesități'
    ],
    'appendix_26': ['Denumirea instituției','Titlul proiectului', 'Numărul contractului de finanțare','Data contractului de finanțare','Denumirea achiziției',
        'Valoarea totală estimată', 'Tabel cu necesități', 'Reprezentant instituției', 'Funcție reprezentant instituție','Cod proiect', 'Tabel cu necesități fără preț'
    ],
    'appendix_21': ['Denumirea instituției', 'Adresă instituției','Titlul proiectului', 'Numărul contractului de finanțare','Data contractului de finanțare','Denumirea achiziției',
        'Denumire ofertant', 'Adresă ofertant', 'Telefon si e-mail ofertant', 'Reprezentant ofertant',
        'Valoarea totală estimată', 'Tabel cu necesități', 'Tabel cu necesități fără preț', 'Tabel specificații tehnice','Termen de livrare','Termen depunere oferte',
        'Telefon instituție', 'E-mail instituției', 'Reprezentant instituției', 'Funcție reprezentant instituție', 'Numărul de zile pentru care ofertantul trebuie să mențină valabilitatea ofertei',
        'Numărul de zile înainte de data limită de depunere a clarificărilor'
    ],
    'offer': [ 'Denumire ofertant', 'Adresă ofertant', 'Telefon si e-mail ofertant', 'Reprezentant ofertant','Denumirea achiziției',
        'Tabel cu produsele ofertate',  'Termen de livrare', "Tabel cu termenul de livrare", "Tabel cu specificațiile tehnice ofertate", 'Valabilitate ofertă',
        'Număr ofertă','Dată ofertă','Număr cerere ofertă','Dată cerere ofertă', 'Semnătura și ștampila ofertant'
    ],
    'appendix_14': ['Denumirea instituției','Titlul proiectului', 'Numărul contractului de finanțare','Data contractului de finanțare','Denumirea achiziției',
        'Număr cerere ofertă', 'Listă oferte primite', 'Listă oferte primite după expirarea termenului',
        'Tabel ofertanti calificați','Tabele cu evaluarea ofertelor tehnic','Tabele cu evaluarea ofertelor financiar','Tabel clasificarea ofertelor',
        'Denumire ofertant câștigător','Valoarea evaluată totală, fără TVA','Valoarea TVA','Valoarea totală, inclusiv TVA','Termen depunere oferte'
    ],
    'appendix_15': ['Denumirea instituției','Titlul proiectului', 'Numărul contractului de finanțare','Data contractului de finanțare','Denumirea achiziției',
        'Denumire ofertant', 'Adresă ofertant', 'Telefon si e-mail ofertant', 'Reprezentant ofertant', 'Numărul contractului de finanțare','Data contractului de finanțare',
        'Valoarea evaluată totală, fără TVA','Valoarea TVA','Valoarea totală, inclusiv TVA', 'Locul in clasament'
    ],
    'contract': ['Denumirea instituției','Fax instituție','Telefon instituție', 'E-mail instituției', 'Reprezentant instituției','CUI-ul instituției', 'Contul de trezorerie al instituției',
        'Trezoreria instituției', 'Funcție reprezentant instituție', 'Adresă instituției', 'Denumirea achiziției','Denumire ofertant', 'Adresă ofertant',
        'Telefon ofertant','E-mail ofertant', 'Reprezentant ofertant', 'Funcție reprezentant ofertant','CUI-ul ofertant','Nr. înreg. Registrul Comerțului ofertant','Contul de trezorerie al ofertant',
        'Trezoreria ofertant', 'Valoarea evaluată totală, fără TVA cifre si litere','Valoarea TVA cifre si litere','Valoarea totală, inclusiv TVA cifre si litere',
        'Semnătura și ștampila ofertant' ,'Tabel receptie'
    ],
    'appendix_20': ['Titlul proiectului','Denumirea instituției', 'Numărul contractului de finanțare','Data contractului de finanțare',
        'Contul de trezorerie al instituției','Denumirea achiziției','Denumire ofertant',  'Reprezentant ofertant', 'Tabel receptie', 'Semnătura ofertant'
    ],
    'series': ['Denumirea instituției','Telefon instituție', 'Reprezentant instituției', 'Denumire ofertant','Reprezentant ofertant','CUI-ul ofertant',
        'Nr. înreg. Registrul Comerțului ofertant','Adresă ofertant', 'Tabel serii'],
    'declaration_1': ['Denumire companie', 'Reprezentant companie', 'Adresă companie',  'Denumirea instituției', 'Cod proiect',
        'Titlu proiect', 'Data', 'Denumire produs', 'Tabel garanție', 'Semnătura companiei', 'Semnătura și ștampila companiei'],
    'declaration_2': ['Denumire companie', 'Reprezentant companie', 'Adresă companie',  'Denumirea instituției', 'Cod proiect',
        'Titlu proiect', 'Data', 'Denumire produs', 'Tabel garanție', 'Semnătura companiei', 'Semnătura și ștampila companiei'],
    'declaration_3': ['Denumire companie', 'Reprezentant companie', 'Adresă companie',  'Denumirea instituției', 'Cod proiect',
        'Titlu proiect', 'Data', 'Denumire produs', 'Tabel garanție', 'Semnătura companiei', 'Semnătura și ștampila companiei'],
}
