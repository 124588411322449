import React, { useState } from 'react';
import {NavLink, useNavigate} from 'react-router-dom';

const SubMenu = ({ item,sidebarMini,setMenuIsOpen,setSidebarMini}) => {
    const [subnav, setSubnav] = useState(false);

    const navigate = useNavigate()
    return (
        <div key={item.title}>
            <NavLink className={item.items && 'inactive'} end={item.end} to={item.path !== -1 ? item.path : 'sdfssfd'}
                    onClick={(event) => {
                        event.preventDefault()
                        if(!item.items || (item.items && item.items.length === 0)){
                            setMenuIsOpen(false)
                            navigate(item.path)
                        }else {
                            setSubnav(!subnav)
                        }
            }}>{/*item.subNav && showSubnav*/}
                <div className={"item"}>
                    <i className={item.icon}></i>
                    {!sidebarMini &&
                        item.title}
                    {!sidebarMini && item.items &&
                        <i className={"ms-auto " + (item.items && subnav ? 'fa-solid fa-caret-up' : item.items ? 'fa-solid fa-caret-down' : null)}></i>
                    }
                </div>

            </NavLink>
            {subnav && item.items &&
                item.items.map((item, index) => (

                    <NavLink className={"subitem-link"} end={item.end} to={item.path} key={index} onClick={(event) => {
                        event.preventDefault()
                        setMenuIsOpen(false)
                        navigate(item.path)
                    }}>
                        <div className={"subitem"}>
                        <i className=  {item.icon}></i>
                        {!sidebarMini && item.title}
                        </div>
                    </NavLink>
                ))}
        </div>
    );
};

export default SubMenu;
