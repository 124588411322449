import './Paginate.scss';
import ReactPaginate from "react-paginate";

export default function Paginate(
    {
        page,
        setPage,
        totalItems
    }
) {
    return (
        Math.ceil(totalItems/10) > 1 &&
        <ReactPaginate

            pageCount={Math.ceil(totalItems/10)}
            pageRangeDisplayed={4}
            initialPage={page - 1}
            marginPagesDisplayed={2}
            onPageChange={(val) => setPage(val.selected + 1)}
            breakLabel="..."
            containerClassName={"react-paginate"}
            previousLabel={<i className={"fa-solid fa-angle-left"}/>}
            nextLabel={<i className={"fa-solid fa-angle-right"}/>}
        >
        </ReactPaginate>
    );
}

