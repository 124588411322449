import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import Selector from "../../../components/elements/Selector/Selector";
import CustomTextarea from "../../../components/elements/CustomTextarea/CustomTextarea";

export default function EditProduct() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')
    let params = useParams();

    const [categories, setCategories] = useState([])
    const [title, setTitle] = useState('')
    const [projectType, setProjectType] = useState('pnras')
    const [partNumber, setPartNumber] = useState('')
    const [category, setCategory] = useState('')
    const [price, setPrice] = useState(0)
    const [stock, setStock] = useState(0)
    const [guarantee, setGuarantee] = useState(36)
    const [activePage, setActivePage] = useState(0)
    const [activeProduct, setActiveProduct] = useState({})
    const [activeIndex, setActiveIndex] = useState(0)
    const [descriptionRequest, setDescriptionRequest] = useState('')
    const [secondDescriptionRequest, setSecondDescriptionRequest] = useState('')
    const [priceRequest, setPriceRequest] = useState(0)
    const [guaranteeRequest, setGuaranteeRequest] = useState(36)
    const [type, setType] = useState('products')
    const [productToCompany, setProductToCompany] = useState([])

    const [loading, setLoading] = useState(true)

    const [saveLoading, setSaveLoading] = useState(false)
    const [duplicateLoading, setDuplicateLoading] = useState(false)
    useEffect(() => {
        getCategories()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCategories = async () => {
        axios.post(process.env.REACT_APP_API + '/product-categories/list/0', {}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = [];
                response.data.categories.forEach((categ) => {
                    temp.push({
                        value: categ.id,
                        label: categ.title
                    })
                })
                setCategories(temp)
                getCompanies()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getData = async (p2) => {

        axios.get(process.env.REACT_APP_API + '/products/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setTitle(response.data.title)
                    setCategory(response.data.product_category)
                    setPrice(response.data.price)
                    setProjectType(response.data.project_type)
                    setStock(response.data.stock ?? 0)
                    setPartNumber(response.data.part_number)
                    setGuarantee(response.data.guarantee)
                    setDescriptionRequest(response.data.main_description_request)
                    setSecondDescriptionRequest(response.data.second_description_request)
                    setPriceRequest(response.data.price_request)
                    setGuaranteeRequest(response.data.guarantee_request)
                    setType(response.data.type)

                    var prod2 = [...p2];
                    await p2.forEach((p, index) => {
                        let personalProd = response.data.product_to_companies.filter((prod) => p.company === prod.company && prod.user === parseInt(localStorage.getItem('user')))
                        if (personalProd && personalProd.length > 0) {
                            prod2[index] = {
                                company: p2[index].company,
                                company_title: p2[index].company_title,
                                id: personalProd[0].id ?? 0,
                                title: personalProd[0].title ?? '',
                                price: personalProd[0].price ?? 0,
                                code_oblio: personalProd[0].code_oblio ?? '',
                                guarantee: personalProd[0].guarantee ?? 36,
                                second_description: personalProd[0].second_description ?? '',
                                main_description: personalProd[0].main_description ?? '',
                                stock: personalProd[0].stock ?? 0
                            }
                        } else {
                            let ind = response.data.product_to_companies.filter((prod) => p.company === prod.company)
                            if (ind && ind.length > 0) {
                                prod2[index] = {
                                    company: p2[index].company,
                                    company_title: p2[index].company_title,
                                    // id: ind[0].id ?? 0,
                                    title: ind[0].title ?? '',
                                    price: ind[0].price ?? 0,
                                    code_oblio: ind[0].code_oblio ?? '',
                                    guarantee: ind[0].guarantee ?? 36,
                                    second_description: ind[0].second_description ?? '',
                                    main_description: ind[0].main_description ?? '',
                                    stock: ind[0].stock ?? 0
                                }
                            }
                        }

                    })
                    await setProductToCompany(prod2)

                    setLoading(false)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getCompanies = async () => {
        axios.get(process.env.REACT_APP_API + '/companies', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                let temp2 = []

                response.data.forEach((company, i) => {
                    temp.push({
                        company_title: company.title,
                        company: company.id,
                        title: '',
                        code_oblio: '',
                        price: 0,
                        guarantee: 36,
                        second_description: '',
                        main_description: '',
                        stock: 0
                    })
                    temp2.push({
                        id: company.id,
                        display: i === 0
                    })
                })
                await setProductToCompany(temp)
                getData(temp)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const save = async (e) => {
        e.preventDefault();
        if (category === '' && projectType !== 'smartlab_smis') {
            toast.error('Nu este selectată categoria!')
            return

        }
        setSaveLoading(true)

        await axios.put(process.env.REACT_APP_API + '/products/' + params.id,
            {
                title, price, guarantee, product_category: category, stock, type,part_number: partNumber, project_type: projectType,
                main_description_request: descriptionRequest, second_description_request: secondDescriptionRequest, price_request: priceRequest,
                guarantee_request: guaranteeRequest, product_to_company: productToCompany
            }, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
            .then(async (response) => {
                toast.success("Informațiile au fost salvate cu succes!")
               // navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setLoading(true)
        await getData(productToCompany)

        setSaveLoading(false)


    }
    const duplicate = async (temp) => {
        setDuplicateLoading(true)

        await axios.post(process.env.REACT_APP_API + '/products',
            {
                title, price, guarantee, product_category: category, stock: 0, type,part_number: partNumber, project_type: temp,
                main_description_request: descriptionRequest, second_description_request: secondDescriptionRequest, price_request: priceRequest,
                guarantee_request: guaranteeRequest, product_to_company: productToCompany
            }, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
            .then(async (response) => {
                toast.success("Informațiile au fost salvate cu succes!")
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })

        setDuplicateLoading(false)

    }
    if (loading) {
        return <LoadingComp/>
    }

    const setProd = (value, index, index2) => {
        var prod2 = [...productToCompany];
        prod2[index][index2] = value
        setProductToCompany(prod2)
    }
    const setValues = (index) => {
        var prod2 = [...productToCompany];
        prod2[index]['title'] = title;
        prod2[index]['price'] = priceRequest;
        prod2[index]['guarantee'] = guaranteeRequest;
        prod2[index]['second_description'] = secondDescriptionRequest;
        prod2[index]['main_description'] = descriptionRequest;

        setProductToCompany(prod2)
    }

    return (

        <div className={"row"}>
            <div className={"col-lg-12 col-xl-3 pb-4"}>
                <div className={"secondary-navigation"}>
                    <a style={{cursor: "pointer"}} className={activePage === 0 ? 'active' : ''} onClick={() => setActivePage(0)}>
                        <div className={"item"}>
                            <div className={"icon"}>
                                {/*    <i className={item.icon}></i>*/}
                            </div>
                            <div className={"title"}>
                                Detalii produs
                            </div>
                        </div>
                    </a>

                    {
                        projectType !== 'smartlab_smis' && productToCompany.map((item, i) => (
                            <a style={{cursor: "pointer"}} className={activePage === item.company ? 'active' : ''}
                               key={i} onClick={() => {
                                setActivePage(item.company)
                                setActiveProduct(productToCompany.find((item2) => item2.company === item.company))
                                setActiveIndex(i)
                            }}>
                                <div className={"item"}>
                                    <div className={"icon"}>
                                        {/* <i className={item.icon}></i>*/}
                                    </div>
                                    <div className={"title"}>
                                        {item.company_title}
                                    </div>
                                </div>
                            </a>
                        ))
                    }

                </div>
            </div>
            <div className={"col-lg-12 col-xl-9"}>
                <div className="card">
                    <div className={"header"}><p>Editează produsul</p></div>
                    <form onSubmit={(e) => save(e)}>
                        <div className={"content"}>
                            {
                                activePage === 0 ?
                                    projectType === 'smartlab_smis' ?

                                        <div className={"row"}>
                                            <div className={"col-6"}>
                                                <div className={"form-control"}>
                                                    <div
                                                        className={"form-label"}>Tip proiect<span
                                                        className={"form-required"}>*</span></div>
                                                    <Selector
                                                        options={[{
                                                            value: 'pnras',
                                                            label: 'PNRAS'
                                                        }, {
                                                            value: 'smartlab',
                                                            label: 'SMARTLAB'
                                                        }, {
                                                            value: 'smartlab_smis',
                                                            label: 'SMARTLAB SMIS'
                                                        }]}
                                                        value={projectType}
                                                        onChange={(option) => setProjectType(option.value)}
                                                        isSearchable={false}
                                                        isOptionDisabled={(option) => option.disabled}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"col-md-6 col-sm-12"}>
                                                <div className={"form-control"}>
                                                    <div
                                                        className={"form-label"}>Garanție (luni)<span
                                                        className={"form-required"}>*</span></div>
                                                    <input type={"number"} step={"1"} className={"form-input"} required
                                                           onFocus={(event) => event.target.select()}
                                                           value={guaranteeRequest} onChange={(e) => setGuaranteeRequest(e.target.value)}/>
                                                </div>
                                            </div>
                                            <div className={"col-12"}>
                                                <div className={"form-control"}>
                                                    <div
                                                        className={"form-label"}>Denumire<span
                                                        className={"form-required"}>*</span></div>
                                                    <input type={"text"} className={"form-input"} required
                                                           value={title} onChange={(e) => setTitle(e.target.value)}/>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                    <div className={"row"}>

                                        <div className={"col-6"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>Tip proiect<span
                                                    className={"form-required"}>*</span></div>
                                                <Selector
                                                    options={[{
                                                        value: 'pnras',
                                                        label: 'PNRAS'
                                                    }, {
                                                        value: 'smartlab',
                                                        label: 'SMARTLAB'
                                                    }, {
                                                        value: 'smartlab_smis',
                                                        label: 'SMARTLAB SMIS'
                                                    }]}
                                                    value={projectType}
                                                    onChange={(option) => setProjectType(option.value)}
                                                    isSearchable={false}
                                                    isOptionDisabled={(option) => option.disabled}
                                                />
                                            </div>
                                        </div>
                                        <div className={"col-6"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>Stoc<span
                                                    className={"form-required"}>*</span></div>
                                                <input type={"number"} className={"form-input"} required
                                                       value={stock} onChange={(e) => setStock(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className={"col-6"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>Denumire<span
                                                    className={"form-required"}>*</span></div>
                                                <input type={"text"} className={"form-input"} required
                                                       value={title} onChange={(e) => setTitle(e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className={"col-6"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>Cod produs</div>
                                                <input type={"text"} className={"form-input"}
                                                       value={partNumber} onChange={(e) => setPartNumber(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className={"col-md-6 col-sm-12"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>Tip<span
                                                    className={"form-required"}>*</span></div>
                                                <Selector
                                                    options={[{
                                                        value: 'services',
                                                        label: intl.formatMessage({id: 'products.type.services'})
                                                    }, {
                                                        value: 'events',
                                                        label: intl.formatMessage({id: 'products.type.events'})
                                                    }, {
                                                        value: 'products',
                                                        label: intl.formatMessage({id: 'products.type.products'})
                                                    }, {
                                                        value: 'other_services',
                                                        label: intl.formatMessage({id: 'products.type.other_services'})
                                                    }, {
                                                        value: 'teachers_training',
                                                        label: intl.formatMessage({id: 'products.type.teachers_training'})
                                                    }, {
                                                        value: 'furniture',
                                                        label: intl.formatMessage({id: 'products.type.furniture'})
                                                    },{
                                                        value: 'products_smartlab',
                                                        label: intl.formatMessage({id: 'projects.type.products_smartlab'})
                                                    }, {
                                                        value: 'educational',
                                                        label: intl.formatMessage({id: 'projects.type.educational'})
                                                    }, {
                                                        value: 'connect_devices',
                                                        label: intl.formatMessage({id: 'projects.type.connect_devices'})
                                                    }, {
                                                        value: 'teacher_training_sessions',
                                                        label: intl.formatMessage({id: 'projects.type.teacher_training_sessions'})
                                                    }, {
                                                        value: 'ergonomic_furniture',
                                                        label: intl.formatMessage({id: 'projects.type.ergonomic_furniture'})
                                                    }]}
                                                    value={type}
                                                    onChange={(option) => setType(option.value)}
                                                    isSearchable={false}
                                                    isOptionDisabled={(option) => option.disabled}
                                                />
                                            </div>
                                        </div>
                                        <div className={"col-md-6 col-sm-12"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>Categorie<span
                                                    className={"form-required"}>*</span></div>
                                                <Selector
                                                    options={categories}
                                                    value={category}
                                                    onChange={(option) => setCategory(option.value)}
                                                    isSearchable={false}
                                                    isOptionDisabled={(option) => option.disabled}
                                                />
                                            </div>
                                        </div>
                                        <div className={"col-md-6 col-sm-12"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>Preț referat de necesitate/cerere ofertă<span
                                                    className={"form-required"}>*</span></div>
                                                <input type={"number"} step={"0.01"} className={"form-input"} required
                                                       onFocus={(event) => event.target.select()}
                                                       value={priceRequest} onChange={(e) => setPriceRequest(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className={"col-md-6 col-sm-12"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>Garanție referat de necesitate/cerere ofertă (luni)<span
                                                    className={"form-required"}>*</span></div>
                                                <input type={"number"} step={"1"} className={"form-input"} required
                                                       onFocus={(event) => event.target.select()}
                                                       value={guaranteeRequest} onChange={(e) => setGuaranteeRequest(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className={"col-md-6 col-sm-12"}>
                                            <CustomTextarea minHeight={'150px'} label={'Descriere referat de necesitate/cerere ofertă'} value={descriptionRequest} required={true} setValue={setDescriptionRequest}/>
                                        </div>
                                        <div className={"col-md-6 col-sm-12"}>
                                            <CustomTextarea minHeight={'150px'} label={'Specificații tehnice referat de necesitate/cerere ofertă'} value={secondDescriptionRequest} required={true} setValue={setSecondDescriptionRequest}/>
                                        </div>
                                    </div>
                                    :
                                    <div className={"row"}>
                                        <div className={"col-12 d-flex"}>
                                            <Button
                                                className={"btn-secondary mt-2 mb-2 ms-auto"}
                                                value={"Completează cu datele din referatul de necesitate/cererea ofertă"}
                                                type={'button'}
                                                onClick={() => {
                                                    setValues(activeIndex)
                                                }}
                                            />
                                        </div>
                                        <div className={"col-6"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>Denumire
                                                </div>
                                                <input type={"text"} className={"form-input"}
                                                       value={activeProduct.title} onChange={(e) => setProd(e.target.value, activeIndex, 'title')}/>
                                            </div>
                                        </div>
                                        <div className={"col-6"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>Cod produs OBLIO
                                                </div>
                                                <input type={"text"} className={"form-input"}
                                                       value={activeProduct.code_oblio} onChange={(e) => setProd(e.target.value, activeIndex, 'code_oblio')}/>
                                            </div>
                                        </div>
                                        <div className={"col-md-6 col-sm-12"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>Preț
                                                </div>
                                                <input type={"number"} step={"0.01"} className={"form-input"}
                                                       onFocus={(event) => event.target.select()}
                                                       value={activeProduct.price} onChange={(e) => setProd(e.target.value, activeIndex, 'price')}/>
                                            </div>
                                        </div>
                                        <div className={"col-md-6 col-sm-12"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>Garanție (luni)
                                                </div>
                                                <input type={"number"} step={"1"} className={"form-input"}
                                                       onFocus={(event) => event.target.select()}
                                                       value={activeProduct.guarantee} onChange={(e) => setProd(e.target.value, activeIndex, 'guarantee')}/>
                                            </div>
                                        </div>

                                        <div className={"col-md-6 col-sm-12"}>
                                            <CustomTextarea minHeight={'150px'} label={'Descriere'} value={activeProduct.main_description} required={false} setValue={(value) => {
                                                setProd(value, activeIndex, 'main_description')
                                            }}/>
                                        </div>
                                        <div className={"col-md-6 col-sm-12"}>
                                            <CustomTextarea minHeight={'150px'} label={'Specificații tehnice'} value={activeProduct.second_description} required={false} setValue={(value) => {
                                                setProd(value, activeIndex, 'second_description')
                                            }}/>
                                        </div>

                                    </div>
                            }
                        </div>
                        <div className={"footer"}>
                            <div className={"row"}>
                                <div className={"col-12 col-md-6"}>
                                    <Button
                                        className={"btn-light mt-2"}
                                        icon={"fa-solid fa-chevron-left"}
                                        value={"Înapoi"}
                                        onClick={() => navigate(-1)}
                                    />
                                </div>
                                <div className={"col-12 col-md-6 d-flex"}>
                                    <Button
                                        className={"btn-secondary mt-2 ms-auto"}
                                        value={"Duplică în " + (projectType === 'pnras' ? 'SMARTLAB' : 'PNRAS')}
                                        disabled={duplicateLoading}
                                        loading={duplicateLoading}
                                        type={'button'}
                                        onClick={() => {
                                            duplicate(projectType === 'pnras' ? 'smartlab' : 'pnras')
                                        }}
                                    />
                                </div>
                                <div className={"col-12 d-flex"}>
                                    <Button
                                        className={"btn-secondary mt-2 ms-auto"}
                                        icon={"fa-solid fa-plus"}
                                        value={"Salvează"}
                                        disabled={saveLoading}
                                        loading={saveLoading}
                                        type={'submit'}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

