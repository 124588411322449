import React from "react";
import '../LineItems/LineItems.scss';
import Selector from "../Selector/Selector";
import CustomTextarea from "../CustomTextarea/CustomTextarea";

export default function LineItemsOffer(
    {
        total,
        setTotal,
        setTotalValueTva,
        items,
        products,
        company,
        setItems = () => {
        },
    }
) {
    const setProductItem = (index, item, product) => {
        let newItems = [...items]
        setItems([])
        newItems[index] = {...item}

        let prod_to_company = product.product_to_companies.find(prod => parseInt(prod.company) === parseInt(company))
        if(company === 0 || !prod_to_company || (prod_to_company && (!prod_to_company.title || prod_to_company.title === ''))){
            newItems[index].guarantee = product.guarantee
            newItems[index].price = product.price
            newItems[index].main_description = product.main_description_request
            newItems[index].second_description = product.second_description_request
            newItems[index].product = product.value
            newItems[index].title = product.label
        }else{
            newItems[index].guarantee = prod_to_company.guarantee
            newItems[index].price = prod_to_company.price
            newItems[index].main_description = prod_to_company.main_description
            newItems[index].second_description = prod_to_company.second_description
            newItems[index].product = prod_to_company.product
            newItems[index].title = prod_to_company.title
        }

        updateTvaAndTotal(newItems)
        setItems(newItems)
    }
    const setQtyItem = (index, item, qty) => {

        let newItems = [...items]
        newItems[index] = {...item}
        newItems[index].qty = qty


        updateTvaAndTotal(newItems)
        setItems(newItems)
    }
    const setGuaranteeItem = (index, item, guarantee) => {

        let newItems = [...items]
        newItems[index] = {...item}
        newItems[index].guarantee = parseInt(guarantee)
        setItems(newItems)
    }
    const setPriceItem = (index, item, price) => {

        let newItems = [...items]
        newItems[index] = {...item}
        newItems[index].price = price


        setItems(newItems)
        updateTvaAndTotal(newItems)

    }
    const setMainDesc = (index, item, desc) => {

        let newItems = [...items]
        newItems[index] = {...item}
        newItems[index].main_description = desc

        setItems(newItems)

    }
    const setSecondDesc = (index, item, desc) => {

        let newItems = [...items]
        newItems[index] = {...item}
        newItems[index].second_description = desc

        setItems(newItems)

    }
    const updateTvaAndTotal = (items) => {
        let temp = 0, tempTotal = 0
        items.forEach((item) => {
            if (item.price && item.qty) {
                temp = (parseFloat(temp) + parseFloat(((parseFloat(item.qty) * parseFloat(item.price) * 0.19) ?? 0).toFixed(2))).toFixed(2, 2)
                tempTotal = (parseFloat(tempTotal) + parseFloat(((parseFloat(item.qty) * parseFloat(item.price)) ?? 0).toFixed(2))).toFixed(2, 2)
            }
        })
        setTotalValueTva(temp)
        setTotal(tempTotal)

    }

    return (
        <table className={"invoice-table-container"}>
            <thead key={total}>
            {items.length > 0 && <tr className={"heading"}>
                <th className={"description"}>Denumire</th>
                <th className={"description"}>Produs</th>
                <th className={"quantity"}>Garanție (luni)</th>
                <th className={"quantity"}>Cantitate</th>
                <th className={"price"}>Preț</th>
                <th className={"subtotal right-aligned-cell"}>Valoare</th>
            </tr>}
            </thead>

            {items.length > 0 && items.map((item, i) => (
                <tbody key={'prod-' + (item.product ?? (item.price_request + '_' + i))}>
                <tr>
                    <td>{item.label}</td>
                    <td className={"right-aligned-cell"}>
                        <Selector
                            options={item.category ? products.filter((prod) => prod.category === item.category) : products}
                            value={item.product}
                            onChange={(e) => {
                                setProductItem(i, item, e)
                            }}
                            isSearchable={true}
                            isOptionDisabled={(option) => option.disabled}
                        />

                    </td>
                    <td className={"right-aligned-cell"}>
                        <input
                            className={"d-flex form-input"}
                            type="number"
                            value={item.guarantee}
                            step={1}
                            onChange={(e) => setGuaranteeItem(i, item, e.target.value)}
                        />
                    </td>
                    <td className={"right-aligned-cell"}>
                        <input
                            className={"d-flex form-input"}
                            type="number"
                            value={item.qty}
                            step={1}
                            onChange={(e) => setQtyItem(i, item, e.target.value)}
                        />
                    </td>
                    <td className={"right-aligned-cell"}>
                        <input
                            className={"d-flex form-input"}
                            type="number"
                            value={parseFloat(item.price)}
                            step={0.01}
                            onChange={(e) => setPriceItem(i, item, e.target.value)}
                        /></td>
                    <td className={"right-aligned-cell"}><strong>{parseFloat(item.qty * item.price).toFixed(2)}</strong></td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <CustomTextarea minHeight={'unset'} label={'Descriere'} value={item.main_description} required={false} setValue={(value) => {
                            setMainDesc(i, item, value)
                        }}/>
                    </td>
                    <td colSpan={4}>

                        <CustomTextarea minHeight={'unset'} label={'Specificații tehnice'} value={item.second_description} required={false} setValue={(value) => {
                            setSecondDesc(i, item, value)
                        }}/>
                    </td>
                </tr>

                </tbody>
            ))}
        </table>
    );
}

