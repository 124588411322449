import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import {useParams} from "react-router-dom";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import ModalComp from "../../../components/ModalComp/ModalComp";
import Selector from "../../../components/elements/Selector/Selector";
import {toast} from "react-toastify";
import moment from "moment";
import {Tooltip} from "react-tooltip";

export default function TrackProducts() {

    const intl = useIntl();
    let params = useParams();
    const jwt = localStorage.getItem('jwt')
    const statuses = [
        {
            value: 'out_of_stock',
            label: 'Lipsă stoc'
        },
        {
            value: 'ordered',
            label: 'Comandat'
        },
        {
            value: 'delivered',
            label: 'Livrat'
        },
    ];




    const [products, setProducts] = useState([])
    const [project, setProject] = useState([])
    const [productsSelected, setProductsSelected] = useState([])
    const [status, setStatus] = useState('')
    const [comment, setComment] = useState('')
    const [modalIsOpen, setModalIsOpen] = useState(0)
    const [returnDate, setReturnDate] = useState('')

    const [loading, setLoading] = useState(true)
    const [loadingSave, setLoadingSave] = useState(false)

    useEffect(() => {
        getProducts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async (prods = []) => {
        axios.get(process.env.REACT_APP_API + '/projects/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.winning_offer && response.data.winning_offer.products.length > 0){
                    for (let i =0; i < response.data.winning_offer.products.length; i++){
                        response.data.winning_offer.products[i].product = prods.find(item => item.id === response.data.winning_offer.products[i].product)
                    }
                }

                setProject(response.data)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getProducts = async () => {
        axios.post(process.env.REACT_APP_API + '/products/list/0' , {}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setProducts(response.data.products)
                getData(response.data.products)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const addStatus = async (e) => {
        e.preventDefault()
        setLoadingSave(true)
        if (status === ''){
            toast.error('Vă rugăm să selectați statusul!')
            setLoadingSave(false)
        }
        axios.post(process.env.REACT_APP_API + '/products/add-status' , {
            project: project.id, acquisition: project.acquisition, products: productsSelected.map(item=> item.id), status,comment, returnDate, qty: productsSelected.map(item=> item.qty)}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
               getData(products)
                setStatus('')
                setReturnDate('')
                setProductsSelected([])
                setLoadingSave(false)
                setModalIsOpen(false)
            })
            .catch(err => {
                errorParser(err, intl)
                setLoadingSave(false)
                setModalIsOpen(false)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }
    const getOptions = (item) => {
        let options = []
        for (let i=1; i<= item.qty; i++){
            options.push({
                label: i,
                value: i
            })
        }
        return options;
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>
                        {project.client_name}
                    </h1>
                </div>

                <div className={"col-12 pb-4"}>

                    <div className="card">

                        <div className={"header d-flex"}>
                            {productsSelected.length > 0 && <Button
                                className={"btn-primary ms-auto"}
                                value={"Adaugă status"}
                                onClick={() => {
                                    setModalIsOpen(true)
                                }}
                            />}
                        </div>


                        <div className={"content p-0"}>
                            <div className={"table mb-1"}>

                                <div className={"table-head"}>
                                    <div className={"row"}>
                                        <div className={"col-1 table-td"}>Nr</div>
                                        <div className={"col-3 table-td"}>Denumire</div>
                                        <div className={"col-2 table-td"}>Cod produs</div>
                                        <div className={"col-2 table-td"}>Ultimul status</div>
                                        <div className={"col-2 table-td"}>Data</div>
                                        <div className={"col-2 table-td"}></div>
                                    </div>
                                </div>

                                <div className={"table-body"}>
                                    {
                                        project.winning_offer && project.winning_offer.products.map((product, i) => (
                                            <div className={"table-row"} key={i}>
                                                <div className={"row"}>
                                                    <div
                                                        className={"col-1 table-td"}>
                                                        {i + 1}
                                                    </div>
                                                    <div
                                                        className={"col-3 table-td"}>
                                                        {product.product.title}
                                                    </div>
                                                    <div
                                                        className={"col-2 table-td"}>
                                                        {product.product.part_number}
                                                    </div>
                                                    <div
                                                        className={"col-2 table-td"} data-tooltip-id={"info-" +i}>
                                                        {product.status_history.length > 0 &&
                                                            <Tooltip id={"info-" +i} style={{width: '300px'}}>
                                                            {
                                                                product.status_history.length > 0 && product.status_history.map((item, j) => (
                                                                    <div>
                                                                        {j+1}.{statuses.find(it => it.value === item.status).label} {/*{item && item.qty ? item.qty : product.qty}/{product.qty} */} {
                                                                            item.status === 'out_of_stock' && item.returnDate ?
                                                                            moment(item.returnDate).format('DD/MM/YYYY')
                                                                                : ''
                                                                        }
                                                                        {
                                                                            item.comment &&
                                                                                '('+item.comment+')'
                                                                        }
                                                                    </div>
                                                                ))
                                                            }
                                                        </Tooltip>}
                                                        {product.status_history.length > 0  &&
                                                            (statuses.find(item => item.value === product.status_history[product.status_history.length - 1].status).label +' '+
                                                            product.status_history[product.status_history.length - 1].qty+'/'+product.qty)}
                                                        <br/>
                                                        {
                                                            product.status_history.length > 0 && product.status_history[product.status_history.length - 1].status === 'out_of_stock' &&
                                                            product.status_history[product.status_history.length - 1].returnDate &&
                                                            moment(product.status_history[product.status_history.length - 1].returnDate).format('DD/MM/YYYY')
                                                        }

                                                        {
                                                            product.status_history.length > 0 && product.status_history[product.status_history.length - 1].comment &&
                                                                '('+product.status_history[product.status_history.length - 1].comment+')'
                                                        }
                                                    </div>
                                                    <div
                                                        className={"col-2 table-td"}>
                                                        {
                                                            product.status_history.length > 0 &&
                                                             moment(product.status_history[product.status_history.length - 1].created_at).format('DD/MM/YYYY HH:mm')
                                                        }
                                                    </div>
                                                    <div className={"col-2 table-td"}>
                                                        <input type={"checkbox"}
                                                               value={productsSelected.find(item => item.id === product.product.id) ? true : false}
                                                               onChange={(e) => {

                                                                   if (productsSelected.find(item => item.id === product.product.id)){
                                                                       let temp = productsSelected;
                                                                       temp.splice(productsSelected.findIndex(item => item.id === product.product.id), 1)

                                                                       setProductsSelected(temp)
                                                                   }else{
                                                                       setProductsSelected(productsSelected.concat([{...product.product, status_history: product.status_history, qty: product.qty}]))}
                                                                   }
                                                               }
                                                                   />
                                                        <Button
                                                            className={"btn-light btn-edit ms-auto"}
                                                            icon={"fa-solid fa-pen"}
                                                            onClick={() => {
                                                                setProductsSelected([{...product.product, status_history: product.status_history, qty: product.qty}])
                                                                setModalIsOpen(true)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={"footer d-flex justify-content-center"}>
                        </div>
                    </div>
                </div>
            </div>
            <ModalComp
                title={'Status produse: ' + (productsSelected.length > 0 && productsSelected.map((item, i)=> {
                    let text  = item.title
                    if (i !== productsSelected.length -1){
                        text += ', '
                    }
                    return text
                }))}
                isOpen={modalIsOpen}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalIsOpen(false)}
            >
                <form onSubmit={(e) => addStatus(e)}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                       {/* {
                            productSelected && productSelected.status_history && productSelected.status_history.map((item, i) => (
                                <p key={i}>{i+1}. {statuses.find(it => it.value === item.status).label} - {moment(item.created_at).format('DD/MM/YYYY HH:mm')}
                                    { item.status === 'out_of_stock' && item.returnDate ? '; Dată revenire în stoc: ' + moment(item.returnDate).format('DD/MM/YYYY') : ''}</p>
                            ))
                        }*/}



                        <div
                            className={"form-label"}>Adaugă status<span
                            className={"form-required"}>*</span></div>
                        <Selector
                            options={statuses}
                            value={status}
                            required={true}
                            onChange={(option) => setStatus(option.value)}
                            isSearchable={false}
                            isOptionDisabled={(option) => option.disabled}
                        />
                        {
                            productsSelected.map((item, i) => (
                                <div>
                                    <div
                                        className={"form-label"}>Cantitate produs: {item.title} {item.qty}<span
                                        className={"form-required"}>*</span></div>
                                    <Selector
                                        options={getOptions(item)}
                                        value={item.qty}
                                        required={true}
                                        onChange={(option) => {
                                            let temp = productsSelected
                                            temp[i].qty = option.value
                                            setProductsSelected(temp)
                                        }}
                                        isSearchable={false}
                                        isOptionDisabled={(option) => option.disabled}
                                    />
                                </div>
                            ))
                        }

                        { status === 'out_of_stock' && <div className={"form-control"}>
                            <div
                                className={"form-label"}>Dată revenire în stoc</div>

                            <input type={"date"} className={"form-input"}
                                   value={returnDate} onChange={(e) => setReturnDate(e.target.value)}/>
                        </div>}

                        <div
                            className={"form-label"}>Observații</div>
                        <textarea className={"form-input"} value={comment} onChange={(e) => setComment(e.target.value)}/>
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={'Salvează'}
                                    type={'submit'}
                                    disabled={loadingSave}
                                    loading={loadingSave}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
        </div>
    );
}

