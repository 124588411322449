import './UploadComp.scss';
import {useEffect, useRef} from "react";

export default function UploadImageComp(
    {
        image,
        setImage,
        imageUrl,
        setImageUrl,
        className = ""
    }
) {
    const inputRef = useRef()

    useEffect(() => {
        if (!image) {
            return
        }

        // create the preview
        const objectUrl = URL.createObjectURL(image)
        setImageUrl(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image])

    return (<div className={className}>
            <input type={"file"}
                   ref={inputRef}
                   accept="image/png, image/jpg, image/jpeg"
                   style={{display: "none"}}
                   multiple={false}
                   onChange={(e) => setImage(e.target.files[0])}
            />
            <div className={"upload-image-comp"} onClick={() => inputRef.current.click()}>
                {
                    imageUrl ?
                        <div className={"preview"}>
                            <img src={imageUrl} alt={""}/>
                            <div className={"overlay"}>
                                <i className="fa-regular fa-images icon"></i>
                                <div className={"title"}>Apasă aici pentru a încărca o imagine</div>
                                <div className={"info"}>PNG sau JPG</div>
                            </div>
                        </div>
                        :
                        <>
                            <i className="fa-regular fa-images icon"></i>
                            <div className={"title"}>Apasă aici pentru a încărca o imagine</div>
                            <div className={"info"}>PNG sau JPG</div>
                        </>
                }
            </div>
        </div>
    );
}

