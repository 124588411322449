import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import LoadingCompHover from "../../../components/elements/LoadingCompHover/LoadingCompHover";

export default function EditClient() {

    const intl = useIntl();
    const navigate = useNavigate()
    let params = useParams();
    const jwt = localStorage.getItem('jwt')

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [disabledEmail, setDisabledEmail] = useState(false)
    const [phone, setPhone] = useState('')
    const [county, setCounty] = useState('')
    const [city, setCity] = useState('')
    const [address, setAddress] = useState('')
    const [change, setChange] = useState(0)
    const [fiscalCode, setFiscalCode] = useState('')
    const [legalRepresentativeFunction, setLegalRepresentativeFunction] = useState('')
    const [bankAccount, setBankAccount] = useState('')
    const [bank, setBank] = useState('')
    const [companyTitle, setCompanyTitle] = useState('')
    const [fax, setFax] = useState('')
    const [regComNumber, setRegComNumber] = useState('')
    const [projectTitle, setProjectTitle] = useState('')
    const [numberFinancingContract, setNumberFinancingContract] = useState('')
    const [dateFinancingContract, setDateFinancingContract] = useState('')

    const [loading, setLoading] = useState(true)
    const [loadingCui, setLoadingCui] = useState(false)

    const [saveLoading, setSaveLoading] = useState(false)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/clients/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setName(response.data.name)
                    setEmail(response.data.email)
                    setDisabledEmail(response.data.user !== null)
                    setPhone(response.data.phone ?? '')
                    setCounty(response.data.county ?? '')
                    setCity(response.data.city ?? '')
                    setAddress(response.data.address ?? '')
                    setFiscalCode(response.data.fiscal_code ?? '')
                    setLegalRepresentativeFunction(response.data.legal_representative_function ?? '')
                    setBankAccount(response.data.bank_account ?? '')
                    setBank(response.data.bank ?? '')
                    setCompanyTitle(response.data.company_title ?? '')
                    setFax(response.data.fax ?? '')
                    setRegComNumber(response.data.reg_com_number ?? '')
                    setProjectTitle(response.data.projectTitle ?? '')
                    setNumberFinancingContract(response.data.number_financing_contract ?? '')
                    setDateFinancingContract(response.data.dateFinancingContract ?? '')

                    setLoading(false)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (e) => {
        e.preventDefault();
        setSaveLoading(true)
        let payload = {
            name: name,
            email: email,
            phone: phone,
            county: county,
            city: city,
            address: address,
            fiscal_code: fiscalCode,
            legal_representative_function: legalRepresentativeFunction,
            bank_account: bankAccount,
            bank: bank,
            company_title: companyTitle,
            fax: fax,
            reg_com_number: regComNumber,
            projectTitle: projectTitle,
            number_financing_contract: numberFinancingContract,
            dateFinancingContract: dateFinancingContract,
        }

        await axios.put(process.env.REACT_APP_API + '/clients/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (parseInt(response.data.status) === 1) {
                    toast.success("Informațiile au fost salvate cu succes!")
                    navigate(-1)
                } else {
                    toast.error("Există deja o instituție cu acestă adresă de  email!")
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    const sendEmail = async () => {
        await axios.get(process.env.REACT_APP_API + '/clients/send-email/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (parseInt(response.data.status) === 1) {
                    toast.success("Email-ul a fost trimis cu succes!")

                }
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }
    const getCompanyInfo = async () => {

        if ((fiscalCode.length === 7 && !fiscalCode.includes('RO')) || (fiscalCode.length === 9 && fiscalCode.includes('RO')) || (fiscalCode.length === 8 && !fiscalCode.includes('RO')) || (fiscalCode.length === 10 && fiscalCode.includes('RO'))) {
            setLoadingCui(true)
            axios.post(process.env.REACT_APP_API + '/company-info',
                {fiscal_code: fiscalCode}, {
                    headers: {
                        'Authorization': 'Bearer ' + jwt
                    }
                })
                .then(async (response) => {
                    if (response.data.data) {
                        setCounty(response.data.data.judet)
                        setCity(response.data.data.loc)
                        setAddress(response.data.data.adresa)
                        setCompanyTitle(response.data.data.nume)
                        setRegComNumber(response.data.data.cod_inmatriculare)
                    }
                    setLoadingCui(false)
                })
                .catch(err => {
                    errorParser(err, intl)
                    setLoadingCui(false)
                })
        }
    }
    useEffect(() => {
        getCompanyInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [change])

    if (loading) {
        return <LoadingComp/>
    }
    const copyToClipboard = async (id) => {
        var textField = document.createElement('textarea')
        var link = window.location.toString()
        textField.innerText = link.split('clients')[0] + 'hl?cu=' + btoa(id)
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')

        toast.success("Link-ul a fost copiat cu succes!")
        textField.remove()
    }
    return (
        <div className="container" style={{position: "relative"}}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>Instituții</h1>
                </div>

                {
                    loadingCui &&
                    <LoadingCompHover/>
                }
                <div className={"col-12"}>
                    <div className="card">
                        <div className={"header"}>

                            <div className={"row"}>
                                <div className={"col-md-6 col-sm-12"}>
                                    <p>Editare instituție</p>
                                </div>
                                <div className={"col-md-6 col-sm-12"}>
                                    <Button
                                        className={"btn-primary d-flex align-items-center ms-auto"}
                                        value={"Trimite email cu link-ul pentru completarea datelor"}
                                        onClick={() => sendEmail()}
                                    />
                                </div>

                                <div className={"col-12"}>
                                    <Button
                                        className={"btn-primary d-flex align-items-center ms-auto"}
                                        value={"Copiază link-ul pentru completarea datelor"}
                                        onClick={() => copyToClipboard(params.id)}
                                    />
                                </div>
                            </div>
                        </div>
                        <form onSubmit={(e) => save(e)}>
                            <div className={"content"}>
                                <div className={"row"}>
                                    <div className={"col-md-6 col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}> Nume reprezentant legal
                                                <span
                                                    className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"}
                                                   value={name} required
                                                   onChange={(e) => setName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Adresa de email<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"email"} className={"form-input"}
                                                   value={email} required disabled={disabledEmail}
                                                   onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Telefon<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"tel"} className={"form-input"}
                                                   value={phone} required
                                                   onChange={(e) => setPhone(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Fax
                                            </div>
                                            <input type={"text"} className={"form-input"} id={'reg-com-number-1'}
                                                   value={fax} onChange={(e) => setFax(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>CIF<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required={true}
                                                   value={fiscalCode} onChange={(e) => {
                                                setChange(change + 1)
                                                setFiscalCode(e.target.value)
                                            }}/>
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Nr. înreg. Registrul Comerțului
                                            </div>
                                            <input type={"text"} className={"form-input"} id={'reg-com-number-1'}
                                                   value={regComNumber} onChange={(e) => setRegComNumber(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Denumire instituție<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required={true}
                                                   value={companyTitle}
                                                   onChange={(e) => setCompanyTitle(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Funcție reprezentant legal
                                            </div>
                                            <input type={"text"} className={"form-input"}
                                                   value={legalRepresentativeFunction}
                                                   onChange={(e) => setLegalRepresentativeFunction(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Cont bancar
                                            </div>
                                            <input type={"text"} className={"form-input"}
                                                   value={bankAccount} onChange={(e) => setBankAccount(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-sm-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Bancă
                                            </div>
                                            <input type={"text"} className={"form-input"}
                                                   value={bank} onChange={(e) => setBank(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Județ
                                            </div>
                                            <input type={"text"} className={"form-input"}
                                                   value={county}
                                                   onChange={(e) => setCounty(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={"col-md-6 col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Oraș
                                            </div>
                                            <input type={"text"} className={"form-input"}
                                                   value={city}
                                                   onChange={(e) => setCity(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Adresa
                                            </div>
                                            <input type={"text"} className={"form-input"}
                                                   value={address}
                                                   onChange={(e) => setAddress(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Denumirea proiectului
                                            </div>
                                            <input type={"text"} className={"form-input"}
                                                   value={projectTitle} onChange={(e) => setProjectTitle(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Numărul contractului de finanțare
                                            </div>
                                            <input type={"text"} className={"form-input"}
                                                   onFocus={(event) => event.target.select()}
                                                   value={numberFinancingContract} onChange={(e) => setNumberFinancingContract(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Data contractului de finanțare
                                            </div>
                                            <input type={"date"} className={"form-input"}
                                                   value={dateFinancingContract} onChange={(e) => setDateFinancingContract(e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"footer"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                        <Button
                                            className={"btn-light mt-2"}
                                            icon={"fa-solid fa-chevron-left"}
                                            value={"Înapoi"}
                                            onClick={() => navigate(-1)}
                                        />
                                    </div>
                                    <div className={"col-12 col-md-6 d-flex"}>
                                        <Button
                                            className={"btn-secondary mt-2 ms-auto"}
                                            icon={"fa-regular fa-floppy-disk"}
                                            value={"Salvează"}
                                            disabled={saveLoading}
                                            loading={saveLoading}
                                            type={"submit"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>


        </div>
    );
}

