import './Selector.scss'
import { default as Select } from "react-select";

function Selector({options, onChange, value, ...props}) {

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };

    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };
    const formatGroupLabel = (data) => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    const getSelectedOptionByValue = (value) => {
           if(Array.isArray(value)){
            let result = [];
            value.forEach((x) => {
                result.push(options.find((item) => item.value === x))
            })
            return result
        }else{
            let result = null
            result = options.find((item) => item.value === value)
            return result
        }
    }

    return (<Select
            noOptionsMessage={() =>  "Nu există opțiuni"}
            placeholder={"Selectează"}
            classNamePrefix={"selector"}
            options={options}
            closeMenuOnSelect={!props.isMulti}
            onChange={onChange}
            cancellable={true}
            isDisabled={props.disabled}
            required={props.required}
            defaultValue={getSelectedOptionByValue(value) ?? ''}
            formatGroupLabel={formatGroupLabel}
            {...props}
            styles={ props.styles/*,{

                control: (provided, state) => ({
                    ...provided,
                    minHeight: '38px',
                    height: '38px',
                }),

                valueContainer: (provided, state) => ({
                    ...provided,
                    height: '38px',
                }),

                input: (provided, state) => ({
                    ...provided,
                    margin: '0px',
                }),
                indicatorSeparator: state => ({
                    display: 'none',
                }),
                indicatorsContainer: (provided, state) => ({
                    ...provided,
                    height: '38px',
                }),
            }*/}
        />
    );
}

export default Selector;
