import React from "react";
import Button from "../../elements/Button/Button";
import './LineItems.scss';

export default function LineItems(
    {
        totalValue,
        setTotalValue,
        items,
        setItems = () => {
        },
        isInvGenerated
    }
) {
    const dragItem = React.useRef(null);
    const dragOverItem = React.useRef(null);

    //const handle drag sorting
    const handleSort = () => {
        let _items = [...items];

        //remove and save the dragged item content
        const draggedItemContent = _items.splice(dragItem.current, 1)[0];

        //switch the position
        _items.splice(dragOverItem.current, 0, draggedItemContent);

        //reset the position ref
        dragItem.current = null;
        dragOverItem.current = null;

        //update the actual array
        setItems(_items);
    };

    const setQtyItem = (index, item, qty) => {

        let newItems = [...items]
        newItems[index] = {...item}
        newItems[index].qty = qty

        setItems(newItems)
        updateTotal(newItems)
    }
    const setGuaranteeItem = (index, item, guarantee) => {

        let newItems = [...items]
        newItems[index] = {...item}
        newItems[index].guarantee = guarantee
        setItems(newItems)
    }
    const setLabelItem = (index, item, label) => {

        let newItems = [...items]
        newItems[index] = {...item}
        newItems[index].label = label
        setItems(newItems)
    }
    const setPriceItem = (index, item, price) => {

        let newItems = [...items]
        newItems[index] = {...item}
        newItems[index].price = price
        newItems[index].total_value = parseFloat(parseFloat(parseFloat(parseFloat(price).toFixed(2)) * parseFloat(parseFloat(item.qty).toFixed(2))).toFixed(2))


        setItems(newItems)
        updateTotal(newItems)

    }
    const setTotalValueForItem = (index, item, value) => {

        let newItems = [...items]
        newItems[index] = {...item}
        newItems[index].total_value = value
        newItems[index].price = parseFloat(parseFloat(parseFloat(parseFloat(value).toFixed(2)) / parseFloat(parseFloat(item.qty).toFixed(2))).toFixed(2))


        setItems(newItems)
        updateTotal(newItems)

    }
    const deleteItem = (item) => {
        let newItems = [...items]
        let index = newItems.indexOf(item)
        if (index > -1) {
            newItems.splice(index, 1);
            setItems(newItems)
            updateTotal(newItems)
        }
    }

    const updateTotal = (newItems) => {
        let total = 0;
        for (let i=0; i < newItems.length; i++){
            total += newItems[i].total_value ? parseFloat(newItems[i].total_value) : parseFloat(parseFloat(parseFloat(newItems[i].qty) * parseFloat(newItems[i].price)).toFixed(2))
        }
        setTotalValue(total)
    }

    return (
        <table className={"invoice-table-container"}>
            <thead>
            {items.length > 0 && <tr className={"heading"}>
                <th className={"description"}>Denumire</th>
                <th className={"quantity"}>Garanție (luni)</th>
                <th className={"quantity"}>Cantitate</th>
                <th className={"price"}>Preț</th>
                <th className={"subtotal right-aligned-cell"}>Valoare</th>
                <th className={"delete"}></th>
            </tr>}
            </thead>
            <tbody>
            {items.map((item, i) => (
                <tr
                    key={i}>
                    <td>{i + 1}.
                        <input
                            style={{textAlign: "left", maxWidth: 'calc( 100% - 56px)'}}
                            className={"d-inline-flex form-input"}
                            type="text"
                            disabled={isInvGenerated}
                            value={item.label}
                            required
                            onChange={(e) => setLabelItem(i, item, e.target.value)}
                        />
                    </td>
                    <td className={"right-aligned-cell"}>
                        <input
                            className={"d-flex form-input"}
                            type="number"
                            disabled={isInvGenerated}
                            value={item.guarantee}
                            min={1}
                            step={1}
                            required
                            onChange={(e) => setGuaranteeItem(i, item, e.target.value)}
                        />
                    </td>
                    <td className={"right-aligned-cell"}>
                        <input
                            className={"d-flex form-input"}
                            type="number"
                            min={1}
                            disabled={isInvGenerated}
                            value={item.qty}
                            step={1}
                            required
                            onChange={(e) => setQtyItem(i, item, e.target.value !== '' ? e.target.value : 0)}
                        />
                    </td>
                    <td className={"right-aligned-cell"}>
                        <input
                            className={"d-flex form-input"}
                            type="number"
                            disabled={isInvGenerated}
                            value={item.price}
                            step={0.01}
                            min={1}
                            required
                            autoFocus
                            onChange={(e) => setPriceItem(i, item, e.target.value !== '' ? e.target.value : 0)}
                        /></td>
                    <td className={"right-aligned-cell"}>
                        <strong>
                            <input
                                className={"d-flex form-input"}
                                type="number"
                                disabled={isInvGenerated}
                                value={item.total_value ?? parseFloat(parseFloat(((item.qty !== '' ? item.qty : 0) * (item.price !== '' ? item.price : 0))).toFixed(2))}
                                step={0.01}
                                min={1}
                                required
                                autoFocus
                                onChange={(e) => setTotalValueForItem(i, item, e.target.value !== '' ? e.target.value : 0)}
                            /></strong>
                    </td>
                    <td className={"right-aligned-cell delete"}>
                        <div className={"d-flex"}>
                            {
                                !isInvGenerated && <Button
                                    className={"btn-light btn-delete"}
                                    icon={"fa-solid fa-trash"}
                                    type={'button'}
                                    disabled={isInvGenerated}
                                    onClick={() => {
                                        deleteItem(item)
                                    }}
                                />
                            }
                            <Button
                                className={"btn-light btn-edit draggable-item"}
                                icon={"fa-solid fa-grip-vertical"}
                                type={'button'}

                               // className={"draggable-item"}
                                draggable
                                onDragStart={(e) => (dragItem.current = i)}
                                onDragEnter={(e) => (dragOverItem.current = i)}
                                onDragEnd={handleSort}
                                onDragOver={(e) => e.preventDefault()}
                            />
                        </div>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}

