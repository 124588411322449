import React from "react";
import {useNavigate} from "react-router-dom";
import '../Projects.scss';

export default function AddProject() {
    const navigate = useNavigate()

    return (
        <div className="container">
            <div className={"row"}>

                <div className={"col-6"}>
                    <div className={"card"} style={{justifyContent: "center", alignItems: "center", minHeight: '200px',
                        fontSize: '40px', cursor: "pointer"}}
                    onClick={() => navigate('/add/pnras/0')}
                    >
                        PNRAS
                    </div>
                </div>
                <div className={"col-6"}>
                    <div className={"card"} style={{justifyContent: "center", alignItems: "center", minHeight: '200px',
                        fontSize: '40px', cursor: "pointer"}}
                         onClick={() => navigate('/add/smartlab/0')}>
                        SMARTLAB
                    </div>
                </div>
            </div>


        </div>
    );
}

