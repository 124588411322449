import {useIntl} from "react-intl";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import SecondaryNavigation from "../../../../../components/elements/SecondaryNavigation/SecondaryNavigation";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";


export default function EditProjectPNRASMenu() {

    const intl = useIntl();
    let params = useParams();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()

    const [projectTitle, setProjectTitle] = useState(null)
    const [items, setItems] = useState([])
    const [update, setUpdate] = useState(0)
    const [templates, setTemplates] = useState([])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/projects/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setProjectTitle(response.data.client_name ?? (response.data.projectTitle ?? ''))

                    let redirect = '';
                    let temp = [{
                        'icon': "",
                        'title': '1. Completare detalii achiziție',
                        'url': '/acquisitions/projects/pnras/' + params.id,
                        'end': true
                    },
                        {
                            'icon': "",
                            'title': '2. Anexa 13 - Referat De necesitate',
                            'url': '/acquisitions/projects/pnras/' + params.id + '/appendix-13',
                            'end': true,
                            'disabled': !(response.data.client && response.data.data.requirements.length > 0 && response.data.companies.length > 0)
                        },
                        {
                            'icon': "",
                            'title': '3. Anexa 26 - Nota justificativa',
                            'url': '/acquisitions/projects/pnras/' + params.id + '/appendix-26',
                            'end': false,
                            'disabled': !(response.data.documents.find(doc => doc.type === 'appendix_13'))
                        },
                        {
                            'icon': "",
                            'title': '4. Anexa 21 - Cerere de oferta',
                            'url': '/acquisitions/projects/pnras/' + params.id + '/appendix-21',
                            'end': false,
                            'disabled': !(response.data.documents.find(doc => doc.type === 'appendix_26'))
                        },
                        {
                            'icon': "",
                            'title': '5. Oferte',
                            'url': '/acquisitions/projects/pnras/' + params.id + '/offers',
                            // 'end': true,
                            'disabled': !(response.data.documents.find(doc => doc.type === 'appendix_26') &&
                                response.data.documents.filter(doc => doc.type === 'appendix_21').length === response.data.companies.length)
                        },
                        {
                            'icon': "",
                            'title': '6. Anexa 14 - PV Selectie',
                            'url': '/acquisitions/projects/pnras/' + params.id + '/appendix-14',
                            'end': true,
                            'disabled': !(response.data.documents.find(doc => doc.type === 'appendix_26') &&
                                response.data.documents.filter(doc => doc.type === 'offer').length === response.data.companies.length)
                        },
                        {
                            'icon': "",
                            'title': '7. Anexa 15 - Comunicari',
                            'url': '/acquisitions/projects/pnras/' + params.id + '/appendix-15',
                            'end': true,
                            'disabled': !(response.data.documents.find(doc => doc.type === 'appendix_14'))
                        },
                        {
                            'icon': "",
                            'title': '8. Anexa 16 - Contract',
                            'url': '/acquisitions/projects/pnras/' + params.id + '/appendix-16',
                            'end': true,
                            'disabled': !(response.data.documents.find(doc => doc.type === 'appendix_14') &&
                                response.data.documents.filter(doc => doc.type === 'appendix_15').length === response.data.companies.length)
                        },
                        {
                            'icon': "",
                            'title': '9. Anexa 20 - Pv receptie',
                            'url': '/acquisitions/projects/pnras/' + params.id + '/appendix-20',
                            'end': true,
                            'disabled': !(response.data.documents.find(doc => doc.type === 'contract'))
                        },
                        {
                            'icon': "",
                            'title': '10. Serii echipamente',
                            'url': '/acquisitions/projects/pnras/' + params.id + '/series',
                            'end': true,
                            'disabled': !(response.data.documents.find(doc => doc.type === 'appendix_20'))
                        },
                        {
                            'icon': "",
                            'title': '11. Facturare',
                            'url': '/acquisitions/projects/pnras/' + params.id + '/invoice',
                            'end': true,
                            'disabled': !(response.data.documents.find(doc => doc.type === 'series'))
                        }


                    ]
                    if (response.data.client && response.data.data.requirements.length > 0 && response.data.companies.length > 0)
                        redirect = '/acquisitions/projects/pnras/' + params.id + '/appendix-13'
                    if (response.data.documents.find(doc => doc.type === 'appendix_13'))
                        redirect = '/acquisitions/projects/pnras/' + params.id + '/appendix-26'
                    if (response.data.documents.find(doc => doc.type === 'appendix_26'))
                        redirect = '/acquisitions/projects/pnras/' + params.id + '/appendix-21'
                    if (response.data.documents.find(doc => doc.type === 'appendix_26') && response.data.documents.filter(doc => doc.type === 'appendix_21').length === response.data.companies.length)
                        redirect = '/acquisitions/projects/pnras/' + params.id + '/offers'
                    if (response.data.documents.find(doc => doc.type === 'appendix_26') && response.data.documents.filter(doc => doc.type === 'offer').length === response.data.companies.length)
                        redirect = '/acquisitions/projects/pnras/' + params.id + '/appendix-14'
                    if (response.data.documents.find(doc => doc.type === 'appendix_14'))
                        redirect = '/acquisitions/projects/pnras/' + params.id + '/appendix-15'
                    if (response.data.documents.find(doc => doc.type === 'appendix_14') && response.data.documents.filter(doc => doc.type === 'appendix_15').length === response.data.companies.length)
                        redirect = '/acquisitions/projects/pnras/' + params.id + '/appendix-16'
                    if (response.data.documents.find(doc => doc.type === 'contract'))
                        redirect = '/acquisitions/projects/pnras/' + params.id + '/appendix-20'
                    if (response.data.documents.find(doc => doc.type === 'appendix_20'))
                        redirect = '/acquisitions/projects/pnras/' + params.id + '/series'
                    if (response.data.documents.find(doc => doc.type === 'series'))
                        redirect = '/acquisitions/projects/pnras/' + params.id + '/invoice'

                    temp.push({
                        'icon': "",
                        'title': '12. Urmărire comandă',
                        'url': '/acquisitions/track-products/' + params.id,
                        'end': true,
                        'disabled': !response.data.documents.find(doc => doc.type === 'appendix_14')
                    })
                    temp.push({
                        'icon': "",
                        'title': 'Înapoi',
                        'url': '/acquisitions/' + response.data.acquisition + '/projects',
                        'end': true
                    })
                    setItems(temp)
                    getTemplates()

                    if (redirect !== '' && !window.location.href.includes('/offers/'))
                        navigate(redirect)

                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getTemplates = async () => {
        return axios.get(process.env.REACT_APP_API + '/templates/list/0', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                response.data.templates.forEach((t) => {
                    if (t.project_type === 'all' || t.project_type === 'pnras')
                        temp.push({
                            value: t.id, label: t.title, type: t.type,
                        })
                })
                setTemplates(temp)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (items.length === 0) {
        return <LoadingComp/>
    }

    return (
        <div className="container">
            <div className={"header"}>
                <h1 className={"container-title"}>{projectTitle}</h1>
            </div>

            <div className={"row"}>

                <div className={"col-md-12 col-lg-3 col-xl-2 pb-4"}>

                    <SecondaryNavigation
                        items={items}
                    />

                </div>
                <div className={"col-md-12 col-lg-9 col-xl-10 edit-project"}>
                    <Outlet context={[update, setUpdate, templates]}/>
                </div>
            </div>


        </div>
    );
}

