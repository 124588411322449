import Modal from "react-modal"
import './ModalComp.scss'
import React from "react";

export default function ModalComp({close = true, ...props}) {
    const customStyles = {
        content: {
            ...props.styleContent
        },
        overlay: {

        }
    };

    return (<Modal
        closeTimeoutMS={200}
        style={customStyles}
        {...props}
    >

        <div className={"head"}>
            <div className={"header pt-0 d-flex"} style={{width: '-webkit-fill-available'}}>
            <div className={"title"}>{props.title}</div>
                { close &&
                    <div className={"close"} onClick={() => props.onRequestClose()}>
                    <span><i className="fa-solid fa-xmark"></i></span>
                </div>}
            </div>
        </div>

        {props.children}

    </Modal>)
}
