import Sidebar from "./Sidebar/Sidebar";
import {Outlet} from "react-router-dom";
import './Layout.scss';
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../ErrorParser/ErrorParser";
import {useIntl} from "react-intl";
import LoadingComp from "../elements/LoadingComp/LoadingComp";

export default function Layout() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [sidebarMini, setSidebarMini] = useState(false);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let itemsTemp = []
                localStorage.setItem('user', response.data.id + '')
                setUser(response.data ?? [])
                if (response.data.role.id && parseInt(response.data.role.id) === 1) {
                    itemsTemp.push({
                        path: '/add',
                        icon: 'fa-solid fa-folder-plus',
                        title: "Configurator",
                    })
                    itemsTemp.push({
                        path: '/acquisitions',
                        icon: 'fa-solid fa-chart-line',
                        title: "Beneficiari"
                    })
                    itemsTemp.push({
                        path: '/sims',
                        icon: 'fa-solid fa-file-word',
                        title: "Declarații SMIS"
                    })
                    itemsTemp.push({
                        icon: "fa-solid fa-file-invoice",
                        title: "Șabloane",
                        path: '/templates'
                    })
                    itemsTemp.push({
                        icon: "fa-solid fa-download",
                        title: "Export",
                        path: '/export'
                    })
                    itemsTemp.push({
                        icon: "fa-solid fa-store",
                        title: "Echipamente și software",
                        path: '/products'
                    })
                    itemsTemp.push({
                        path: '/clients',
                        icon: 'fa-solid fa-landmark',
                        title: "Instituții"
                    })
                    itemsTemp.push({
                        icon: "fa-solid fa-users",
                        title: 'Companii',
                        path: '/companies'
                    })
                } else {
                    if (parseInt(response.data.role.id) === 3) {
                        itemsTemp.push({
                            path: '/acquisitions',
                            icon: 'fa-solid fa-chart-line',
                            title: "Achiziții"
                        })
                        itemsTemp.push({
                            icon: "fa-solid fa-store",
                            title: "Echipamente și software",
                            path: '/products'
                        })
                        itemsTemp.push({
                            path: '/clients',
                            icon: 'fa-solid fa-landmark',
                            title: "Instituții"
                        })
                        itemsTemp.push({
                            icon: "fa-solid fa-users",
                            title: 'Companii',
                            path: '/companies'
                        })
                    }
                }
                setItems(itemsTemp)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return (<LoadingComp/>);
    }
    return (
        <div>
            <div>
                <div className={"icon-sidebar d-flex"}>
                    {
                        window.innerWidth <= 576 ?
                            <div onClick={() => setMenuIsOpen(!menuIsOpen)}>
                                {
                                    menuIsOpen ?
                                        <i className="fa-solid fa-bars"></i>
                                        :
                                        <i className="fa-solid fa-bars"></i>
                                }
                            </div>

                            :
                            <div onClick={() => setSidebarMini(!sidebarMini)}>
                                {
                                    sidebarMini ?
                                        <i className="fa-solid fa-chevron-right"></i>
                                        :
                                        <i className="fa-solid fa-chevron-left"></i>
                                }
                            </div>
                    }


                </div>
            </div>
            <div className={"layout"}>
                <Sidebar
                    sidebarMini={sidebarMini}
                    menuIsOpen={menuIsOpen}
                    setSidebarMini={setSidebarMini}
                    setMenuIsOpen={setMenuIsOpen}
                    items={items}
                    loading={loading}
                    user={user}
                />
                <Outlet/>
            </div>
        </div>
    );
}

