import {BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from "./components/Layout/Layout";
import {useState} from "react";
import {ToastContainer} from 'react-toastify';
import {IntlProvider} from "react-intl";
import LanguageContext from "./contexts/LanguageContext";
import {ro} from "./translations/ro/ro"
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import Login from "./screens/Auth/Login/Login";
import Account from "./screens/Owner/Account/Account";
import EditCompany from "./screens/Owner/Companies/EditCompany";
import Clients from "./screens/Owner/Clients/Clients";
import EditClient from "./screens/Owner/Clients/EditClient";
import moment from "moment";
import 'moment/locale/ro';
import 'moment/locale/en-in';
import * as localeRo from 'moment/locale/ro';
import * as localeEnIn from 'moment/locale/en-in';
import Products from "./screens/Owner/Products/Products";
import AddProduct from "./screens/Owner/Products/AddProduct";
import EditProduct from "./screens/Owner/Products/EditProduct";
import Modal from "react-modal";
import {registerLocale} from "react-datepicker";
import rom from "date-fns/locale/ro";
import * as eng from "date-fns/locale/en-IE";
import AddClient from "./screens/Owner/Clients/AddClient";
import Register from "./screens/Auth/Register/Register";
import Templates from "./screens/Owner/Template/Templates";
import AddTemplate from "./screens/Owner/Template/AddTemplate";
import EditTemplate from "./screens/Owner/Template/EditTemplate";
import Companies from "./screens/Owner/Companies/Companies";
import AddCompany from "./screens/Owner/Companies/AddCompany";
import ProductsMenu from "./screens/Owner/Products/ProductsMenu";
import ProductsCategories from "./screens/Owner/Products/ProductsCategories";
import AddProductsCategory from "./screens/Owner/Products/AddProductsCategory";
import EditProductsCategory from "./screens/Owner/Products/EditProductsCategory";
import Projects from "./screens/Owner/Projects/Projects";
import AddProject from "./screens/Owner/Projects/AddProject/AddProject";
import Details from "./screens/Owner/Projects/EditProject/PNRAS/Pages/Details/Details";
import EditProjectPNRASMenu from "./screens/Owner/Projects/EditProject/PNRAS/EditProjectPNRASMenu";
import Offers from "./screens/Owner/Projects/EditProject/PNRAS/Pages/Offers";
import Appendix14 from "./screens/Owner/Projects/EditProject/PNRAS/Pages/Appendix14";
import GenerateOffer from "./screens/Owner/Projects/EditProject/GenerateOffer";
import ClientsAdmin from "./screens/Admin/Clients/Clients";
import CompaniesAdmin from "./screens/Admin/Companies/Companies";
import ProjectsAdmin from "./screens/Admin/Projects/Projects";
import ViewCompany from "./screens/Admin/Companies/ViewCompany";
import ViewClient from "./screens/Admin/Clients/ViewClient";
import ViewProject from "./screens/Admin/Projects/ViewProject";
import HotLink from "./screens/HotLink/HotLink";
import Acquisitions from "./screens/Owner/Projects/Acquisitions";
import Invoice from "./screens/Owner/Projects/EditProject/PNRAS/Pages/Invoice";
import EditDocument from "./screens/Owner/Projects/EditProject/EditDocument";
import Appendix13 from "./screens/Owner/Projects/EditProject/PNRAS/Pages/Appendix13";
import Appendix26 from "./screens/Owner/Projects/EditProject/PNRAS/Pages/Appendix26";
import Appendix21 from "./screens/Owner/Projects/EditProject/PNRAS/Pages/Appendix21";
import Appendix15 from "./screens/Owner/Projects/EditProject/PNRAS/Pages/Appendix15";
import Appendix16 from "./screens/Owner/Projects/EditProject/PNRAS/Pages/Appendix16";
import Appendix20 from "./screens/Owner/Projects/EditProject/PNRAS/Pages/Appendix20";
import Series from "./screens/Owner/Projects/EditProject/PNRAS/Pages/Series";
import EditOffer from "./screens/Owner/Projects/EditProject/PNRAS/EditOffer";
import TrackProducts from "./screens/Owner/Projects/TrackProducts";
import AddProjectPNRAS from "./screens/Owner/Projects/AddProject/AddProjectPNRAS";
import AddProjectSMARTLAB from "./screens/Owner/Projects/AddProject/AddProjectSMARTLAB";
import EditProjectSMARTLABMenu from "./screens/Owner/Projects/EditProject/SMARTLAB/EditProjectSMARTLABMenu";
import Appendix13Smartlab from "./screens/Owner/Projects/EditProject/SMARTLAB/Pages/Appendix13Smartlab";
import Appendix26Smartlab from "./screens/Owner/Projects/EditProject/SMARTLAB/Pages/Appendix26Smartlab";
import Appendix21Smartlab from "./screens/Owner/Projects/EditProject/SMARTLAB/Pages/Appendix21Smartlab";
import Appendix14Smartlab from "./screens/Owner/Projects/EditProject/SMARTLAB/Pages/Appendix14Smartlab";
import Appendix15Smartlab from "./screens/Owner/Projects/EditProject/SMARTLAB/Pages/Appendix15Smartlab";
import Appendix16Smartlab from "./screens/Owner/Projects/EditProject/SMARTLAB/Pages/Appendix16Smartlab";
import Appendix20Smartlab from "./screens/Owner/Projects/EditProject/SMARTLAB/Pages/Appendix20Smartlab";
import SeriesSmartlab from "./screens/Owner/Projects/EditProject/SMARTLAB/Pages/SeriesSmartlab";
import InvoiceSmartlab from "./screens/Owner/Projects/EditProject/SMARTLAB/Pages/InvoiceSmartlab";
import EstimatedSmartlab from "./screens/Owner/Projects/EditProject/SMARTLAB/Pages/EstimatedSmartlab";
import OffersSmartlab from "./screens/Owner/Projects/EditProject/SMARTLAB/Pages/OffersSmartlab";
import ProductStock from "./screens/Owner/Products/ProductStock";
import EditOfferSmartlab from "./screens/Owner/Projects/EditProject/SMARTLAB/EditOfferSmartlab";
import Export from "./screens/Owner/Export/Export";
import SimsDeclarations from "./screens/Owner/SimsDeclarations/SimsDeclarations";
import AddSimsDeclaration from "./screens/Owner/SimsDeclarations/AddSimsDeclaration";
import EditSimsDeclaration from "./screens/Owner/SimsDeclarations/EditSimsDeclaration";
import DownloadSimsDeclaration from "./screens/Owner/SimsDeclarations/DownloadSimsDeclaration";

Modal.setAppElement('body');

export default function App() {

    const isJwt = localStorage.getItem('jwt') !== null

    const [language, setLanguage] = useState('ro')

    const getLanguageFile = (lang) => {
        switch (lang) {
            case 'ro':
                moment.updateLocale('ro', localeRo);
                registerLocale('ro', rom);
                moment.updateLocale('ro', {
                    week: {
                        dow: 1, // Monday is the first day of the week.
                    }
                });
                return ro
            case 'en':
                moment.updateLocale('en', localeEnIn);
                registerLocale('en', eng);
                return ro
            default:
                return ro
        }
    }

    const changeLanguage = (lang) => {
        setLanguage(lang)
        localStorage.setItem('language', lang)
    }

    const languageContextValue = {
        language: language,
        setLanguage: changeLanguage
    };

    return (
        <LanguageContext.Provider value={languageContextValue}>
            <IntlProvider messages={getLanguageFile(language)} locale={language}>
                <ToastContainer/>
                {
                    !isJwt ?
                        <BrowserRouter>
                            <Routes>
                                <Route exact path="/" element={<Login/>}/>
                                <Route exact path="/hl" element={<HotLink/>}/>
                                <Route exact path="/admin" element={<Login/>}/>
                                <Route exact path="/register" element={<Register/>}/>
                            </Routes>
                        </BrowserRouter>
                        :
                        parseInt(localStorage.getItem('role')) === 3 ?

                            <BrowserRouter>
                                <Routes>

                                    <Route exact path="/hl" element={<HotLink/>}/>
                                    <Route element={<Layout/>}>

                                        <Route path="/clients" element={<ClientsAdmin/>}/>
                                        <Route exact path={"/clients/:id"} element={<ViewClient/>}/>

                                        <Route exact path={"/"} element={<Account/>}/>

                                        <Route exact path={"/companies"} element={<CompaniesAdmin/>}/>
                                        <Route exact path={"/companies/:id"} element={<ViewCompany/>}/>

                                        <Route exact path={"/products"} element={<ProductsMenu/>}>
                                            <Route exact path={"items"} element={<Products/>}/>
                                            <Route exact path={"items/add"} element={<AddProduct/>}/>
                                            <Route exact path={"items/stock/:id"} element={<ProductStock/>}/>
                                            <Route exact path={"items/:id"} element={<EditProduct/>}/>
                                            <Route exact path={"categories"} element={<ProductsCategories/>}/>
                                            <Route exact path={"categories/add"} element={<AddProductsCategory/>}/>
                                            <Route exact path={"categories/:id"} element={<EditProductsCategory/>}/>
                                        </Route>

                                        <Route exact path={"/acquisitions"} element={<Acquisitions/>}/>
                                        <Route exact path={"/acquisitions/:id/projects"} element={<ProjectsAdmin/>}/>
                                          <Route exact path={"/acquisitions/projects/:id"} element={<ViewProject/>}/>

                                    </Route>
                                </Routes>
                            </BrowserRouter>
                            :
                            <BrowserRouter>
                                <Routes>

                                    <Route exact path="/hl" element={<HotLink/>}/>
                                    <Route element={<Layout/>}>

                                        <Route path="/clients" element={<Clients/>}/>
                                        <Route exact path={"/clients/add"} element={<AddClient/>}/>
                                        <Route exact path={"/clients/:id"} element={<EditClient/>}/>

                                        <Route exact path={"/"} element={<Account/>}/>
                                        <Route exact path={"/company"} element={<EditCompany/>}/>

                                        <Route exact path={"/companies"} element={<Companies/>}/>
                                        <Route exact path={"/companies/add"} element={<AddCompany/>}/>
                                        <Route exact path={"/companies/:id"} element={<EditCompany/>}/>
                                        <Route exact path={"/products"} element={<ProductsMenu/>}>
                                            <Route exact path={"items"} element={<Products/>}/>
                                            <Route exact path={"items/add"} element={<AddProduct/>}/>
                                            <Route exact path={"items/stock/:id"} element={<ProductStock/>}/>
                                            <Route exact path={"items/:id"} element={<EditProduct/>}/>
                                            <Route exact path={"categories"} element={<ProductsCategories/>}/>
                                            <Route exact path={"categories/add"} element={<AddProductsCategory/>}/>
                                            <Route exact path={"categories/:id"} element={<EditProductsCategory/>}/>
                                        </Route>
                                        <Route exact path={"/sims"} element={<SimsDeclarations/>}/>
                                        <Route exact path={"/sims/add"} element={<AddSimsDeclaration/>}/>
                                        <Route exact path={"/sims/:id"} element={<EditSimsDeclaration/>}/>
                                        <Route exact path={"/sims/download/:id"} element={<DownloadSimsDeclaration/>}/>


                                        <Route exact path={"/export"} element={<Export/>}/>
                                        <Route exact path={"/templates"} element={<Templates/>}/>
                                        <Route exact path={"/templates/add"} element={<AddTemplate/>}/>
                                        <Route exact path={"/templates/:id"} element={<EditTemplate/>}/>
                                        <Route exact path={"/acquisitions"} element={<Acquisitions/>}/>
                                        <Route exact path={"/acquisitions/:id/projects"} element={<Projects/>}/>
                                        <Route exact path={"/acquisitions/track-products/:id"} element={<TrackProducts/>}/>
                                        <Route exact path={"/add"} element={<AddProject/>}/>
                                        <Route exact path={"/add/pnras/:acquisition"} element={<AddProjectPNRAS/>}/>
                                        <Route exact path={"/add/smartlab/:acquisition"} element={<AddProjectSMARTLAB/>}/>
                                        <Route path="/acquisitions/projects/pnras/:id" element={<EditProjectPNRASMenu/>}>
                                            <Route exact path={""} element={<Details/>}/>
                                            <Route exact path={"appendix-13"} element={<Appendix13/>}/>
                                            <Route exact path={"appendix-26"} element={<Appendix26/>}/>
                                            <Route exact path={"appendix-21"} element={<Appendix21/>}/>
                                            <Route exact path={"offers"} element={<Offers/>}/>
                                            <Route exact path={"offers/:company"} element={<GenerateOffer/>}/>
                                            <Route exact path={"offers/:company/:offer"} element={<EditOffer/>}/>
                                            <Route exact path={"appendix-14"} element={<Appendix14/>}/>
                                            <Route exact path={"appendix-15"} element={<Appendix15/>}/>
                                            <Route exact path={"appendix-16"} element={<Appendix16/>}/>
                                            <Route exact path={"appendix-20"} element={<Appendix20/>}/>
                                            <Route exact path={"series"} element={<Series/>}/>
                                            <Route exact path={"invoice"} element={<Invoice/>}/>
                                            <Route exact path={"documents/:id_document"} element={<EditDocument/>}/>
                                        </Route>
                                        <Route path="/acquisitions/projects/smartlab/:id" element={<EditProjectSMARTLABMenu/>}>
                                            <Route exact path={""} element={<Details/>}/>
                                            <Route exact path={"appendix-13"} element={<Appendix13Smartlab/>}/>
                                            <Route exact path={"estimated"} element={<EstimatedSmartlab/>}/>
                                            <Route exact path={"appendix-26"} element={<Appendix26Smartlab/>}/>
                                            <Route exact path={"appendix-21"} element={<Appendix21Smartlab/>}/>
                                            <Route exact path={"offers"} element={<OffersSmartlab/>}/>
                                            <Route exact path={"offers/:company"} element={<GenerateOffer/>}/>
                                            <Route exact path={"offers/:company/:offer"} element={<EditOfferSmartlab/>}/>
                                            <Route exact path={"appendix-14"} element={<Appendix14Smartlab/>}/>
                                            <Route exact path={"appendix-15"} element={<Appendix15Smartlab/>}/>
                                            <Route exact path={"appendix-16"} element={<Appendix16Smartlab/>}/>
                                            <Route exact path={"appendix-20"} element={<Appendix20Smartlab/>}/>
                                            <Route exact path={"series"} element={<SeriesSmartlab/>}/>
                                            <Route exact path={"invoice"} element={<InvoiceSmartlab/>}/>
                                            <Route exact path={"documents/:id_document"} element={<EditDocument/>}/>
                                        </Route>
                                    </Route>
                                </Routes>
                            </BrowserRouter>


                }
            </IntlProvider>
        </LanguageContext.Provider>
    )
}


