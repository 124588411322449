import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import {Editor} from "@tinymce/tinymce-react";

export default function EditDocument() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const editorRef = useRef(null);

    const [initContent, setInitContent] = useState('')
    const [content, setContent] = useState("")
    const [loading, setLoading] = useState(true)

    const [saveLoading, setSaveLoading] = useState(false)
    const [draft, setDraft] = useState(true)
    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/documents/' + params.id_document, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setContent(response.data.content)
                    setInitContent(response.data.content)
                    setLoading(false)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (e) => {
        e.preventDefault();

        setSaveLoading(true)

        let payload = {
            content: content !== '' ? JSON.stringify(content) : JSON.stringify(initContent),
            status: draft ? 'draft' : 'generated'
        }
        await axios.put(process.env.REACT_APP_API + '/documents/' + params.id_document, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success("Informațiile au fost salvate cu succes!")
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">

            <div className={"header"}><p>Editează documentul</p></div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>Conținut<span
                                    className={"form-required"}>*</span></div>
                                <Editor
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    apiKey='1rb156cyg9vg206vb6cdpcvzrnh4iiwcewgc0owv61j1t8ev'
                                    initialValue={initContent}
                                    onEditorChange={setContent}
                                    init={{
                                        menubar: false,
                                        selector: 'textarea',  // change this value according to your HTML
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'searchreplace', 'tabfocus', 'code'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | image link media | searchreplace | code |' +
                                            'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | ' +
                                            'help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-9"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={"Înapoi"}
                                onClick={() => navigate(-1)}
                            />

                            <Button
                                className={"btn-light mt-2 ms-3"}
                                icon={"fa-regular fa-floppy-disk"}
                                value={"Salvează ca draft"}
                                disabled={saveLoading}
                                onClick={() => setDraft(true)}
                                //  loading={saveLoading}
                                type={'submit'}
                            />
                        </div>
                        <div className={"col-12 col-md-3 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-regular fa-floppy-disk"}
                                value={"Salvează"}
                                disabled={saveLoading}
                                onClick={() => setDraft(false)}
                                //  loading={saveLoading}
                                type={'submit'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

