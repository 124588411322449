import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import {useParams} from "react-router-dom";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import ModalComp from "../../../../../../components/ModalComp/ModalComp";
import Selector from "../../../../../../components/elements/Selector/Selector";
import {toast} from "react-toastify";

export default function Invoice() {

    const intl = useIntl();
    let params = useParams();
    const jwt = localStorage.getItem('jwt')

    const [project, setProject] = useState([])

    const [open, setOpen] = useState(false);
    const [series, setSeries] = useState('');
    const [seriesChit, setSeriesChit] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true)
    const [loadingGenerate, setLoadingGenerate] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalIsOpenCollect, setModalIsOpenCollect] = useState(false)
    const [collect, setCollect] = useState('')
    const [docNr, setDocNr] = useState(0);

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/projects/' + +params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setProject(response.data ?? [])
                if(response.data.winning_offer.company.access_token_oblio)
                    getSeries()
                else
                    setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getSeries = async () => {
        axios.get(process.env.REACT_APP_API + '/projects/invoices-series/' + +params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = [];
                if (response.data.status === 0){

                    toast.error(response.data.error)
                }
                response.data && response.data.data && response.data.data.forEach((item) => {
                    temp.push({
                        value: item.name,
                        label: item.name,
                        type: item.type
                    })

                })
                setOptions(temp)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const generateInvoice = async (e) => {
        e.preventDefault();
        setLoadingGenerate(true)
        axios.post(process.env.REACT_APP_API + '/projects/generate-invoice/' + params.id, {series}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1 || response.data.status === 200) {
                    getData()
                } else {
                    toast.error(response.data.statusMessage)
                }
                setModalIsOpen(false)
                setLoadingGenerate(false)
            })
            .catch(err => {
                setModalIsOpen(false)
                errorParser(err, intl)
                setLoadingGenerate(false)
            })
    }
    const cancel = async (id) => {
        setLoadingGenerate(true)
        axios.get(process.env.REACT_APP_API + '/invoices/cancel/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {

                if (response.data.status === 1 || response.data.status === 200) {
                    getData()
                } else {
                    toast.error(response.data.error)
                }
                setModalIsOpen(false)
                setLoadingGenerate(false)
            })
            .catch(err => {
                setModalIsOpen(false)
                errorParser(err, intl)
                setLoadingGenerate(false)
            })
    }
    const collectInvoice = async (e) => {
        e.preventDefault()
        setLoadingGenerate(true)
        axios.post(process.env.REACT_APP_API + '/invoices/collect/' + project.invoices[0].id, {collect, number: docNr, series: seriesChit}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1 || response.data.status === 200) {
                    getData()
                } else {
                    toast.error(response.data.error)
                }
                setModalIsOpenCollect(false)
                setLoadingGenerate(false)
            })
            .catch(err => {
                setModalIsOpenCollect(false)
                errorParser(err, intl)
                setLoadingGenerate(false)
            })
    }

    const deleteInvoice = async (id) => {
        setLoadingGenerate(true)
        axios.get(process.env.REACT_APP_API + '/invoices/delete/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1 || response.data.status === 200) {
                    getData()
                } else {
                    toast.error(response.data.error)
                }
                setModalIsOpen(false)
                setLoadingGenerate(false)
            })
            .catch(err => {
                setModalIsOpen(false)
                errorParser(err, intl)
                setLoadingGenerate(false)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }
    return (<div className="card" style={{position: 'relative'}}>


            <div className={"header"}><p>Facturare</p></div>
            <div className={"content"}>

                {
                    project.invoices.length > 0 ?

                        <div className={"table mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div className={"col-6 table-td"}>Factura</div>
                                    <div className={"col-3 table-td"}>Total</div>
                                    <div className={"col-3 table-td"}></div>
                                </div>
                            </div>

                            <div className={"table-body"}>
                                <div className={"table-row"}>
                                    {project.invoices.map((invoice, i) => (
                                        <div className={"row"} key={i}>
                                            <div
                                                className={"col-6 table-td"}>
                                                {invoice.series} {invoice.number}
                                                {
                                                    invoice.status === 'collected' ?
                                                        <div className={"alert alert-success p-2 ms-3"}>Încasată</div>
                                                        :
                                                        invoice.status === 'canceled' ?
                                                            <div className={"alert alert-danger p-2 ms-3"}>Anulată</div>
                                                            :
                                                            ''
                                                }
                                            </div>
                                            <div
                                                className={"col-3 table-td"}>
                                                {invoice.total} Lei
                                            </div>
                                            <div className={"col-3 table-td"}>


                                                <div className={"ms-auto"}>
                                                    <button type="button" className="btn btn-light btn-edit ms-auto" onClick={() => setOpen(!open)}>
                                                        <i className="fa-solid fa-ellipsis-vertical"></i></button>
                                                    <div className={"dropdown " + (open ? 'show' : 'hide')}>
                                                        <a
                                                            className={"btn-primary ms-auto"}
                                                            target={"_blank"}
                                                            rel={"noreferrer"}
                                                            href={invoice.link}
                                                        >
                                                            <button type="button" className="btn btn-light btn-edit ms-auto">Descarcă</button>
                                                        </a>

                                                        {
                                                            invoice.status !== 'canceled' && invoice.status !== 'collected' && <button type="button" className="btn btn-light btn-edit ms-auto"
                                                                                                     onClick={() => cancel(invoice.id)}>
                                                                Anulează
                                                            </button>
                                                        }
                                                        {invoice.status !== 'collected' && <button type="button" className="btn btn-light btn-edit ms-auto"
                                                                                                   onClick={() => deleteInvoice(invoice.id)}>
                                                            Șterge
                                                        </button>
                                                        }
                                                        {
                                                            invoice.status === 'new' && <button type="button" className="btn btn-light btn-edit ms-auto"
                                                                                                onClick={() => setModalIsOpenCollect(true)}>
                                                                Încasează
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                </div>
                            </div>
                        </div>
                        :
                        !project.winning_offer.company.access_token_oblio ?
                            <div className={"alert alert-warning"}>Nu este configurată facturarea pentru această companie, mergeți în pagina companiei și adaugați datele pentru
                                integrarea cu oblio!</div>
                            :
                            options.length === 0 ?
                                <div className={"alert alert-warning"}>Nu puteți genera factura pentru că nu există serii de factură în contul oblio!</div>
                                :
                                <div className={"d-flex"}>
                                    <Button
                                        className={"btn-primary ms-auto"}
                                        value={"Generează factura"}
                                        onClick={() => {
                                            setModalIsOpen(true)
                                        }}
                                    />
                                </div>
                }

            </div>


            <div className={"footer d-flex justify-content-center"}>

            </div>
            <ModalComp
                title={'Generează factura'}
                isOpen={modalIsOpen}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalIsOpen(false)}
            >
                <form onSubmit={(e) => generateInvoice(e)}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <div>
                            <div
                                className={"form-label"}>Serie factura<span
                                className={"form-required"}>*</span></div>
                            <Selector
                                options={[...options.filter((opt) => opt.type === 'Factura')]}
                                required={true}
                                onChange={(option) => {
                                    setSeries(option.value)
                                }}
                                isSearchable={false}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    loading={loadingGenerate}
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={'Generează'}
                                    disabled={loadingGenerate}
                                    type={'submit'}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
            <ModalComp
                title={'Incasează factura'}
                isOpen={modalIsOpenCollect}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalIsOpenCollect(false)}
            >
                <form onSubmit={(e) => collectInvoice(e)}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <div>
                            <div
                                className={"form-label"}>Tipul de document<span
                                className={"form-required"}>*</span></div>
                            <Selector
                                options={[{
                                    value: 'Chitanta',
                                    label: 'Chitanta'
                                }, {
                                    value: 'Bon fiscal',
                                    label: 'Bon fiscal'
                                }, {
                                    value: 'Alta incasare numerar',
                                    label: 'Alta incasare numerar'
                                }, {
                                    value: 'Ordin de plata',
                                    label: 'Ordin de plata'
                                }, {
                                    value: 'Mandat postal',
                                    label: 'Mandat postal'
                                }, {
                                    value: 'Card',
                                    label: 'Card'
                                }, {
                                    value: 'CEC',
                                    label: 'CEC'
                                }, {
                                    value: 'Bilet ordin',
                                    label: 'Bilet ordin'
                                }, {
                                    value: 'Alta incasare banca',
                                    label: 'Alta incasare banca'
                                }]}
                                required={true}
                                value={collect}
                                onChange={(option) => {
                                    setCollect(option.value)
                                }}
                                isSearchable={false}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                        {collect === 'Chitanta' && options.filter((opt) => opt.type === 'Chitanta').length === 0 &&
                            <div className={"alert alert-warning mt-2"}>Nu puteți încasa factura pentru că nu există serii de chitanță în contul oblio!</div>
                        }
                        {collect === 'Chitanta' ?
                            <div>
                                <div
                                    className={"form-label"}>Serie chitanta<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={[...options.filter((opt) => opt.type === 'Chitanta')]}
                                    required={true}
                                    onChange={(option) => {
                                        setSeriesChit(option.value)
                                    }}
                                    isSearchable={false}
                                />
                            </div>
                            :

                            <div>
                            <div
                            className={"form-label"}>Număr document<span
                            className={"form-required"}>*</span></div>

                            <input type={"text"} className={"form-input"} id={"sdfdsdsf"}
                            required={true}
                            onChange={(e) => setDocNr(e.target.value)}/>
                            </div>
                        }

                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    loading={loadingGenerate}
                                    disabled={loadingGenerate}
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={'Generează'}
                                    type={'submit'}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
        </div>

    );
}

