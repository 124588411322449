import Button from "../../../../components/elements/Button/Button";
import React, {useEffect, useState} from "react";
import LineItems from "../../../../components/elements/LineItems/LineItems";
import '../Projects.scss';

export default function OtherRequirementsItem({otherRequirements, itemBack,setItem, index, categories}) {


    const [search, setSearch] = useState('')
    const [category, setCategory] = useState('')
    const [profFind, setProdFind] = useState(0)
    const [update, setUpdate] = useState(0)
    const [requirements, setRequirements] = useState(itemBack.requirements)
    const [totalValue, setTotalValue] = useState(itemBack.totalValue)
    const [title, setTitle] = useState(itemBack.title)

    useEffect(() => {
        let temp = itemBack
        temp.totalValue = totalValue
        temp.requirements = requirements
        temp.title = title
        setItem(temp)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, requirements, totalValue, update])
    const getProdForCustopm = (item, currentIndex) => {
        let content = []
        content.push(<div></div>)
        if (item.value === category)
            item.products.forEach((product, j) => {
                if (product.hided !== true)
                    content.push(<div className={"d-flex ps-5 pe-3 pb-2"}>
                        <div style={{
                            cursor: 'pointer',
                            color: profFind === product.id ? '#3F8CFF' : ''
                        }}
                             onClick={() => {
                                 let temp = requirements
                                 temp.push({
                                     price: product.price_request,
                                     price_request: item.price_request,
                                     id: item.id,
                                     value: item.value,
                                     label: item.label,
                                     product: product.id,
                                     product_request: product.id,
                                     guarantee: product.guarantee_request,
                                     qty: 1
                                 })
                                 setUpdate(update+1)
                                 setRequirements(temp)
                                 setTotalValue(parseFloat(parseFloat(totalValue + (product.price_request ?? 0)).toFixed(2)))
                             }}>
                            {j + 1}. {product.title} ({product.price_request} Lei) {product.part_number ? (' - ' + product.part_number) : ''}
                        </div>

                    </div>)
            })
        return content
    }

    return (
        <div className={"row"} key={index + '_' + requirements.length}>
            <div className={"col-md-12 col-lg-4 col-xl-3 pb-4"}>
                <div className={"secondary-navigation"}>
                    <div className={"item"}>
                        <input type={"text"} className={"form-input"}
                               onFocus={(event) => event.target.select()}
                               placeholder={"Caută"}
                               value={search} onChange={(e) => {
                            setSearch(e.target.value)
                            if (e.target.value.length >= 3) {
                                for (let i = 0; i < categories.length; i++) {
                                    for (let j = 0; j < categories[i].products.length; j++) {
                                        if (categories[i].products[j].title.toLowerCase().includes(e.target.value.toLowerCase()) ||
                                            categories[i].products[j].part_number?.toLowerCase().includes(e.target.value.toLowerCase())) {
                                            setCategory(categories[i].id)
                                            setProdFind(categories[i].products[j].id)
                                        }
                                    }
                                }
                            }

                        }}/>
                    </div>

                    {
                        categories.length ? categories.filter(category => category.products.filter(it => it.hided !== true).length > 0).map((product, i) => (
                                <div key={i}>
                                    <div
                                        className={"item d-block " + (product.value === category && 'active')}
                                        key={i}
                                        style={{cursor: 'pointer'}}
                                        onClick={() => {
                                            if (product.value === category) {
                                                setCategory(0)
                                                setProdFind(0)
                                            } else
                                                setCategory(product.value)
                                        }}>
                                        <div className={"title d-flex"}
                                             style={{width: '-webkit-fill-available'}}>
                                            {product.label} <i
                                            className={"ms-auto " + (product.products && product.value === category ? 'fa-solid fa-caret-up' : product.products ? 'fa-solid fa-caret-down' : null)}></i>
                                        </div>

                                    </div>
                                    {getProdForCustopm(product, i)}
                                </div>
                            ))
                            :
                            <div>
                                <div className={"alert alert-warning ms-4 me-4"}>Momentan nu aveți
                                    nici un produs
                                    adaugat in acesta categorie de cheltuieli!
                                </div>
                            </div>
                    }
                </div>
            </div>
            <div className={"col-md-12 col-lg-8 col-xl-9 pb-4"}>

                <div className="card">

                    <div className={"header"}>
                        <input
                            defaultValue={title}
                            onChange={(e) => {
                                setTitle(e.target.value)
                            }}
                            className={"form-input mt-3"}/></div>

                    <div className={"content"}>
                        <div className={"row"}>

                            <LineItems
                                items={requirements}
                                setItems={setRequirements}
                                setTotalValue={setTotalValue}
                                totalValue={totalValue}
                            />

                            <div className={"d-flex pb-2 mt-2"}>
                                <Button
                                    className={"btn-secondary"}
                                    size={"small"}
                                    value={"Adaugă produs personalizat"}
                                    style={{cursor: 'pointer'}} onClick={() => {
                                    let temp = requirements
                                    temp.push({
                                        price: 0,
                                        price_request: 0,
                                        id: null,
                                        value: null,
                                        label: '',
                                        product: null,
                                        guarantee: 36,
                                        qty: 1
                                    })
                                    setUpdate(update+1)
                                    setRequirements(temp)
                                }}>

                                </Button>

                            </div>
                            {
                                requirements.length > 0 &&
                                <strong className={"mb-4"}>Valoarea
                                    totală: {parseFloat(totalValue).toLocaleString('ro-RO')} RON</strong>
                            }

                        </div>
                    </div>

                </div>
            </div>
        </div>


    );
}

