import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import ModalComp from "../../../../../../components/ModalComp/ModalComp";
import moment from "moment/moment";
import {deleteDocument, downloadDoc, getProjectInfo} from "../../../../../../utils/project_functions";

export default function OffersSmartlab() {

    const intl = useIntl();
    let params = useParams();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [update, setUpdate] = useOutletContext();
    const [project, setProject] = useState([])

    const [loading, setLoading] = useState(true)
    const [loadingDownload, setLoadingDownload] = useState([])
    const [docSelected, setDocSelected] = useState(0)
    const [docNumber, setDocNumber] = useState(0)
    const [compId, setCompId] = useState(0)
    const [docDate, setDocDate] = useState('')
    const [modalDocIsOpen, setModalDocIsOpen] = useState(false)

    useEffect(() => {
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const init = async () => {
        let proj = await getProjectInfo(params.id, intl)
        setProject(proj)
        setLoading(false)
    }
    if (loading) {
        return <LoadingComp/>
    }

    const editDoc = (e) => {
        e.preventDefault()
        axios.post(process.env.REACT_APP_API + '/projects/update-doc/' + params.id, {document: docSelected, number: docNumber, date: docDate}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {

                setModalDocIsOpen(false)
                navigate('/acquisitions/projects/smartlab/' + params.id + '/offers/' + compId)
                let proj = await getProjectInfo(params.id, intl)
                setProject(proj)

            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    return (<div className="card">


            <div className={"header"}><p>Oferte</p></div>


            <div className={"content p-0"}>
                <div className={"table mb-1"}>

                    <div className={"table-head"} style={{minWidth: '900px'}}>
                        <div className={"row"}>
                            <div className={"col-2 table-td"}>Denumire</div>
                            <div className={"col-2 table-td"}>Status</div>
                            <div className={"col-3 table-td"}>Furnizor</div>
                            <div className={"col-1 table-td"}>Dată generare</div>
                            <div className={"col-1 table-td"}>Dată descărcare</div>
                            <div className={"col-3 table-td"}></div>
                        </div>
                    </div>

                    <div className={"table-body"} style={{minWidth: '900px'}}>
                        <div className={"table-row"}>
                            {project.companies.map((company, i) => (
                                <div className={"row"} key={i}>
                                    <div
                                        className={"col-2 table-td"}>
                                        Ofertă
                                    </div>

                                    <div
                                        className={"col-2 table-td"}>
                                        <div
                                            className={"p-2 alert " + (project.documents.filter((document) => document.type === 'offer' && document.company === company.id).length !== 0 &&
                                            project.documents.find((document) => document.type === 'offer' && document.company === company.id).status === 'generated' ? 'alert-success' : 'alert-danger')}>
                                            {project.documents.find((document) => document.type === 'offer' && document.company === company.id) ?
                                                (project.documents.find((document) => document.type === 'offer' && document.company === company.id).status === 'draft' ? 'Draft' : 'Generat') : 'Negenerat'}
                                        </div>
                                    </div>

                                    <div
                                        className={"col-3 table-td"}>
                                        {company.title}
                                    </div>
                                    <div
                                        className={"col-1 table-td"}>
                                        {project.documents.find((document) => document.type === 'offer' && document.company === company.id) &&
                                            moment(project.documents.find((document) => document.type === 'offer' && document.company === company.id).created_at).format('DD/MM HH:mm')}
                                    </div>
                                    <div
                                        className={"col-1 table-td"}>
                                        {project.documents.find((document) => document.type === 'offer' && document.company === company.id) &&
                                            project.documents.find((document) => document.type === 'offer' && document.company === company.id).downloaded_at &&
                                            moment(project.documents.find((document) => document.type === 'offer' && document.company === company.id).downloaded_at).format('DD/MM HH:mm')}
                                    </div>
                                    <div className={"col-3 table-td"}>
                                        {project.documents.filter((document) => document.type === 'offer' && document.company === company.id).length > 0 ?
                                            <>

                                                {project.invoices.length === 0 && <Button
                                                    className={"btn-light btn-edit ms-auto"}
                                                    icon={"fa-solid fa-pen"}
                                                    onClick={() => {
                                                        navigate('/acquisitions/projects/smartlab/' + params.id + '/offers/' + company.id + '/' +
                                                            project.documents.find((document) => document.type === 'offer' && document.company === company.id).offer)
                                                    }}
                                                />}
                                                {
                                                project.documents.find((document) => document.type === 'offer' && document.company === company.id).status === 'draft' ?
                                                    <>
                                                        {/*<Button
                                                            className={"btn-light btn-edit ms-auto"}
                                                            icon={"fa-solid fa-pen"}
                                                            onClick={() => {
                                                                navigate('/acquisitions/projects/smartlab/' + params.id + '/documents/' +
                                                                    project.documents.find((document) => document.type === 'offer' && document.company === company.id).id)
                                                            }}
                                                        />*/}
                                                        <Button
                                                            className={"btn-light btn-delete ms-auto"}
                                                            icon={"fa-solid fa-trash"}
                                                            onClick={async () => {
                                                                if (await deleteDocument(project.documents.find((document) => document.type === 'offer' && document.company === company.id).id, intl) === 1) {
                                                                    let project = await getProjectInfo(params.id, intl)
                                                                    setProject(project)
                                                                    setUpdate(update + 1)
                                                                }
                                                            }}
                                                        /></>
                                                    :

                                                    <Button
                                                        className={"btn-success ms-auto"}
                                                        value={"Descarcă"}
                                                        loading={loadingDownload && loadingDownload[i]}
                                                        onClick={() => {

                                                            let arr = {}
                                                            arr[i] = true
                                                            setLoadingDownload(arr)
                                                            downloadDoc('Ofertă-' + company.title, project.documents.find((document) => document.type === 'offer' && document.company === company.id).id, setLoadingDownload, false)
                                                        }}
                                                    />

                                            }
                                            </>
                                            :
                                            <Button
                                                className={"btn-primary ms-auto"}
                                                value={"Generează"}
                                                onClick={() => {
                                                    let doc = project.documents.find((doc) => doc.type === 'appendix_21' && doc.company === company.id)

                                                    if (doc.number && doc.date) {
                                                        navigate('/acquisitions/projects/smartlab/' + params.id + '/offers/' + company.id)
                                                    } else {
                                                        setCompId(company.id)
                                                        setDocNumber(doc.number)
                                                        setDocDate(doc.date)
                                                        setDocSelected(doc.id)
                                                        setModalDocIsOpen(true)
                                                    }
                                                }}
                                            />}
                                    </div>
                                </div>))}
                        </div>
                    </div>
                </div>
            </div>


            <div className={"footer d-flex justify-content-center"}>

            </div>

            <ModalComp
                title={'Editare cerere '}
                isOpen={modalDocIsOpen}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalDocIsOpen(false)}
            >
                <form onSubmit={(e) => editDoc(e)}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <div className={"alert alert-info mb-3"}>Înainte de generarea ofertei trebuie sa adăugati numărul și data cererii de ofertă</div>
                        <div>
                            <div
                                className={"form-label"}>Numar<span
                                className={"form-required"}>*</span></div>
                            <input type={"text"} className={"form-input"}
                                   value={docNumber} required
                                   onChange={(e) => setDocNumber(e.target.value)}
                            />
                        </div>
                        <div>
                            <div
                                className={"form-label"}>Data<span
                                className={"form-required"}>*</span></div>
                            <input type={"date"} className={"form-input"}
                                   value={docDate} required
                                   onChange={(e) => setDocDate(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={'Salvează'}
                                    type={'submit'}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
        </div>

    );
}

