import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import Selector from "../../../../../components/elements/Selector/Selector";
import LineItemsOffer from "../../../../../components/elements/LineItemsOffer/LineItemsOffer";
import ModalComp from "../../../../../components/ModalComp/ModalComp";
import AddProduct from "../../../Products/AddProduct";
import AddProductsCategory from "../../../Products/AddProductsCategory";

export default function EditOfferSmartlab() {

    const intl = useIntl();
    const navigate = useNavigate()
    let params = useParams();
    const jwt = localStorage.getItem('jwt')

    const [templates, setTemplates] = useState([])
    const [template, setTemplate] = useState('')
    const [type, setType] = useState('')
    const [companyTitle, setCompanyTitle] = useState('')
    const [deliveryTime, setDeliveryTime] = useState(30)
    const [offerValidity, setOfferValidity] = useState(60)
    const [totalValue, setTotalValue] = useState(0)
    const [totalValueTva, setTotalValueTva] = useState(0)
    const [products, setProducts] = useState([])
    const [productsSelected, setProductsSelected] = useState([])

    const [loading, setLoading] = useState(true)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [draft, setDraft] = useState(false)
    const [showAddProductModal, setShowAddProductModal] = useState(false)
    const [showAddCategoryModal, setShowAddCategoryModal] = useState(false)

    useEffect(() => {
        getTemplates()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (showAddProductModal === false)
            getProducts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showAddProductModal])

    const getData = async (prods) => {
        axios.get(process.env.REACT_APP_API + '/offers/' + params.offer, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setType(response.data.project.type)
                setCompanyTitle(response.data.company.title)

                setTotalValue(response.data.total)
                setProductsSelected(response.data.products)
                let temp =0;
                response.data.products.forEach((item) => {
                    temp = (parseFloat(temp) + parseFloat(item.qty * item.price * 0.19)).toFixed(2)
                })
                setTotalValueTva(temp)
                setTemplate(response.data.document.template)
                setLoading(false)

            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getTemplates = async () => {
        axios.get(process.env.REACT_APP_API + '/templates/list/0', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                response.data.templates.forEach((t) => {
                    if (t.type === 'offer')
                        temp.push({
                            value: t.id, label: t.title, type: t.type,
                        })
                })
                setTemplates(temp)
                getProducts()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getProducts = async () => {
        axios.post(process.env.REACT_APP_API + '/products/list/0', {}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []

                response.data.products && response.data.products.forEach((item) => {
                    temp.push({
                        value: item.id,
                        label: item.title,
                        main_description_request: item.main_description_request,
                        second_description_request: item.second_description_request,
                        price: item.price,
                        type: item.type,
                        guarantee: item.guarantee,
                        category: item.product_category,
                        product_to_companies: item.product_to_companies
                    })
                })

                setProducts(temp)
                getData(temp)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const save = async (e) => {
        setSaveLoading(true)
        e.preventDefault();
        if (template === '') {
            toast.error('Nu este selectat șablonul!')
            setSaveLoading(false)
            return

        }

        for (let i = 0; i < productsSelected.length; i++) {
            if (!productsSelected[i].price || productsSelected[i].price === 0) {
                toast.error('Adăugați prețul pentru produsele ofertate!')
                setSaveLoading(false)
                return
            }
            if (!productsSelected[i].guarantee || productsSelected[i].guarantee === 0) {
                toast.error('Adăugați garanția pentru produsele ofertate!')
                setSaveLoading(false)
                return
            }
            if (!productsSelected[i].product || productsSelected[i].product === null) {
                toast.error('Selectați produsele ofertate!')
                setSaveLoading(false)
                return
            }
        }

        let payload = {
            template,
            company: params.company,
            project: params.id,
            delivery_time: deliveryTime,
            validity: offerValidity,
            total: totalValue,
            products: JSON.stringify(productsSelected),
            status: draft ? 'draft' : 'generated'
        }

        fetch(process.env.REACT_APP_API + '/offers/' + params.offer, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
        })
            .then(response => response.blob())
            .then(async blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = 'Ofertă-' + companyTitle + '.docx';
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again

                toast.success("Informațiile au fost salvate cu succes!")
                setModalIsOpen(false)
                setSaveLoading(false)
                navigate(-1)
                window.location.href = '/acquisitions/projects/smartlab/' + params.id

            })
            .catch(e => console.log(e))

    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">

            <div className={"header"}><p className={"d-flex"}>Ofertă: {companyTitle}

                <Button
                    className={"btn-primary mt-0 ms-auto"}
                    value={"Adaugă produs nou"}
                    onClick={() => setShowAddProductModal(true)}
                    type={'button'}
                />
                <Button
                    className={"btn-primary mt-0 ms-3"}
                    value={"Adaugă categorie de produse nouă"}
                    onClick={() => setShowAddCategoryModal(true)}
                    type={'button'}
                />

            </p></div>

            <form onSubmit={(e) => {
                e.preventDefault()
             setModalIsOpen(true)
            }}>
                <div className={"content"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>Șablon<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={templates}
                                    value={template}
                                    required={true}
                                    onChange={(option) => setTemplate(option.value)}
                                    isSearchable={false}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                        <div className={"col-12 col-md-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>Termen livrare (zile)<span
                                    className={"form-required"}>*</span></div>
                                <input type={"number"} step={1} className={"form-input"} required
                                       onFocus={(event) => event.target.select()}
                                       value={deliveryTime} onChange={(e) => setDeliveryTime(e.target.value)}/>
                            </div>
                        </div>
                        <div className={"col-12 col-md-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>Valabilitate ofertă (zile)<span
                                    className={"form-required"}>*</span></div>
                                <input type={"number"} step={1} className={"form-input"} required
                                       onFocus={(event) => event.target.select()}
                                       value={offerValidity} onChange={(e) => setOfferValidity(e.target.value)}/>
                            </div>
                        </div>

                        <LineItemsOffer
                            items={productsSelected}
                            setItems={setProductsSelected}
                            setTotal={setTotalValue}
                            setTotalValueTva={setTotalValueTva}
                            total={totalValue}
                            company={parseInt(params.company)}
                            products={products.filter((p) => p.type === type)}
                        />

                        {
                            productsSelected.length > 0 &&
                            <strong className={"mb-4"}>Valoarea totală: {parseFloat(totalValue).toLocaleString('ro-RO')} RON</strong>
                        }
                        {
                            productsSelected.length > 0 &&
                            <strong className={"mb-4"}>Valoarea TVA: {parseFloat(totalValueTva).toFixed(2).toLocaleString('ro-RO')} RON</strong>
                        }
                        {
                            productsSelected.length > 0 &&
                            <strong className={"mb-4"}>Total: {parseFloat(parseFloat(totalValue) + parseFloat(totalValueTva)).toFixed(2).toLocaleString('ro-RO')} RON</strong>
                        }


                        <div className={"col-12 col-md-12"}>
                            <div
                                className={"form-label mt-3 me-2"}>

                                <input type={"checkbox"} checked={draft} onChange={(e) => setDraft(!draft)}/>
                                Salvează ca draft
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={"Înapoi"}
                                onClick={() => navigate(-1)}
                            />
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                value={"Generează"}
                                disabled={saveLoading}
                                loading={saveLoading}
                                type={'submit'}
                            />
                        </div>
                    </div>
                </div>
            </form>

            <ModalComp
                title={"Adaugă categorie nouă"}
                isOpen={showAddProductModal}
                className={"sign-contract add-prod"}
                close={true}
                onRequestClose={() => setShowAddProductModal(false)}
            >
                <AddProduct showHeader={false} setShowAddProductModal={setShowAddProductModal}></AddProduct>

            </ModalComp>
            <ModalComp
                title={"Adaugă categorie nouă"}
                isOpen={showAddCategoryModal}
                className={"sign-contract add-prod"}
                close={true}
                onRequestClose={() => setShowAddCategoryModal(false)}
            >
                <AddProductsCategory showHeader={false} setShowAddCategoryModal={setShowAddCategoryModal}/>

            </ModalComp>

            <ModalComp
                title={'Regenerare ofertă'}
                isOpen={modalIsOpen}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalIsOpen(false)}
            >
                <form onSubmit={(e) => save(e)}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <p>Dacă vei regenera oferta vor se vor șterge toate documentele generate după generarea ofertei!</p>
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={'Regenerează'}
                                    type={'submit'}
                                    disabled={saveLoading}
                                    loading={saveLoading}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
        </div>

    );
}

