import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import Selector from "../../../components/elements/Selector/Selector";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import ModalAddProductToSims from "./ModalAddProductToSims";

export default function EditSimsDeclaration() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')
    let params = useParams();

    const [clients, setClients] = useState([])
    const [companies, setCompanies] = useState([])

    const [client, setClient] = useState(null)
    const [company, setCompany] = useState(null)
    const [projectTitle, setProjectTitle] = useState('')
    const [projecCode, setProjecCode] = useState('')
    const [date, setDate] = useState(new Date())
    const [lot, setLot] = useState(1)
    const [products, setProducts] = useState([])
    const [productsOptions, setProductsOptions] = useState([])

    const [loading, setLoading] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)

    const [showModalProduct, setShowModalProduct] = useState(false)
    const [indexProduct, setIndexProduct] = useState(null)
    const [productSelected, setProductSelected] = useState(null)
    const [title, setTitle] = useState('')
    const [guarantee, setGuarantee] = useState(0)

    useEffect(() => {
        getClients()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getClients = async () => {
        axios.post(process.env.REACT_APP_API + '/clients/list/0',
            {}, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
            .then(async (response) => {
                let temp = [];
                response.data.clients.forEach((client) => {
                    temp.push({
                        value: client.id,
                        label: client.company_title,
                        projectTitle: client.projectTitle,
                        number_financing_contract: client.number_financing_contract,
                        dateFinancingContract: client.dateFinancingContract
                    })
                })
                setClients(temp)
                getCompanies()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getCompanies = async () => {
        axios.get(process.env.REACT_APP_API + '/companies', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                response.data.forEach(item => {
                    temp.push({
                        value: item.id,
                        label: item.title
                    })
                })
                setCompanies(temp)
                getProducts()
            })
            .catch(err => {
                errorParser(err)
            })
    }
    const getProducts = async () => {
        axios.post(process.env.REACT_APP_API + '/products/list/0', {
            project_type: 'smartlab_smis'
        }, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i=0; i < response.data.products.length; i++){
                    temp.push({
                        value: response.data.products[i].id,
                        label: response.data.products[i].title,
                        guarantee: response.data.products[i].guarantee_request,
                    })
                }
                setProductsOptions(temp)
                getData()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/smartlab-sims-declarations/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if(response.data){
                    setClient(response.data.client)
                    setCompany(response.data.company)
                    setProjectTitle(response.data.project_title)
                    setProjecCode(response.data.project_code)
                    setDate(response.data.date)
                    setLot(response.data.lot)
                    setProducts(response.data.products)
                    setLoading(false)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const save = async (e) => {
        e.preventDefault();
        if (client === null || company === null || projectTitle === '' || projecCode === '' || products.length === 0){
            toast.error('Vă rugăm să completați toate câmpurile!')
            return
        }

        setSaveLoading(true)

        let payload = {
            client,
            company,
            project_title: projectTitle,
            project_code: projecCode,
            date: new Date(date).toISOString(),
            lot: lot,
            products
        }
        await axios.put(process.env.REACT_APP_API + '/smartlab-sims-declarations/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success("Informațiile au fost salvate cu succes!")
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })

        setSaveLoading(false)
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>Declarații SMIS</h1>
                </div>

                <div className={"col-12 pb-4"}>

                    <div className="card">

                        <div className={"header"}><p>Editare proiect</p></div>

                        <form onSubmit={(e) => save(e)}>
                            <div className={"content"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Instituție<span
                                                className={"form-required"}>*</span></div>
                                            <Selector
                                                options={clients}
                                                value={client}
                                                key={client}
                                                onChange={(option) => setClient(option.value)}
                                                isSearchable={false}
                                                isOptionDisabled={(option) => option.disabled}
                                            />
                                        </div>
                                    </div>
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Companie<span
                                                className={"form-required"}>*</span></div>
                                            <Selector
                                                options={companies}
                                                value={company}
                                                key={company}
                                                onChange={(option) => setCompany(option.value)}
                                                isSearchable={false}
                                                isOptionDisabled={(option) => option.disabled}
                                            />
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-6"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Titlu proiect<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required
                                                   value={projectTitle} onChange={(e) => setProjectTitle(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-6"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Cod proiect<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required
                                                   value={projecCode} onChange={(e) => setProjecCode(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-6"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Data<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"date"} className={"form-input"} required
                                                   value={date} onChange={(e) => setDate(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-6"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Lot<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"number"} className={"form-input"} required
                                                   value={lot} onChange={(e) => setLot(e.target.value)}/>
                                        </div>
                                    </div>
                                    {products && products.length > 0 && <div className={"col-12"}>
                                        <div className={"table mb-1"}>

                                            <div className={"table-head"}>
                                                <div className={"row"}>
                                                    <div className={"col-8 table-td"}>Titlu produs</div>
                                                    <div className={"col-4 table-td"}>Garanție</div>
                                                </div>
                                            </div>

                                            <div className={"table-body"}>
                                                {
                                                    products.map((item, i) => (
                                                        <div className={"table-row"} key={i} style={{cursor: "pointer"}}
                                                             onClick={() => {

                                                                 setProductSelected(0)
                                                                 setIndexProduct(i)
                                                                 setTitle(item.title)
                                                                 setGuarantee(item.guarantee)
                                                                 setShowModalProduct(true)
                                                             }}>
                                                            <div className={"row"}>
                                                                <div
                                                                    className={"col-8 table-td"}>
                                                                    {item.title}
                                                                </div>
                                                                <div
                                                                    className={"col-4 table-td"}>
                                                                    {item.guarantee}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>}
                                    <div className={"col-12"}>
                                        <Button
                                            className={"btn-primary mt-2"}
                                            style={{width: '100%'}}
                                            value={"Adaugă produs"}
                                            onClick={() => {
                                                setProductSelected(null)
                                                setIndexProduct(null)
                                                setTitle('')
                                                setGuarantee(0)
                                                setShowModalProduct(true)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={"footer"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                        <Button
                                            className={"btn-light mt-2"}
                                            icon={"fa-solid fa-chevron-left"}
                                            value={"Înapoi"}
                                            onClick={() => navigate(-1)}
                                        />
                                    </div>
                                    <div className={"col-12 col-md-6 d-flex"}>
                                        <Button
                                            className={"btn-secondary mt-2 ms-auto"}
                                            icon={"fa-solid fa-plus"}
                                            value={"Salvează"}
                                            disabled={saveLoading}
                                            loading={saveLoading}
                                            type={'submit'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
           <ModalAddProductToSims
               indexProduct={indexProduct} showModalProduct={showModalProduct}
               setProductSelected={setProductSelected} setIndexProduct={setIndexProduct}
               setGuarantee={setGuarantee} setShowModalProduct={setShowModalProduct}
               setTitle={setTitle} products={products} title={title} guarantee={guarantee}
               setProducts={setProducts} productsOptions={productsOptions} productSelected={productSelected}
           />
        </div>
    );
}

