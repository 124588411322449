import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import ModalComp from "../../../components/ModalComp/ModalComp";
import moment from "moment";

export default function ProductStock() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')
    let params = useParams();

    const [qty, setQty] = useState(0)
    const [stockQty, setStockQty] = useState(0)
    const [details, setDetails] = useState('')
    const [title, setTitle] = useState('')
    const [stock, setStock] = useState([])

    const [loading, setLoading] = useState(true)
    const [modalIsOpen, setModalIsOpen] = useState(false)

    const [saveLoading, setSaveLoading] = useState(false)
    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async (p2) => {

        axios.get(process.env.REACT_APP_API + '/products/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setTitle(response.data.title)
                    setStockQty(response.data.stock)
                    setStock(response.data.stocks ?? [])

                    setLoading(false)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const addInStock = async (e) => {
        e.preventDefault();
        setSaveLoading(true)

        await axios.post(process.env.REACT_APP_API + '/products/add-in-stock/' + params.id,
            {details, qty}, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
            .then(async (response) => {
                toast.success("Informațiile au fost salvate cu succes!")
                getData()
                setModalIsOpen(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })

        setSaveLoading(false)


    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">
            <div className={"header"}>
                <div className={"row"}>
                    <div className={"col-md-6 col-12"}>
                        <p>Stoc produs: {title}</p>
                    </div>
                    <div className={"col-md-6 col-12 d-flex"}>

                        <Button
                            className={"btn-primary ms-auto"}
                            value={"Adaugă produse în stoc"}
                            onClick={() => {
                               setModalIsOpen(true)
                            }}
                        />
                    </div>

                </div>
            </div>
            <div className={"content"}>
                {stock.length > 0 && <div className={"table mb-1"}>
                    <div className={"table-head"} style={{minWidth: '770px'}}>
                        <div className={"row"}>
                            <div className={"col-6 table-td"}>Detalii</div>
                            <div className={"col-3 table-td"}>Data</div>
                            <div className={"col-3 table-td"}>Cantitate</div>
                        </div>
                    </div>

                    <div className={"table-body"} style={{minWidth: '770px'}}>
                        <div className={"table-row"}>
                            {stock.map(item =>
                                <div className={"row"} key={item.id}>
                                    <div className={"col-6 table-td"}>{item.details}</div>
                                    <div className={"col-3 table-td"}>{moment(item.created_at).format('DD/MM/YYYY HH:mm')}</div>
                                    <div className={"col-3 table-td"}>{item.type === 'input'? '+':'-'}{item.qty}</div>
                                </div>
                            )}

                            <div className={"row"}>
                                <div className={"col-9 table-td"}><strong>Stoc</strong></div>
                                <div className={"col-3 table-td"}><strong>{stockQty}</strong></div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            <div className={"footer"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <Button
                            className={"btn-light mt-2"}
                            icon={"fa-solid fa-chevron-left"}
                            value={"Înapoi"}
                            onClick={() => navigate(-1)}
                        />
                    </div>
                </div>
            </div>


            <ModalComp
                title={'Adaugă produse în stoc'}
                isOpen={modalIsOpen}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalIsOpen(false)}
            >
                <form onSubmit={(e) => addInStock(e)}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <div>
                            <div
                                className={"form-label"}>Cantitate<span
                                className={"form-required"}>*</span></div>
                            <input type={"number"} className={"form-input"}
                                   value={qty} required
                                   onChange={(e) => setQty(e.target.value)}
                            />
                        </div>
                        <div>
                            <div
                                className={"form-label"}>Detalii</div>
                            <input type={"text"} className={"form-input"}
                                   value={details}
                                   onChange={(e) => setDetails(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={'Salvează'}
                                    type={'submit'}
                                    disabled={saveLoading}
                                    loading={saveLoading}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
        </div>

    );
}

