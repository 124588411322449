import {useNavigate} from "react-router-dom";
import './Sidebar.scss';
import React from "react";
import LoadingCompSmall from "../../elements/LoadingComp/LoadingCompSmall";
import SubMenu from "./SubMenu";

export default function Sidebar({sidebarMini, menuIsOpen,setMenuIsOpen,setSidebarMini,items,loading,user}) {
    const navigate = useNavigate()

    if((window.innerWidth <= 576 && menuIsOpen) || window.innerWidth > 576){
        return (
            <div className={(sidebarMini ? 'sidebar mini' : 'sidebar')}>
                <div className={"menu"}>
                    {!sidebarMini &&
                        <div className={"logo"}>
                            <img src={"/images/logo.svg"} alt={""}/>
                        </div>
                    }
                   <SubMenu
                        key={'Înapoi'}
                        item={{
                            path: -1,
                            icon: "fa-solid fa-arrow-left",
                            title: 'Înapoi',
                    }}
                        sidebarMini={sidebarMini}
                        setSidebarMini={setSidebarMini}
                        setMenuIsOpen={setMenuIsOpen}
                    />

                    {items.map((item) => (
                        <SubMenu
                            key={item.title}
                            item={item}
                            sidebarMini={sidebarMini}
                            setSidebarMini={setSidebarMini}
                            setMenuIsOpen={setMenuIsOpen}
                        />
                    ))}


                </div>

                <div className={"user-cont"} onClick={() => {
                    setMenuIsOpen(false)
                    navigate('/')
                }}>

                    {
                        loading ?
                            <LoadingCompSmall/>
                            :
                            <>
                                <div className={"icon"}>
                                    <i className="fa-solid fa-cog"></i>
                                </div>
                                {!sidebarMini &&
                                    <div className={"content"}>
                                        <div className={"name"}>{user.first_name} {user.last_name}</div>
                                    </div>
                                }
                            </>
                    }

                </div>
            </div>
        );
    }

}

