import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import {useNavigate, useParams} from "react-router-dom";
import Paginate from "../../../components/elements/Paginate/Paginate";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import moment from "moment";
import ModalComp from "../../../components/ModalComp/ModalComp";

export default function Projects() {

    const intl = useIntl();
    const navigate = useNavigate()
    let params = useParams();
    const jwt = localStorage.getItem('jwt')

    const [projects, setProjects] = useState([])

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [itemSelected, setItemSelected] = useState(null)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [totalTemplates, setTotalTemplates] = useState(0)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/projects/list/' + page + '/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setProjects(response.data.projects ?? [])
                setTotalTemplates(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    const deleteProject = async (e) => {
        e.preventDefault()
        setDeleteLoading(true)
        axios.delete(process.env.REACT_APP_API + '/projects/' + itemSelected.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })

        setDeleteLoading(true)
        setModalIsOpen(false)
        setItemSelected(null)
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>
                        {projects[0].client ? projects[0].client.company_title : projects[0].client_name}
                    </h1>
                </div>

                <div className={"col-12 pb-4"}>

                <div className="card">

            <div className={"header d-flex"}>
                <Button
                    className={"btn-primary d-flex align-items-center ms-auto"}
                    icon={"fa-solid fa-plus"}
                    value={'Adaugă categorie'}
                    onClick={() => navigate('/add/' + projects[0].project_type +'/'+ projects[0].acquisition)}
                />
            </div>
            {
                projects.length === 0 ?
                    <div className={"content"}>

                        <div className={"alert alert-warning"}>Momentan nu aveți nici o achiziție adăugată!</div>
                    </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div className={"col-1 table-td"}>Nr</div>
                                    <div className={"col-3 table-td"}>Categorie</div>
                                    <div className={"col-2 table-td"}>Suma</div>
                                    <div className={"col-2 table-td"}>Ultimul document generat</div>
                                    <div className={"col-2 table-td"}>Data ultimului document generat</div>
                                    <div className={"col-2 table-td"}></div>
                                </div>
                            </div>

                            <div className={"table-body"}>
                                {
                                    projects.map((project, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    onClick={() => navigate('/acquisitions/projects/' + project.project_type +'/'+ project.id)}
                                                    className={"col-1 table-td"}>
                                                    {i + 1 + (page-1) * 10}
                                                </div>
                                                <div
                                                    onClick={() => navigate('/acquisitions/projects/' + project.project_type +'/' + project.id)}
                                                    className={"col-3 table-td"}>
                                                    {intl.formatMessage({id: 'projects.type.' +project.type})}
                                                </div>
                                                <div
                                                    onClick={() => navigate('/acquisitions/projects/' + project.project_type +'/' + project.id)}
                                                    className={"col-2 table-td"}>
                                                    {project.type === 'products_smartlab' ? project.totalGrant : project.total}
                                                </div>
                                                <div
                                                    onClick={() => navigate('/acquisitions/projects/' + project.project_type +'/' + project.id)}
                                                    className={"col-2 table-td"}>

                                                    { project.documents.length > 0 &&
                                                    intl.formatMessage({id: 'templates.type.' + project.documents[project.documents.length - 1].type})}
                                                </div>
                                                <div
                                                    onClick={() => navigate('/acquisitions/projects/' + project.project_type +'/' + project.id)}
                                                    className={"col-2 table-td"}>
                                                    {
                                                        project.documents.length ?
                                                        moment(project.documents[project.documents.length-1].updated_at).format('DD/MM/YYYY HH:mm'): ''
                                                    }
                                                </div>
                                                <div className={"col-2 table-td"}>

                                                    {project.winning_offer && project.winning_offer && <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        icon={"fa-solid fa-truck-arrow-right"}
                                                        onClick={() => navigate('/acquisitions/track-products/' + project.id)}
                                                    />}
                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        icon={"fa-solid fa-pen"}
                                                        onClick={() => navigate('/acquisitions/projects/' + project.project_type +'/' + project.id)}
                                                    />
                                                    {projects.length > 0 && <Button
                                                        className={"btn-light btn-delete"}
                                                        icon={"fa-solid fa-trash"}
                                                        onClick={() => {
                                                            setModalIsOpen(true)
                                                            setItemSelected(project)
                                                        }}
                                                    />}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }

            <div className={"footer d-flex justify-content-center"}>
                <Paginate
                    page={page}
                    setPage={setPage}
                    totalItems={totalTemplates}
                />
            </div>
        </div>
                </div>
            </div>
            <ModalComp
                title={'Ștergere proiect'}
                isOpen={modalIsOpen}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalIsOpen(false)}
            >
                <form onSubmit={(e) => deleteProject(e)}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <p>Sigur dorești ștergerea proiectului?</p>
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-6 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4"}
                                    value={'Nu'}
                                    type={'button'}
                                    onClick={() => setModalIsOpen(false)}
                                />
                            </div>
                            <div className={"col-6 d-flex"}>
                                <Button
                                    className={"btn-gray mt-4 ms-auto"}
                                    value={'Da'}
                                    type={'submit'}
                                    disabled={deleteLoading}
                                    loading={deleteLoading}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
        </div>
    );
}

