import axios from "axios";
import errorParser from "../components/ErrorParser/ErrorParser";

const jwt = localStorage.getItem('jwt')


export async function deleteDocument(id, intl) {
    return axios.delete(process.env.REACT_APP_API + '/documents/' + id, {
        headers: {
            'Authorization': 'Bearer ' + jwt
        }
    })
        .then(async (response) => {

            return response.data.status;
        })
        .catch(err => {
            errorParser(err, intl)
        })
}

export async function getProjectInfo(id, intl) {
    return axios.get(process.env.REACT_APP_API + '/projects/' + id, {
        headers: {
            'Authorization': 'Bearer ' + jwt
        }
    })
        .then(async (response) => {
            return response.data;
        })
        .catch(err => {
            errorParser(err, intl)
        })
}

export async function downloadDoc(title, id, setLoadingDownload, setLoading = true) {

    if (setLoading)
        setLoadingDownload(true)
    fetch(process.env.REACT_APP_API + '/documents/download/' + id, {
        method: 'GET', headers: {
            'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt
        },
    })
        .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = title + ".docx";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();  //afterwards we remove the element again
            setLoadingDownload(false)
        })
        .catch(e => console.log(e))
}