import Button from "../../../components/elements/Button/Button";
import React from "react";
import Selector from "../../../components/elements/Selector/Selector";
import ModalComp from "../../../components/ModalComp/ModalComp";

export default function ModalAddProductToSims({indexProduct, showModalProduct,setProductSelected,setIndexProduct, setGuarantee,setShowModalProduct,
                                                setTitle, products, title, guarantee, setProducts, productsOptions,productSelected}) {
    return (<ModalComp
            title={(indexProduct === null ? 'Adaugă produs' : 'Editează produsul')}
            isOpen={showModalProduct}
            className={"sign-contract"}
            close={true}
            onRequestClose={() => {
                setProductSelected(null)
                setIndexProduct(null)
                setGuarantee(0)
                setTitle('')
                setShowModalProduct(false)
            }}
        >
            <form onSubmit={(e) => {
                e.preventDefault()
                let temp = [...products]
                if (indexProduct === null) {
                    temp.push({
                        title,
                        guarantee
                    })
                } else {
                    temp[indexProduct].title = title
                    temp[indexProduct].guarantee = guarantee
                }
                setProducts(temp)
                setShowModalProduct(false)
            }}>
                <div className={"content"} style={{paddingBottom: '200px'}}>
                    <p className={"mb-0"}>Produs</p>
                    <div className={"search-input-container"}>
                        <i className="fa-solid fa-magnifying-glass"></i>
                        <Selector
                            options={productsOptions}
                            value={productSelected}
                            onChange={(option) => {
                                setTitle(option.label)
                                setGuarantee(option.guarantee)
                                setProductSelected(option.value)
                            }}
                            isSearchable={false}
                            isOptionDisabled={(option) => option.disabled}
                        />
                    </div>

                    {
                        productSelected !== null && <>
                            <p className={"mb-0"}>Titlu</p>

                            <input type={"text"} className={"form-input"} required
                                   value={title} onChange={(e) => setTitle(e.target.value)}/>
                            <p className={"mb-0"}>Garanție</p>

                            <input type={"number"} className={"form-input"} required
                                   value={guarantee} onChange={(e) => setGuarantee(e.target.value)}/></>
                    }

                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 d-flex"}>
                            {indexProduct !== null && <Button
                                className={"btn-light mt-4"}
                                value={'Șterge'}
                                type={'button'}
                                onClick={() => {
                                    let temp = [...products]
                                    temp.splice(indexProduct, 1)
                                    setProducts(temp)
                                    setShowModalProduct(false)
                                }}
                            />}
                            <Button
                                className={"btn-primary mt-4 ms-auto"}
                                value={(indexProduct === null ? 'Adaugă' : 'Salveză')}
                                type={'submit'}
                            />
                        </div>
                    </div>

                </div>
            </form>

        </ModalComp>
    );
}

