import SecondaryNavigation from "../../../components/elements/SecondaryNavigation/SecondaryNavigation";
import {Outlet, useNavigate} from "react-router-dom";
import {useEffect} from "react";

export default function ProductsMenu() {

    const navigate = useNavigate()

    useEffect(() => {
        if (window.location.pathname === '/products'){
            navigate('/products/items')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>Echipamente și software</h1>
                </div>

                <div className={"col-lg-12 col-xl-2 pb-4"}>

                    <SecondaryNavigation
                        items={[
                            {
                                'icon': "fa-solid fa-store",
                                'title': "Echipamente și software",
                                'url': '/products/items',
                            },
                            {
                                'icon': "fa-solid fa-truck-field",
                                'title': "Categorii de echipamente",
                                'url': '/products/categories'
                            },
                        ]}
                    />
                </div>
                <div className={"col-lg-12 col-xl-10"}>
                    <Outlet />
                </div>
            </div>




        </div>
    );
}

