import React, {useState} from "react";
import ModalComp from "../../ModalComp/ModalComp";
import Button from "../Button/Button";

export default function CustomTextarea(
    {
        label,
        required,
        value,
        setValue,
        minHeight
    }
) {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    return (<div className={"form-control h-100"}>
            <div
                className={"form-label"}>{label}
                {required && <span className={"form-required"}>*</span>}</div>
            <textarea className={"form-input"}
                      onClick={() => setModalIsOpen(true)}
                      required={required} style={{minHeight: minHeight, height: '100%'}}
                      value={value} onChange={(e) => setValue(e.target.value)}/>
            <ModalComp
                title={label}
                isOpen={modalIsOpen}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalIsOpen(false)}
            >
                    <div className={"content"} style={{maxHeight: '70vh', height: '70vh'}}>
                            <textarea className={"form-input"}
                      required={required} style={{height: '100%'}}
                      value={value} onChange={(e) => setValue(e.target.value)}/>

                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={'Închide'}
                                    type={'button'}
                                    onClick={() => setModalIsOpen(false)}
                                />
                            </div>
                        </div>

                    </div>

            </ModalComp>
        </div>
    );
}

