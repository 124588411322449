import React from "react";

export default function Checkbox(
    {
        className,
        value = "",
        onChange = () => {
        },
        itemKey,
        ...props
    }
) {
    return (<input type={"checkbox"} className={className}
                   key={itemKey}
                   checked={value}
                   value={1}
                   onChange={() => onChange()}
        />
    );
}

