import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";

export default function AddProductsCategory({showHeader = true, setShowAddCategoryModal = () => {}}) {
    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [title, setTitle] = useState('')

    const [saveLoading, setSaveLoading] = useState(false)

    const save = async (e) => {
        e.preventDefault();
        setSaveLoading(true)

        await axios.post(process.env.REACT_APP_API + '/product-categories',
            {title}, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
            .then(async (response) => {
                toast.success("Informațiile au fost salvate cu succes!")
                if (showHeader)
                    navigate(-1)
                else
                    setShowAddCategoryModal(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)


    }

    return (
        <div className="card">

            {showHeader && <div className={"header"}><p>Adaugă categorie</p></div>}

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>Denumire<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required
                                       value={title} onChange={(e) => setTitle(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            {showHeader && <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={"Înapoi"}
                                onClick={() => navigate(-1)}
                            />}
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={"Adaugă"}
                                disabled={saveLoading}
                                loading={saveLoading}
                                type={'submit'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

