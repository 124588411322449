import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";

export default function Account() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')

    const [changePassword, setChangePassword] = useState(false)
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')

    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setFirstName(response.data.first_name)
                setLastName(response.data.last_name)
                setEmail(response.data.email)

                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (e) => {

        e.preventDefault();
        //Validation

        if (changePassword) {
            if (!password || !repeatPassword || password !== repeatPassword) {
                toast.error("Cele 2 parole introduse nu corespund!")
                return
            }
        }

        setSaveLoading(true)

        let payload = {
            first_name: firstName,
            last_name: lastName
        }
        if (changePassword) {
            payload.password = password
        }

        await axios.put(process.env.REACT_APP_API + '/users-settings/me', payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success("Informațiile au fost salvate cu succes!")
            })
            .catch(err => {
                errorParser(err, intl)
            })

        setSaveLoading(false)
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>Contul tău</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card">

                        <form onSubmit={(e) => save(e)}>
                            <div className={"content"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>Prenume<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required
                                                   value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-6"}>
                                        <div className={"form-control"}>
                                            <div className={"form-label"}>Nume<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required
                                                   value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-6"}>
                                        <div className={"form-control"}>
                                            <div className={"form-label"}>Adresa de email</div>
                                            <input type={"email"} className={"form-input"} disabled={true}
                                                   value={email} onChange={(e) => {
                                            }}/>
                                        </div>
                                    </div>

                                    <div className={"col-12"}>
                                        <div className={"mt-3 form-control"}>
                                            <label>
                                                <input type={"checkbox"}
                                                       checked={changePassword}
                                                       onChange={() => setChangePassword(!changePassword)}/> Doriți să schimbați parola?
                                            </label>
                                        </div>
                                    </div>
                                    {
                                        changePassword ?
                                            <>
                                                <div className={"col-12 col-md-6"}>
                                                    <div className={"form-control"}>
                                                        <div
                                                            className={"form-label"}>Introduceți noua parolă
                                                            <span className={"form-required"}>*</span></div>
                                                        <input type={"password"} className={"form-input"} required
                                                               value={password} onChange={(e) => setPassword(e.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className={"col-12 col-md-6"}>
                                                    <div className={"form-control"}>
                                                        <div
                                                            className={"form-label"}>Reintroduceți noua parolă
                                                            <span className={"form-required"}>*</span></div>
                                                        <input type={"password"} className={"form-input"}
                                                               value={repeatPassword} required
                                                               onChange={(e) => setRepeatPassword(e.target.value)}/>
                                                    </div>
                                                </div>
                                            </>
                                            : null
                                    }
                                </div>
                            </div>

                            <div className={"footer"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                        <Button
                                            className={"btn-light mt-2"}
                                            icon={"fa-solid fa-arrow-right-from-bracket"}
                                            value={'Deconectare'}
                                            onClick={() => {
                                                localStorage.clear();
                                                window.location.replace("/");
                                            }}
                                        />
                                    </div>
                                    <div className={"col-12 col-md-6 d-flex"}>
                                        <Button
                                            className={"btn-secondary mt-2 ms-auto"}
                                            icon={"fa-regular fa-floppy-disk"}
                                            value={"Salvează"}
                                            type={"submit"}
                                            loading={saveLoading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

