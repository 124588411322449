import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import {useNavigate, useParams} from "react-router-dom";
import Paginate from "../../../components/elements/Paginate/Paginate";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";

export default function ProjectsAdmin() {

    const intl = useIntl();
    const navigate = useNavigate()
    let params = useParams();
    const jwt = localStorage.getItem('jwt')

    const [projects, setTemplates] = useState([])

    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [totalProjects, setTotalProjects] = useState(0)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/projects/list/' + page + '/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setTemplates(response.data.projects ?? [])
                setTotalProjects(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container companies-list">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>Achiziții</h1>
                </div>

                <div className={"col-12 pb-4"}>

                <div className="card">

            {
                projects.length === 0 ?
                    <div className={"content"}>

                        <div className={"alert alert-warning"}>Momentan nu aveți nici o achiziție adăugată!</div>
                    </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div className={"col-1 table-td"}>Nr</div>
                                    <div className={"col-4 table-td"}>Denumire</div>
                                    <div className={"col-3 table-td"}>Institutia</div>
                                    <div className={"col-3 table-td"}>Utilizator</div>
                                    <div className={"col-1 table-td"}></div>
                                </div>
                            </div>

                            <div className={"table-body"}>
                                {
                                    projects.map((project, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-1 table-td"}>
                                                    {i + 1 + (page-1) * 10}
                                                </div>
                                                <div
                                                    className={"col-4 table-td"}>
                                                    {project.title}
                                                </div>
                                                <div
                                                    className={"col-3 table-td"}>
                                                    {project.client ? project.client.company_title : project.client_name}
                                                </div>
                                                <div
                                                    className={"col-3 table-td"}>
                                                    {project.user.first_name} { project.user.last_name}
                                                </div>
                                                <div className={"col-1 table-td"}>
                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        icon={"fa-solid fa-eye"}
                                                        onClick={() => navigate('/acquisitions/projects/' + project.project_type +'/' + project.id)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }

            <div className={"footer d-flex justify-content-center"}>
                <Paginate
                    page={page}
                    setPage={setPage}
                    totalItems={totalProjects}
                />
            </div>
        </div>
                </div>
            </div>
        </div>
    );
}

