import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import React from "react";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import './Company.scss';
import {useNavigate, useParams} from "react-router-dom";

export default function ViewCompany() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const navigate = useNavigate()

    const [company, setCompany] = useState({})

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/companies/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setCompany(response.data)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }
    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{company.title}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card" style={{position: "relative"}}>
                            <div className={"content"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <div className={"form-label"}>CUI: {company.fiscal_code}</div>
                                        <div className={"form-label"}>Nr. înreg. Registrul Comerțului: {company.reg_com_number}</div>
                                        <div className={"form-label"}>Județ: {company.county}</div>
                                        <div className={"form-label"}>Oraș: {company.city}</div>
                                        <div className={"form-label"}>Adresa: {company.address}</div>
                                        <div className={"form-label"}>Adresa de email: {company.email}</div>
                                        <div className={"form-label"}>Telefon: {company.phone}</div>
                                        <div className={"form-label"}>Fax: {company.fax}</div>
                                        <div className={"form-label"}>Nume reprezentant legal: {company.legal_representative_name}</div>
                                        <div className={"form-label"}>Funcție reprezentant legal: {company.legal_representative_function}</div>
                                        <div className={"form-label"}>Cont bancar: {company.bank_account}</div>
                                        <div className={"form-label"}>Bancă: {company.bank}</div>
                                        <div className={"form-label"}>Ultimul număr de ofertă: {company.offer_number}</div>
                                    </div>
                                </div>
                            </div>

                            <div className={"footer"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <Button
                                            className={"btn-light mt-2"}
                                            icon={"fa-solid fa-chevron-left"}
                                            value={"Înapoi"}
                                            onClick={() => navigate(-1)}
                                        />
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

