import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useOutletContext, useParams} from "react-router-dom";
import axios from "axios";
import errorParser from "../../../../../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../../../../../components/elements/LoadingComp/LoadingComp";
import DetailsType2 from "./DetailsType2";
import DetailsType1 from "./DetailsType1";

export default function Details() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();


    const [update, setUpdate] = useOutletContext();
    const [title, setTitle] = useState('')
    const [motivation, setMotivation] = useState('')
    const [projectType, setProjectType] = useState('')
    const [projectCode, setProjectCode] = useState('')
    const [client, setClient] = useState('')
    const [clientName, setClientName] = useState('')
    const [projectTitle, setProjectTitle] = useState('')
    const [numberFinancingContract, setNumberFinancingContract] = useState('')
    const [dateFinancingContract, setDateFinancingContract] = useState('')
    const [deliveryTime, setDeliveryTime] = useState('')
    const [submissionDeadline, setSubmissionDeadline] = useState('')
    const [requirements, setRequirements] = useState([])
    const [totalValue, setTotalValue] = useState(0)
    const [providers, setProviders] = useState([])
    const [clients, setClients] = useState([])
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState('')
    const [companies, setCompanies] = useState([])
    const [isInvGenerated, setIsInvGenerated] = useState(false)
    const [products, setProducts] = useState([])
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalEditIsOpen, setModalEditIsOpen] = useState(false)
    const [project, setProject] = useState('')
    const [product, setProduct] = useState('')
    const [daysClarification, setDaysClarification] = useState(3)
    const [validityOffer, setValidityOffer] = useState(60)

    const [type, setType] = useState('')
    const [addTo, setAddTo] = useState('common')
    const [requirementsCommon, setRequirementsCommon] = useState([])
    const [otherRequirements, setOtherRequirements] = useState([])
    const [requirementsDifferentiated, setRequirementsDifferentiated] = useState([])
    const [totalValueCommonTrunk, setTotalValueCommonTrunk] = useState(0)
    const [totalValueDifferentiatedTrunk, setTotalValueDifferentiatedTrunk] = useState(0)

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getClients()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getClients = async () => {
        axios.post(process.env.REACT_APP_API + '/clients/list/0',
            {}, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
            .then(async (response) => {
                let temp = [];
                response.data.clients.forEach((client) => {
                    temp.push({
                        value: client.id,
                        label: client.company_title,
                        projectTitle: client.projectTitle,
                        number_financing_contract: client.number_financing_contract,
                        dateFinancingContract: client.dateFinancingContract,
                    })
                })
                setClients(temp)
                getData()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/projects/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setProject(response.data)
                    setTitle(response.data.title)
                    if (response.data.motivation){
                        setMotivation(response.data.motivation)
                    }else {
                        setMotivation('Sprijinirea unitatii de invatamant cu risc ridicat de abandon scolar, prin achizitia de '+(response.data.title ??
                                '.....................................')+
                            ' necesare dotarii salilor de clasa (Cod CPV: 30190000 -7 Diverse masini, echipamente si accesorii de birou),' +
                            ' conform ordinului de ministru 3.677/2023, in vederea inceperii activitatilor cu grupul nostru ținta conform graficului activităților')
                    }
                    setProjectCode(response.data.project_code ?? '')
                    setProjectType(response.data.project_type ?? '')
                    setClientName(response.data.client_name)
                    setClient(response.data.client)
                    setProjectTitle(response.data.data.projectTitle)
                    setNumberFinancingContract(response.data.data.numberFinancingContract ?? 0)
                    setDateFinancingContract(response.data.data.dateFinancingContract ?? '')
                    setSubmissionDeadline(response.data.data.submissionDeadline ?? '')
                    setDeliveryTime(response.data.data.deliveryTime ?? '')
                    setType(response.data.type)
                    if (response.data.type === 'products_smartlab'){

                        setOtherRequirements(response.data.data.otherRequirements ??[])
                        setRequirementsCommon(response.data.data.requirements_common)
                        setRequirementsDifferentiated(response.data.data.requirements_differentiated)
                        setTotalValueCommonTrunk(response.data.data.totalValueCommon)
                        setTotalValueDifferentiatedTrunk(response.data.data.totalValueDifferentiated)
                    }else {
                        setRequirements(response.data.data.requirements)
                        setTotalValue(response.data.data.totalValue)

                    }
                    setProviders(response.data.companies.map((company) => company.id))
                    if (response.data.invoices.length > 0)
                        setIsInvGenerated(true)
                    getCategories(response.data.project_type,response.data.type)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getCategories = async (project_type, type) => {
        axios.post(process.env.REACT_APP_API + '/product-categories/list/0', {}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = [];
                response.data.categories.forEach((category) => {
                    temp.push({
                        price: category.price,
                        guarantee: 36,
                        id: category.id,
                        value: category.id,
                        label: category.title,
                        products: category.products,
                        qty: 1
                    })
                })
                setCategories(temp)
                getCompanies()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getCompanies = async () => {
        axios.get(process.env.REACT_APP_API + '/companies', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = [];
                response.data.forEach((company) => {
                    temp.push({
                        value: company.id,
                        label: company.title,
                    })
                })
                setCompanies(temp)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }



    if (loading) {
        return <LoadingComp/>
    }
    return (
        type === 'products_smartlab' ?
            <DetailsType2 update={update} setUpdate={setUpdate} title={title} setTitle={setTitle} motivation={motivation}
                          setMotivation={setMotivation} projectCode={projectCode} setProjectCode={setProjectCode} client={client}
                          setClient={setClient} clientName={clientName} projectTitle={projectTitle} setProjectTitle={setProjectTitle}
                          numberFinancingContract={numberFinancingContract} setNumberFinancingContract={setNumberFinancingContract}
                          dateFinancingContract={dateFinancingContract} setDateFinancingContract={setDateFinancingContract}
                          deliveryTime={deliveryTime} setDeliveryTime={setDeliveryTime} submissionDeadline={submissionDeadline}
                          setSubmissionDeadline={setSubmissionDeadline} providers={providers} setProviders={setProviders}
                          clients={clients} categories={categories} category={category} setCategory={setCategory} companies={companies}
                          isInvGenerated={isInvGenerated} products={products} setProducts={setProducts} modalIsOpen={modalIsOpen}
                          setModalIsOpen={setModalIsOpen} modalEditIsOpen={modalEditIsOpen} setModalEditIsOpen={setModalEditIsOpen}
                          project={project} product={product} setProduct={setProduct} daysClarification={daysClarification}
                          setDaysClarification={setDaysClarification} validityOffer={validityOffer} setValidityOffer={setValidityOffer}
                          getData={getData} addTo={addTo} setAddTo={setAddTo} requirementsCommon={requirementsCommon}
                          setRequirementsCommon={setRequirementsCommon} requirementsDifferentiated={requirementsDifferentiated}
                          setRequirementsDifferentiated={setRequirementsDifferentiated} totalValueCommonTrunk={totalValueCommonTrunk}
                          setTotalValueCommonTrunk={setTotalValueCommonTrunk} totalValueDifferentiatedTrunk={totalValueDifferentiatedTrunk}
                          setTotalValueDifferentiatedTrunk={setTotalValueDifferentiatedTrunk} otherRequirements={otherRequirements}
                          setOtherRequirements={setOtherRequirements} projectType={projectType} type={type}/>
            :
            <DetailsType1 update={update} setUpdate={setUpdate} title={title} setTitle={setTitle} motivation={motivation}
                          setMotivation={setMotivation} projectCode={projectCode} setProjectCode={setProjectCode} client={client}
                          setClient={setClient} clientName={clientName} projectTitle={projectTitle} setProjectTitle={setProjectTitle}
                          numberFinancingContract={numberFinancingContract} setNumberFinancingContract={setNumberFinancingContract}
                          dateFinancingContract={dateFinancingContract} setDateFinancingContract={setDateFinancingContract}
                          deliveryTime={deliveryTime} setDeliveryTime={setDeliveryTime} submissionDeadline={submissionDeadline}
                          setSubmissionDeadline={setSubmissionDeadline} providers={providers} setProviders={setProviders}
                          clients={clients} categories={categories} category={category} setCategory={setCategory} companies={companies}
                          isInvGenerated={isInvGenerated} products={products} setProducts={setProducts} modalIsOpen={modalIsOpen}
                          setModalIsOpen={setModalIsOpen} modalEditIsOpen={modalEditIsOpen} setModalEditIsOpen={setModalEditIsOpen}
                          project={project} product={product} setProduct={setProduct} daysClarification={daysClarification}
                          setDaysClarification={setDaysClarification} validityOffer={validityOffer} setValidityOffer={setValidityOffer}
                          getData={getData} requirements={requirements} setRequirements={setRequirements} totalValue={totalValue}
                          setTotalValue={setTotalValue} projectType={projectType} type={type} />
    );
}

