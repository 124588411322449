import {useIntl} from "react-intl";
import React, {useState} from "react";
import '../Projects.scss';
import AddProjectType2 from "./AddProjectType2";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import AddProjectType3 from "./AddProjectType3";

export default function AddProjectSMARTLAB() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [type, setType] = useState('products_smartlab')

    const getCategories = async () => {
        return axios.post(process.env.REACT_APP_API + '/product-categories/list/0', {}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = [];
                response.data.categories.forEach((category) => {
                    let prods = category.products.filter((product) => product.type === type && product.project_type === 'smartlab')
                    if (prods.length > 0)
                        temp.push({
                            price: category.price,
                            id: category.id,
                            value: category.id,
                            label: category.title,
                            products: prods,
                            qty: 1
                        })
                })
                return temp
            })
            .catch(err => {
                return []
                errorParser(err, intl)
            })
    }

    return (
        <>
            {
                type === 'products_smartlab' ?
                    <AddProjectType2 type={type} setType={setType} categoriesOptions={[{
                        value: 'products_smartlab',
                        label: intl.formatMessage({id: 'projects.type.products_smartlab'})
                    }, {
                        value: 'educational',
                        label: intl.formatMessage({id: 'projects.type.educational'})
                    }, {
                        value: 'connect_devices',
                        label: intl.formatMessage({id: 'projects.type.connect_devices'})
                    }, {
                        value: 'teacher_training_sessions',
                        label: intl.formatMessage({id: 'projects.type.teacher_training_sessions'})
                    }, {
                        value: 'ergonomic_furniture',
                        label: intl.formatMessage({id: 'projects.type.ergonomic_furniture'})
                    }
                    ]} getCategories={getCategories}/>
                    :
                    <AddProjectType3 type={type} setType={setType} categoriesOptions={[{
                        value: 'products_smartlab',
                        label: intl.formatMessage({id: 'projects.type.products_smartlab'})
                    }, {
                        value: 'educational',
                        label: intl.formatMessage({id: 'projects.type.educational'})
                    }, {
                        value: 'connect_devices',
                        label: intl.formatMessage({id: 'projects.type.connect_devices'})
                    }, {
                        value: 'teacher_training_sessions',
                        label: intl.formatMessage({id: 'projects.type.teacher_training_sessions'})
                    }, {
                        value: 'ergonomic_furniture',
                        label: intl.formatMessage({id: 'projects.type.ergonomic_furniture'})
                    }]} getCategories={getCategories}/>
            }</>
    );
}

