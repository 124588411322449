import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import ModalComp from "../../../../../../components/ModalComp/ModalComp";
import Selector from "../../../../../../components/elements/Selector/Selector";
import moment from "moment";
import {deleteDocument, downloadDoc, getProjectInfo} from "../../../../../../utils/project_functions";

export default function Appendix15() {

    const navigate = useNavigate()
    const intl = useIntl();
    let params = useParams();
    const jwt = localStorage.getItem('jwt')

    const [project, setProject] = useState([])

    const [update, setUpdate, templates] = useOutletContext();
    const [loading, setLoading] = useState(true)
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [company, setCompany] = useState(0)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [template, setTemplate] = useState('')
    const [loadingGenerate, setLoadingGenerate] = useState(false)
    const [draft, setDraft] = useState(false)
    const [appendix15, setAppendix15] = useState(null)
    const [docIdToDelete, setDocIdToDelete] = useState(0)

    useEffect(() => {
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const init = async () => {

        let proj = await getProjectInfo(params.id, intl)
        proj.offers.sort((a, b) => (a.total > b.total) ? 1 : ((b.total > a.total) ? -1 : 0))

        setProject(proj)
        setAppendix15(proj.documents.filter(doc => doc.type === 'appendix_15') ?? null)
        setLoading(false)
    }

    const generateDoc = async (e) => {
        e.preventDefault()
        setLoadingGenerate(true)

        if (docIdToDelete !== 0) {
            await deleteDocument(docIdToDelete, intl)
        }




        fetch(process.env.REACT_APP_API + '/projects/generate-doc/' + params.id, {
            method: "POST",
            body: JSON.stringify({template, company, status: draft ? 'draft' : 'generated'}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
        })
            .then(response => response.blob())
            .then(async blob => {
                if (!draft) {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = 'Anexa 15 - Comunicari.docx';
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                }

                setUpdate(update + 1)
                setModalIsOpen(false)
                setLoadingGenerate(false)
                let proj = await getProjectInfo(params.id, intl)
                proj.offers.sort((a, b) => (a.total > b.total) ? 1 : ((b.total > a.total) ? -1 : 0))
                setProject(proj)
                setAppendix15(proj.documents.filter(doc => doc.type === 'appendix_15') ?? [])
            })
            .catch(e => console.log(e))
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">
            <div className={"header"}><p>Oferte</p></div>
            <div className={"content p-0"}>
                <div className={"table mb-1"}>
                    <div className={"table-head"} style={{minWidth: '900px'}}>
                        <div className={"row"}>
                            <div className={"col-2 table-td"}>Denumire</div>
                            <div className={"col-2 table-td"}>Status</div>
                            <div className={"col-2 table-td"}>Furnizor</div>
                            <div className={"col-1 table-td"}>Dată generare</div>
                            <div className={"col-1 table-td"}>Dată descărcare</div>
                            <div className={"col-4 table-td"}></div>
                        </div>
                    </div>
                    <div className={"table-body"} style={{minWidth: '900px'}}>
                        <div className={"table-row"} key={1}>
                            {project.offers.map((offer, i) => (
                                <div className={"row"} key={i}>
                                    <div
                                        className={"col-2 table-td"}>
                                        Anexa 15 - Comunicari
                                    </div>

                                    <div
                                        className={"col-2 table-td"}>
                                        <div className={"p-2 alert " + (appendix15.find((document) => document.company === offer.company.id) &&
                                        appendix15.find((document) => document.company === offer.company.id).status === 'generated' ? 'alert-success' : 'alert-danger')}>
                                            {appendix15.find((document) => document.company === offer.company.id)?
                                                appendix15.find((document) => document.company === offer.company.id).status === 'draft' ? 'Draft' : 'Generat' : 'Negenerat'}
                                        </div>
                                    </div>
                                    <div
                                        className={"col-2 table-td"}>
                                        {offer.company.title} (Locul: {i + 1}), Suma: {offer.total.toFixed(2)}
                                    </div>

                                    <div
                                        className={"col-1 table-td"}>
                                        {appendix15.find((document) => document.company === offer.company.id) &&
                                            moment(appendix15.find((document) => document.company === offer.company.id).created_at).format('DD/MM HH:mm')}
                                    </div>
                                    <div
                                        className={"col-1 table-td"}>
                                        {appendix15.find((document) => document.company === offer.company.id) && appendix15.find((document) => document.company === offer.company.id).downloaded_at &&
                                            moment(appendix15.find((document) => document.company === offer.company.id).downloaded_at).format('DD/MM HH:mm')}
                                    </div>
                                    <div className={"col-4 table-td"}>
                                        {appendix15.find((document) => document.company === offer.company.id) ?
                                            <>
                                                {
                                                    appendix15.find((document) => document.company === offer.company.id).status === 'draft' ?
                                                        <Button
                                                            className={"btn-light btn-edit ms-auto"}
                                                            icon={"fa-solid fa-pen"}
                                                            onClick={() => {
                                                                navigate('/acquisitions/projects/pnras/' + params.id + '/documents/' +
                                                                    appendix15.find((document) => document.company === offer.company.id).id)
                                                            }}
                                                        />
                                                        :
                                                        <Button
                                                            className={"btn-success ms-auto"}
                                                            value={"Descarcă"}
                                                            loading={loadingDownload && loadingDownload[i]}
                                                            onClick={() => {

                                                                let arr = {}
                                                                arr[i] = true
                                                                setLoadingDownload(arr)
                                                                downloadDoc('Anexa 15 - Comunicari' + offer.company.title,
                                                                    appendix15.find((document) => document.company === offer.company.id).id, setLoadingDownload, false)
                                                            }}
                                                        />
                                                }
                                                <Button
                                                    className={"btn-primary ms-2"}
                                                    value={"Regenerează"}
                                                    onClick={() => {
                                                        setCompany(offer.company.id)
                                                        setDocIdToDelete(appendix15.find((document) => document.company === offer.company.id).id)
                                                        if (templates.filter((item) => item.type === 'appendix_15').length === 1)
                                                            setTemplate(templates.filter((item) => item.type === 'appendix_15')[0].value)
                                                        setModalIsOpen(true)

                                                    }}
                                                />
                                            </>

                                            : <Button
                                                className={"btn-primary ms-auto"}
                                                value={"Generează"}
                                                onClick={(e) => {
                                                    setCompany(offer.company.id)
                                                    setDocIdToDelete(0)
                                                    if (templates.filter((item) => item.type === 'appendix_15').length === 1)
                                                        setTemplate(templates.filter((item) => item.type === 'appendix_15')[0].value)
                                                    setModalIsOpen(true)
                                                }}
                                            />}
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </div>


            <div className={"footer d-flex justify-content-center"}>

            </div>
            <ModalComp
                title={(docIdToDelete === 0 ? 'Generează' : 'Regenerează') + ' Anexa 15 - Comunicari'}
                isOpen={modalIsOpen}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalIsOpen(false)}
            >
                <form onSubmit={(e) => generateDoc(e)}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <div
                            className={"form-label"}>Șablon<span
                            className={"form-required"}>*</span></div>
                        <Selector
                            options={templates.filter((item) => item.type === 'appendix_15')}
                            value={template}
                            required={true}
                            onChange={(option) => setTemplate(option.value)}
                            isSearchable={false}
                            isOptionDisabled={(option) => option.disabled}
                        />

                        <div
                            className={"form-label mt-3 me-2"}>

                            <input type={"checkbox"} checked={draft} onChange={(e) => setDraft(!draft)}/>
                            Salvează ca draft
                        </div>
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={'Generează'}
                                    type={'submit'}
                                    disabled={loadingGenerate}
                                    loading={loadingGenerate}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
        </div>

    );
}

