import './Login.scss';
import React, {useState} from "react";
import Button from "../../../components/elements/Button/Button";
import axios from "axios";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

export default function Login() {

    const navigate = useNavigate()

    const [email, setEmail] = useState( '')
    const [password, setPassword] = useState('')

    const [loading, setLoading] = useState(false)

    const signIn = async () => {
        setLoading(true)

        let obj = {
            identifier: email,
            password: password
        }

        await axios.post(process.env.REACT_APP_API + '/auth/local', obj)
            .then(async (response) => {
                localStorage.setItem('jwt', response.data.jwt)
                localStorage.setItem('role', response.data.user.role.id + '')
                window.location.replace("/");
            })
            .catch(err => {
                toast.error("Datele de autentificare nu sunt corecte!")
            })

        setLoading(false)
    }

    return (
        <div className="login">
            <div className={"logo"}>
                <img src={"/images/logo.svg"} alt={""}/>
            </div>
            <div className={"container"}>

                <h1>Autentificare</h1>
                <div className={"form-label"}>Adresa de email</div>
                <input type={"email"}
                       className={"form-input"}
                       placeholder={'Adresa de email'}
                       value={email}
                       onChange={(e) => setEmail(e.target.value)}/>

                <div className={"form-label mt-3"}>Parola</div>
                <input type={"password"}
                       className={"form-input"}
                       placeholder={'Parola'}
                       value={password}
                       onChange={(e) => setPassword(e.target.value)}/>

                <Button
                    className={"btn-primary mt-4"}
                    value={'Autentificare'}
                    onClick={signIn}
                    loading={loading}
                />

                    <Button
                        className={"btn-primary mt-4"}
                        value={'Crează cont'}
                        onClick={() => navigate('/register')}
                    />
            </div>
        </div>
    );
}

