import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import Paginate from "../../../components/elements/Paginate/Paginate";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import Selector from "../../../components/elements/Selector/Selector";
import {toast} from "react-toastify";
import ModalComp from "../../../components/ModalComp/ModalComp";
import Checkbox from "../../../components/elements/Checkbox/Checkbox";

export default function Products() {
    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [products, setProducts] = useState([])
    const [search, setSearch] = useState(localStorage.getItem('search') ?? '')

    const [loading, setLoading] = useState(true)
    const [update, setUpdate] = useState(1)
    const [page, setPage] = useState(localStorage.getItem('page') ?? 1)
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState(localStorage.getItem('category') ? parseInt(localStorage.getItem('category')) : '')
    const [totalProducts, setTotalProducts] = useState(0)
    const [type, setType] = useState(localStorage.getItem('type') ?? 'products')
    const [projectType, setProjectType] = useState(localStorage.getItem('project_type') ?? '')
    const [productsSelected, setProductsSelected] = useState([])
    const [action, setAction] = useState('duplicate')
    const [showModalDuplicate, setShowModalDuplicate] = useState(false)
    const [duplicateLoading, setDuplicateLoading] = useState(false)
    const [duplicateInProjectType, setDuplicateInProjectType] = useState('pnras')
    const [duplicateInType, setDuplicateInType] = useState('products_smartlab')

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData()
        }, 600)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, page, type, category, projectType])

    const getData = async () => {
        axios.post(process.env.REACT_APP_API + '/products/list/' + page, {
            search,
            type,
            category,
            project_type: projectType
        }, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setProducts(response.data.products ?? [])
                setTotalProducts(response.data.total ?? 0)
                getCategories()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getCategories = async () => {
        axios.post(process.env.REACT_APP_API + '/product-categories/list/0', {search: ''}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                temp.push({
                    value: '',
                    label: 'Toate categoriile'
                })
                response.data.categories.forEach((item) => {
                    temp.push({
                        value: item.id,
                        label: item.title
                    })
                })

                setCategories(temp)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const deleteProduct = async (id) => {
        axios.delete(process.env.REACT_APP_API + '/products/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                } else {
                    toast.error("Acest produs nu poate fi șters!")
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const hideProduct = async (id, hided) => {
        axios.post(process.env.REACT_APP_API + '/products/hide/' + id, {hided}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    if (hided === true)
                        toast.success('Produsul a fost ascuns!')
                    else
                        toast.success('Produsul a devenit vizibil!')


                    getData()
                } else {
                    toast.error('S-a produs o eroare! Vă rugăm să reîncercați!')
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const duplicateProducts = async (e) => {
        e.preventDefault()
        setDuplicateLoading(true)
        axios.post(process.env.REACT_APP_API + '/products/duplicate', {
            ids: productsSelected.map(item => item.id),
            project_type: duplicateInProjectType,
            action,
            type: duplicateInType
        }, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                } else {
                    toast.error('S-a produs o eroare! Vă rugăm să reîncercați!')
                }
                setShowModalDuplicate(false)
                setDuplicateLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)

                setShowModalDuplicate(false)
                setDuplicateLoading(false)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">

            <div className={"header"}>
                <div className={"row"}>
                    <div className={"col-md-3 col-sm-12"}>
                        <div className={"search-input-container"}>
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <input type={"text"} className={"form-input"}
                                   placeholder={"Căutare"}
                                   value={search} onChange={(e) => {
                                localStorage.setItem('search', e.target.value)
                                setPage(1)
                                setSearch(e.target.value)
                            }}/>
                        </div>
                    </div>
                    <div className={"col-md-9 col-sm-12 float-right"}>
                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <Button
                                className={"btn-primary d-flex align-items-center ms-2"}
                                icon={"fa-solid fa-plus"}
                                value={"Adaugă"}
                                onClick={() => navigate('/products/items/add')}
                            />
                            {productsSelected && productsSelected.length > 0 && <Button
                                className={"btn-primary d-flex align-items-center ms-2"}
                                value={"Duplică"}
                                onClick={() => {
                                    if (productsSelected.length > 0) {
                                        setAction('duplicate')
                                        setShowModalDuplicate(true)
                                    }
                                }}
                            />}
                            {productsSelected && productsSelected.length > 0 && <Button
                                className={"btn-primary d-flex align-items-center ms-2"}
                                value={"Mută"}
                                onClick={() => {
                                    if (productsSelected.length > 0) {
                                        setAction('move')
                                        setShowModalDuplicate(true)
                                    }
                                }}
                            />}
                        </div>
                    </div>

                    <div className={"col-md-4 col-sm-12"}>
                        <div className={"search-input-container"}>
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <Selector
                                options={[{
                                    value: '',
                                    label: 'Toate tipurile de proiect'
                                }, {
                                    value: 'pnras',
                                    label: 'PNRAS'
                                }, {
                                    value: 'smartlab',
                                    label: 'SMARTLAB'
                                }, {
                                    value: 'smartlab_smis',
                                    label: 'SMARTLAB SMIS'
                                }]}
                                value={projectType}
                                onChange={(option) => {
                                    localStorage.setItem('project_type', option.value)
                                    setPage(1)
                                    setProjectType(option.value)
                                }}
                                isSearchable={false}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>
                    <div className={"col-md-4 col-sm-12"}>
                        <div className={"search-input-container"}>
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <Selector
                                options={[{
                                    value: '',
                                    label: 'Toate tipurile'
                                }, {
                                    value: 'services',
                                    label: intl.formatMessage({id: 'products.type.services'})
                                }, {
                                    value: 'events',
                                    label: intl.formatMessage({id: 'products.type.events'})
                                }, {
                                    value: 'products',
                                    label: intl.formatMessage({id: 'products.type.products'})
                                }, {
                                    value: 'other_services',
                                    label: intl.formatMessage({id: 'products.type.other_services'})
                                }, {
                                    value: 'teachers_training',
                                    label: intl.formatMessage({id: 'products.type.teachers_training'})
                                }, {
                                    value: 'furniture',
                                    label: intl.formatMessage({id: 'products.type.furniture'})
                                },{
                                    value: 'products_smartlab',
                                    label: intl.formatMessage({id: 'projects.type.products_smartlab'})
                                }, {
                                    value: 'educational',
                                    label: intl.formatMessage({id: 'projects.type.educational'})
                                }, {
                                    value: 'connect_devices',
                                    label: intl.formatMessage({id: 'projects.type.connect_devices'})
                                }, {
                                    value: 'teacher_training_sessions',
                                    label: intl.formatMessage({id: 'projects.type.teacher_training_sessions'})
                                }, {
                                    value: 'ergonomic_furniture',
                                    label: intl.formatMessage({id: 'projects.type.ergonomic_furniture'})
                                }]}
                                value={type}
                                onChange={(option) => {
                                    localStorage.setItem('type', option.value)
                                    setPage(1)
                                    setType(option.value)
                                }}
                                isSearchable={false}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>
                    <div className={"col-md-4 col-sm-12"}>
                        <div className={"search-input-container"}>
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <Selector
                                options={categories}
                                value={category}
                                onChange={(option) => {
                                    localStorage.setItem('category', option.value)
                                    setPage(1)
                                    setCategory(option.value)
                                }}
                                isSearchable={false}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {
                products.length === 0 ?
                    search === '' ?
                        <div className={"content"}>
                            <div className={"alert alert-warning"}>Momentan nu aveți nici un produs adaugat!</div>
                        </div>
                        :
                        <div className={"content"}>

                            <div className={"alert alert-warning"}>Nu au fost gasite produse!</div>
                        </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div className={"col-1 table-td"}>
                                        <Checkbox className={"me-2"}
                                                  value={productsSelected.length === products.length}
                                                  onChange={(e) => {
                                                      if (productsSelected.length !== products.length) {
                                                          setProductsSelected(products)
                                                      } else {
                                                          setProductsSelected([])
                                                      }
                                                  }
                                                  }
                                        />

                                        Nr
                                    </div>
                                    <div className={"col-3 table-td"}>Denumire</div>
                                    <div className={"col-1 table-td"}>Stoc</div>
                                    <div className={"col-2 table-td"}>Tip proiect</div>
                                    <div className={"col-2 table-td"}>Pret</div>
                                    <div className={"col-3 table-td"}></div>
                                </div>
                            </div>

                            <div className={"table-body"} key={productsSelected.length}>
                                {
                                    products.map((product, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-1 table-td"}>
                                                    <Checkbox
                                                        className={"me-2"}
                                                        itemKey={i+ '_'+productsSelected.length +'_'+ (productsSelected.find(item => item.id === product.id) ? 1: 0)}
                                                        value={productsSelected.find(item => item.id === product.id) ? true : false}
                                                        onChange={(e) => {
                                                            let temp = productsSelected;
                                                            if (productsSelected.find(item => item.id === product.id)) {
                                                                temp.splice(productsSelected.findIndex(item => item.id === product.id), 1)
                                                                setUpdate(update + 1)
                                                                setProductsSelected(temp)
                                                            } else {
                                                                setProductsSelected(productsSelected.concat([{...product}]))
                                                            }
                                                        }
                                                        }
                                                    />
                                                    {i + 1 + (page - 1) * 10}
                                                </div>
                                                <div
                                                    className={"col-3 table-td"}>
                                                    {product.title}
                                                </div>
                                                <div
                                                    className={"col-1 table-td"}>
                                                    {product.stock ?? 0}
                                                </div>
                                                <div
                                                    className={"col-2 table-td"}>
                                                    {product.project_type.toLocaleUpperCase()}
                                                </div>
                                                <div
                                                    className={"col-2 table-td"}>
                                                    {product.price_request} Lei
                                                </div>
                                                <div className={"col-3 table-td"}>
                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        icon={"fa-solid fa-pen"}
                                                        onClick={() => navigate('/products/items/' + product.id)}
                                                    />
                                                    <Button
                                                        className={"btn-light btn-edit"}
                                                        icon={"fa-solid fa-boxes-stacked"}
                                                        onClick={() => navigate('/products/items/stock/' + product.id)}
                                                    />
                                                    <Button
                                                        className={"btn-light btn-edit"}
                                                        style={{color: product.hided === true && 'lightgrey'}}
                                                        icon={"fa-solid fa-eye"}
                                                        onClick={() => hideProduct(product.id, product.hided ? !product.hided : true)}
                                                    />
                                                    <Button
                                                        className={"btn-light btn-delete"}
                                                        icon={"fa-solid fa-trash"}
                                                        onClick={() => deleteProduct(product.id)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }

            <div className={"footer d-flex justify-content-center"}>
                <Paginate
                    page={page}
                    setPage={(value) => {
                        setPage(value)
                        localStorage.setItem('page', value)
                    }}
                    totalItems={totalProducts}
                />
            </div>

            <ModalComp
                title={(action === 'duplicate' ? 'Duplică ' : 'Mută ') + 'echipamentele'}
                isOpen={showModalDuplicate}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setShowModalDuplicate(false)}
            >
                <form onSubmit={(e) => duplicateProducts(e)}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <p>Tipul de proiect</p>
                        <div className={"search-input-container"}>
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <Selector
                                options={[{
                                    value: 'pnras',
                                    label: 'PNRAS'
                                }, {
                                    value: 'smartlab',
                                    label: 'SMARTLAB'
                                }]}
                                value={duplicateInProjectType}
                                onChange={(option) => {
                                    setDuplicateInProjectType(option.value)
                                }}
                                isSearchable={false}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                        <p>Categorie</p>
                        <div className={"search-input-container"}>
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <Selector
                                options={[ {
                                    value: 'services',
                                    label: intl.formatMessage({id: 'products.type.services'})
                                }, {
                                    value: 'events',
                                    label: intl.formatMessage({id: 'products.type.events'})
                                }, {
                                    value: 'products',
                                    label: intl.formatMessage({id: 'products.type.products'})
                                }, {
                                    value: 'other_services',
                                    label: intl.formatMessage({id: 'products.type.other_services'})
                                }, {
                                    value: 'teachers_training',
                                    label: intl.formatMessage({id: 'products.type.teachers_training'})
                                }, {
                                    value: 'furniture',
                                    label: intl.formatMessage({id: 'products.type.furniture'})
                                },{
                                    value: 'products_smartlab',
                                    label: intl.formatMessage({id: 'projects.type.products_smartlab'})
                                }, {
                                    value: 'educational',
                                    label: intl.formatMessage({id: 'projects.type.educational'})
                                }, {
                                    value: 'connect_devices',
                                    label: intl.formatMessage({id: 'projects.type.connect_devices'})
                                }, {
                                    value: 'teacher_training_sessions',
                                    label: intl.formatMessage({id: 'projects.type.teacher_training_sessions'})
                                }, {
                                    value: 'ergonomic_furniture',
                                    label: intl.formatMessage({id: 'projects.type.ergonomic_furniture'})
                                }]}
                                value={duplicateInType}
                                onChange={(option) => {
                                    setDuplicateInType(option.value)
                                }}
                                isSearchable={false}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={(action === 'duplicate' ? 'Duplică ' : 'Mută ')}
                                    type={'submit'}
                                    disabled={duplicateLoading}
                                    loading={duplicateLoading}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
        </div>
    );
}

