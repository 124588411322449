import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import moment from "moment";
import Checkbox from "../../../components/elements/Checkbox/Checkbox";
import Button from "../../../components/elements/Button/Button";

export default function DownloadSimsDeclaration() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();

    const [documentsSelected, setDocumentsSelected] = useState([])
    const [products, setProducts] = useState([])

    const [loading, setLoading] = useState(false)
    const [downloadLoading, setDownloadLoading] = useState(false)
    const [downloadPdfLoading, setDownloadPdfLoading] = useState(false)


    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/smartlab-sims-declarations/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setProducts(response.data.products)
                    setLoading(false)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const download = async () => {
        setDownloadLoading(true)

        let payload = {
            documents: documentsSelected
        }
        await axios.post(process.env.REACT_APP_API + '/smartlab-sims-declarations/download', payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var url = response.data.file;
                var a = document.createElement('a');
                a.href = url;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
            })
            .catch(err => {
                errorParser(err, intl)
            })

        setDownloadLoading(false)
    }
    const downloadPDF = async () => {
        setDownloadPdfLoading(true)

        let payload = {
            documents: documentsSelected
        }
        await axios.post(process.env.REACT_APP_API + '/smartlab-sims-declarations/download-pdf', payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var url = response.data.file;
                var a = document.createElement('a');
                a.href = url;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
            })
            .catch(err => {
                errorParser(err, intl)
            })

        setDownloadPdfLoading(false)
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-6"}>
                    <h1 className={"container-title"}>Declarații SMIS</h1>
                </div>
                <div className={"col-6"}>
                    {documentsSelected.length > 0 && <Button
                        className={"btn-primary mt-2 ms-2"}
                        style={{float: 'right'}}
                        value={"Descarcă WORD"}
                        disabled={downloadLoading}
                        loading={downloadLoading}
                        onClick={() => {
                            download()
                        }}
                    />}
                    {documentsSelected.length > 0 && <Button
                        className={"btn-primary mt-2"}
                        style={{float: 'right'}}
                        value={"Descarcă PDF"}
                        disabled={downloadPdfLoading}
                        loading={downloadPdfLoading}
                        onClick={() => {
                            downloadPDF()
                        }}
                    />}
                </div>

                <div className={"col-12 pb-4"}>
                    {products && products.length > 0 && products.map((product, i) =>
                        <div className="card mb-3">


                            <div className={"header"}><p>{product.title}</p></div>


                            <div className={"content p-0"}>
                                <div className={"table mb-1"}>

                                    <div className={"table-head"} style={{minWidth: '900px'}}>
                                        <div className={"row"}>
                                            <div className={"col-1 table-td"}>
                                                <Checkbox className={"me-2"}
                                                          key={documentsSelected.length}
                                                          value={documentsSelected.find(item => item.product === product.id) ? documentsSelected.find(item => item.product === product.id).documents.length === product.documents.length : false}
                                                          onChange={(e) => {
                                                              let temp = [...documentsSelected]
                                                              let exist = temp.find(item => item.product === product.id)
                                                              if (exist) {
                                                                  let index = temp.indexOf(exist)
                                                                  temp.splice(index,1)
                                                              }else {
                                                                  temp.push({
                                                                      product: product.id,
                                                                      product_title: product.title,
                                                                      documents: product.documents.map(item => item.id)
                                                                  })
                                                              }
                                                              setDocumentsSelected(temp)
                                                          }}
                                                />
                                            </div>
                                            <div className={"col-5 table-td"}>Denumire</div>
                                            <div className={"col-3 table-td"}>Dată generare</div>
                                            <div className={"col-3 table-td"}>Dată descărcare</div>
                                        </div>
                                    </div>

                                    <div className={"table-body"} style={{minWidth: '900px'}}>
                                        <div className={"table-row"}>
                                            {product.documents && product.documents.map((document, i) => (
                                                <div className={"row"} key={i}>

                                                    <div className={"col-1 table-td"}>
                                                        <Checkbox className={"me-2"}
                                                                  value={documentsSelected.find(item => item.product === product.id) ? documentsSelected.find(item => item.product === product.id).documents.includes(document.id) : false}
                                                                  onChange={(e) => {
                                                                      let temp = [...documentsSelected]
                                                                      setDocumentsSelected([])
                                                                      let existProd = temp.find(item => item.product === product.id)
                                                                      if (existProd) {
                                                                          let indexProd = temp.indexOf(existProd)
                                                                          let exist = existProd.documents.includes(document.id)
                                                                          if (exist) {
                                                                              let index = existProd.documents.indexOf(document.id)
                                                                              temp[indexProd].documents.splice(index, 1)
                                                                          } else {
                                                                              temp[indexProd].documents.push(document.id)
                                                                          }

                                                                      } else {
                                                                          temp.push({
                                                                              product: product.id,
                                                                              product_title: product.title,
                                                                              documents: [document.id]
                                                                          })
                                                                      }
                                                                      setDocumentsSelected(temp)
                                                                  }
                                                                  }
                                                        />
                                                    </div>
                                                    <div
                                                        className={"col-5 table-td"}>
                                                        {intl.formatMessage({id: 'templates.type.' + document.type})}
                                                    </div>
                                                    <div
                                                        className={"col-3 table-td"}>
                                                        {moment(document.created_at).format('DD/MM HH:mm')}
                                                    </div>
                                                    <div
                                                        className={"col-3 table-td"}>
                                                        {document.downloaded_at && moment(document.downloaded_at).format('DD/MM HH:mm')}
                                                    </div>
                                                </div>))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"footer d-flex justify-content-center"}>

                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

