import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";

export default function ViewClient() {

    const intl = useIntl();
    const navigate = useNavigate()
    let params = useParams();
    const jwt = localStorage.getItem('jwt')

    const [client, setClient] = useState({})

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/clients/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setClient(response.data)
                    setLoading(false)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container" style={{position: "relative"}}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{client.company_title}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card">
                        <div className={"content"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <div className={"form-label"}>Nume reprezentant legal: {client.name}</div>
                                    <div className={"form-label"}>Adresa de email: {client.email}</div>
                                    <div className={"form-label"}>Telefon: {client.phone}</div>
                                    <div className={"form-label"}>Fax: {client.fax}</div>
                                    <div className={"form-label"}>CIF: {client.fiscal_code}</div>
                                    <div className={"form-label"}>Nr. înreg. Registrul Comerțului: {client.reg_com_number}</div>
                                    <div className={"form-label"}>Funcție reprezentant legal: {client.legal_representative_function}</div>
                                    <div className={"form-label"}>Cont bancar: {client.bank_account}</div>
                                    <div className={"form-label"}>Bancă: {client.bank}</div>
                                    <div className={"form-label"}>Județ: {client.county}</div>
                                    <div className={"form-label"}>Oraș: {client.city}</div>
                                    <div className={"form-label"}>Adresa: {client.address}</div>
                                    <div className={"form-label"}>Denumirea proiectului: {client.projectTitle}</div>
                                    <div className={"form-label"}>Numărul contractului de finanțare: {client.number_financing_contract}</div>
                                    <div className={"form-label"}>Data contractului de finanțare: {client.dateFinancingContract}</div>
                                </div>
                            </div>
                        </div>

                        <div className={"footer"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <Button
                                        className={"btn-light mt-2"}
                                        icon={"fa-solid fa-chevron-left"}
                                        value={"Înapoi"}
                                        onClick={() => navigate(-1)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    );
}

