import React, {useState} from "react";
import {useIntl} from "react-intl";
import Button from "../../../components/elements/Button/Button";
import axios from "axios";
import {toast} from "react-toastify";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";

export default function Register() {

    const intl = useIntl();
    const navigate = useNavigate()

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [phone, setPhone] = useState('')
    const [fax, setFax] = useState('')

    const [loading, setLoading] = useState(false)

    const register = async (e) => {
        e.preventDefault();
        setLoading(true)
        if (!password || !repeatPassword || password !== repeatPassword) {
            toast.error("Cele 2 parole introduse nu corespund!")
            setLoading(false)
            return
        }
        let obj = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
            phone:phone,
            fax:fax
        }

        await axios.post(process.env.REACT_APP_API + '/auth/register', obj)
            .then(async (response) => {
                if (response.status) {
                    navigate('/')
                }
            })
            .catch(err => {
                errorParser(err,intl)
            })

        setLoading(false)
    }
    return (
        <div className="login">

            <div className={"logo"}>
                <img src={"/images/logo.svg"} alt={""}/>
            </div>
            <form onSubmit={(e) => register(e)}>
                <div className={"container"}>

                    <h1>Crează cont</h1>
                    <div className={"form-control"}>
                        <div
                            className={"form-label"}>Prenume<span
                            className={"form-required"}>*</span></div>
                        <input type={"text"} className={"form-input"} required
                               value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                    </div>
                    <div className={"form-control"}>
                        <div className={"form-label"}>Nume<span
                            className={"form-required"}>*</span></div>
                        <input type={"text"} className={"form-input"} required
                               value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                    </div>
                    <div className={"form-control"}>
                        <div className={"form-label"}>Adresa de email<span
                            className={"form-required"}>*</span></div>
                        <input type={"email"} className={"form-input"} required
                               value={email} onChange={(e) => {
                            setEmail(e.target.value)
                        }}/>
                    </div>

                    <div className={"form-control"}>
                        <div
                            className={"form-label"}>Introduceți noua parolă<span
                            className={"form-required"}>*</span></div>
                        <input type={"password"} className={"form-input"} required
                               value={password} onChange={(e) => setPassword(e.target.value)}/>
                    </div>
                    <div className={"form-control"}>
                        <div
                            className={"form-label"}>Reintroduceți noua parolă<span
                            className={"form-required"}>*</span></div>
                        <input type={"password"} className={"form-input"}
                               value={repeatPassword} required
                               onChange={(e) => setRepeatPassword(e.target.value)}/>
                    </div>

                    <div className={"form-control"}>
                        <div className={"form-label"}>Telefon</div>
                        <input type={"text"} className={"form-input"}
                               value={phone} onChange={(e) => {
                            setPhone(e.target.value)
                        }}/>
                    </div>
                    <div className={"form-control"}>
                        <div className={"form-label"}>Fax</div>
                        <input type={"text"} className={"form-input"}
                               value={fax} onChange={(e) => {
                            setFax(e.target.value)
                        }}/>
                    </div>
                    <Button
                        className={"btn-primary mt-4"}
                        value={'Crează cont'}
                        type={'submit'}
                        loading={loading}
                    />
                    <a href={"/"} className={"mt-3"} style={{textAlign: 'center'}}>
                        Autentificare
                    </a>
                </div>
            </form>
        </div>
    );
}

