import Button from "../../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../../../../components/ErrorParser/ErrorParser";
import Selector from "../../../../../../../components/elements/Selector/Selector";
import LineItems from "../../../../../../../components/elements/LineItems/LineItems";
import {components} from "react-select";
import ModalComp from "../../../../../../../components/ModalComp/ModalComp";
import OtherRequirementsItemDetails from "./OtherRequirementsItemDetails";

export default function DetailsType2({
                                         update,
                                         setUpdate,
                                         title,
                                         setTitle,
                                         motivation,
                                         setMotivation,
                                         projectCode,
                                         setProjectCode,
                                         client,
                                         setClient,
                                         clientName,
                                         projectTitle,
                                         setProjectTitle,
                                         numberFinancingContract,
                                         setNumberFinancingContract,
                                         dateFinancingContract,
                                         setDateFinancingContract,
                                         deliveryTime,
                                         setDeliveryTime,
                                         submissionDeadline,
                                         setSubmissionDeadline,
                                         providers,
                                         setProviders,
                                         clients,
                                         categories,
                                         category,
                                         setCategory,
                                         companies,
                                         isInvGenerated,
                                         products,
                                         setProducts,
                                         modalIsOpen,
                                         setModalIsOpen,
                                         modalEditIsOpen,
                                         setModalEditIsOpen,
                                         project,
                                         product,
                                         setProduct,
                                         daysClarification,
                                         setDaysClarification,
                                         validityOffer,
                                         setValidityOffer,
                                         addTo,
                                         setAddTo,
                                         requirementsCommon,
                                         setRequirementsCommon,
                                         requirementsDifferentiated,
                                         setRequirementsDifferentiated,
                                         totalValueCommonTrunk,
                                         setTotalValueCommonTrunk,
                                         totalValueDifferentiatedTrunk,
                                         setTotalValueDifferentiatedTrunk,
                                         getData,
                                         otherRequirements,
                                         setOtherRequirements,
                                         projectType,
                                         type
                                     }) {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();

    const [saveLoading, setSaveLoading] = useState(false)
    const [updateProducts, setUpdateProducts] = useState(0)
    const [downloadExcel, setDownloadExcel] = useState(false)

    const save = async (e) => {
        e.preventDefault();
        if (client === '') {
            toast.error('Nu este selectată intituția!')
            return

        }
        let totalOther = 0
        if (otherRequirements)
            for (let i = 0; i < otherRequirements.length; i++) {
                totalOther += parseFloat(otherRequirements[i].totalValue)
            }

        setSaveLoading(true)
        let payload = {
            title: title,
            client: client,
            motivation: motivation,
            project_code: projectCode,
            companies: providers,
            download_excel: downloadExcel,
            data: JSON.stringify({
                projectTitle: projectTitle,
                numberFinancingContract: numberFinancingContract,
                dateFinancingContract: dateFinancingContract,
                submissionDeadline: submissionDeadline,
                deliveryTime: deliveryTime,
                requirements_common: requirementsCommon,
                requirements_differentiated: requirementsDifferentiated,
                totalValueCommon: totalValueCommonTrunk,
                totalValueDifferentiated: totalValueDifferentiatedTrunk,
                daysClarification,
                validityOffer,
                otherRequirements,
                totalOther
            })
        }

        await axios.put(process.env.REACT_APP_API + '/projects/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (downloadExcel && response.data && response.data.link){
                    var a = document.createElement('a');
                    a.href = response.data.link;
                    a.download = "Configurator-" + client + ".xlsx";

                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                }
                setTimeout(() => {
                    toast.success("Informațiile au fost salvate cu succes!")
                    setUpdate(update + 1)
                    getData()
                }, 1000)
            })
            .catch(err => {
                errorParser(err, intl)
            })

        setSaveLoading(false)
    }

    const SingleValue = props => (
        <components.SingleValue  {...props}>
            <span>Selectează</span>
        </components.SingleValue>
    );
    return (
        <div className="card">
            <div className={"header"}><p>Completare detalii achiziție</p></div>
            <form onSubmit={(e) => {
                if (project.documents.length > 0) {
                    e.preventDefault()
                    setModalEditIsOpen(true)
                } else
                    save(e)
            }}>
                <div className={"content"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>Denumire achiziție<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required
                                       disabled={isInvGenerated}
                                       value={title} onChange={(e) => setTitle(e.target.value)}/>
                            </div>
                        </div>
                        {client === null && <div className={"col-12 col-md-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>Denumire client
                                </div>
                                <input type={"text"} className={"form-input"} disabled
                                       value={clientName}/>
                            </div>
                        </div>}
                        <div className={"col-12 col-md-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>Instituție<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={clients}
                                    value={client}
                                    required={true}
                                    disabled={client ? true : false}
                                    onChange={(option) => {
                                        setClient(option.value)
                                        setProjectTitle(option.projectTitle)
                                        setNumberFinancingContract(option.number_financing_contract)
                                        setDateFinancingContract(option.dateFinancingContract)
                                    }}
                                    isSearchable={false}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                        <div className={"col-12 col-md-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>Termen de livrare (zile)
                                </div>
                                <input type={"number"} step={1} className={"form-input"}
                                       disabled={isInvGenerated}
                                       onFocus={(event) => event.target.select()}
                                       value={deliveryTime}
                                       onChange={(e) => setDeliveryTime(e.target.value)}/>
                            </div>
                        </div>

                        <div className={"col-12 col-md-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>Termen depunere oferte
                                </div>
                                <input type={"date"} step={1} className={"form-input"}
                                       disabled={isInvGenerated}
                                       value={submissionDeadline}
                                       onChange={(e) => setSubmissionDeadline(e.target.value)}/>
                            </div>
                        </div>

                        <div className={"col-12 col-md-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>Cod proiect
                                </div>
                                <input type={"text"} className={"form-input"}
                                       disabled={isInvGenerated}
                                       value={projectCode}
                                       onChange={(e) => setProjectCode(e.target.value)}/>
                            </div>
                        </div>

                        <div className={"col-12 col-md-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>Numărul de zile înainte de data limită
                                    de depunere a clarificărilor<span
                                        className={"form-required"}>*</span></div>
                                <input type={"number"} step={1} className={"form-input"} required
                                       disabled={isInvGenerated}
                                       onFocus={(event) => event.target.select()}
                                       value={daysClarification}
                                       onChange={(e) => setDaysClarification(e.target.value)}/>
                            </div>
                        </div>

                        <div className={"col-12 col-md-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>Numărul de zile pentru care ofertantul
                                    trebuie să mențină valabilitatea ofertei<span
                                        className={"form-required"}>*</span></div>
                                <input type={"number"} step={1} className={"form-input"} required
                                       disabled={isInvGenerated}
                                       value={validityOffer}
                                       onChange={(e) => setValidityOffer(e.target.value)}/>
                            </div>
                        </div>
                        <strong className={"mb-1 mt-3"}>Trunchi comun</strong>

                        <LineItems
                            items={requirementsCommon}
                            setItems={setRequirementsCommon}
                            setTotalValue={setTotalValueCommonTrunk}
                            totalValue={totalValueCommonTrunk}
                            isInvGenerated={isInvGenerated}
                        />

                        <div className={"d-flex pb-2 mt-2"}>
                            <Button
                                className={"btn-secondary"}
                                size={"small"}
                                value={"Adaugă produs personalizat"}
                                style={{cursor: 'pointer'}} onClick={() => {
                                let temp = requirementsCommon
                                temp.push({
                                    price: 0,
                                    price_request: 0,
                                    id: null,
                                    value: null,
                                    label: '',
                                    product: null,
                                    guarantee: 36,
                                    qty: 1
                                })
                                setUpdateProducts(updateProducts+1)
                                setRequirementsCommon(temp)
                            }}>

                            </Button>

                        </div>
                        {
                            requirementsCommon.length > 0 &&
                            <strong className={"mb-4"}>Valoarea totală trunchi
                                comun: {parseFloat(totalValueCommonTrunk).toLocaleString('ro-RO', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })} RON</strong>
                        }

                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>Adaugă element
                                </div>

                                <Selector
                                    options={categories}
                                    value={category} disabled={isInvGenerated}
                                    onChange={(option) => {
                                        setAddTo('common')
                                        let temp = []
                                        option.products.forEach((prod) => {
                                            if (prod.type === type && prod.project_type === projectType)
                                                temp.push({
                                                    price: prod.price_request,
                                                    price_request: prod.price_request,
                                                    id: prod.id,
                                                    value: prod.id,
                                                    label: prod.title,
                                                    product: prod.id,
                                                    guarantee: prod.guarantee_request,
                                                    qty: 1
                                                })
                                        })
                                        setProducts(temp)
                                        setCategory(option)
                                        setModalIsOpen(true)
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                    components={{SingleValue}}
                                />
                            </div>
                        </div>

                        <strong className={"mb-1 mt-3"}>Trunchi opțional</strong>
                        <LineItems
                            items={requirementsDifferentiated}
                            setItems={setRequirementsDifferentiated}
                            setTotalValue={setTotalValueDifferentiatedTrunk}
                            totalValue={totalValueDifferentiatedTrunk}
                            isInvGenerated={isInvGenerated}
                        />

                        <div className={"d-flex pb-2 mt-2"}>
                            <Button
                                className={"btn-secondary"}
                                size={"small"}
                                value={"Adaugă produs personalizat"}
                                style={{cursor: 'pointer'}} onClick={() => {
                                let temp = requirementsDifferentiated
                                temp.push({
                                    price: 0,
                                    price_request: 0,
                                    id: null,
                                    value: null,
                                    label: '',
                                    product: null,
                                    guarantee: 36,
                                    qty: 1
                                })
                                setUpdateProducts(updateProducts+1)
                                setRequirementsDifferentiated(temp)
                            }}>

                            </Button>

                        </div>
                        {
                            requirementsDifferentiated.length > 0 &&
                            <strong className={"mb-4"}>Valoarea totală trunchi
                                opțional: {parseFloat(totalValueDifferentiatedTrunk).toLocaleString('ro-RO', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })} RON</strong>
                        }

                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>Adaugă element
                                </div>
                                <Selector
                                    options={categories}
                                    value={category} disabled={isInvGenerated}
                                    onChange={(option) => {
                                        setAddTo('diff')
                                        let temp = []
                                        option.products.forEach((prod) => {
                                            if (prod.type === type && prod.project_type === projectType)
                                            temp.push({
                                                price: prod.price_request,
                                                price_request: prod.price_request,
                                                id: prod.id,
                                                value: prod.id,
                                                label: prod.title,
                                                product: prod.id,
                                                guarantee: prod.guarantee_request,
                                                qty: 1
                                            })
                                        })
                                        setProducts(temp)
                                        setCategory(option)
                                        setModalIsOpen(true)
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                    components={{SingleValue}}
                                />
                            </div>
                        </div>


                        {
                            otherRequirements && otherRequirements.map((itemOther, index) =>
                                <OtherRequirementsItemDetails key={index} setItem={(value) => {
                                    let temp = otherRequirements
                                    temp[index] = value;
                                    setOtherRequirements(temp)
                                }} projectType={projectType} type={type}
                                                              item={itemOther} categories={categories} SingleValue={SingleValue}
                                                              setModalIsOpen={setModalIsOpen}
                                                              category={category}
                                                              setCategory={setCategory}
                                                              setProducts={setProducts}
                                                              setAddTo={setAddTo}
                                                              isInvGenerated={isInvGenerated}/>
                            )
                        }
                        <Button
                            className={"btn-secondary mt-2 ms-auto"}
                            value={"Adaugă categorie"}
                            onClick={() => {
                                setOtherRequirements([...otherRequirements, {
                                    title: '',
                                    requirements: [],
                                    totalValue: 0
                                }])
                            }}
                            type={'button'}
                        />

                        <div className={"col-12 mt-4"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>Furnizori
                                </div>
                                <Selector
                                    options={companies}
                                    value={providers} disabled={isInvGenerated}
                                    onChange={(option) => {
                                        setProviders(Array.from(option, option => option.value))
                                    }}
                                    isSearchable={true}
                                    isMulti={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>

                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            {
                                !isInvGenerated &&
                                <Button
                                    className={"btn-secondary mt-2 ms-auto"}
                                    icon={"fa-solid fa-plus"}
                                    value={'Salvează'}
                                    disabled={saveLoading}
                                    loading={saveLoading}
                                    type={'submit'}
                                    onClick={() => setDownloadExcel(false)}
                                />
                            }
                            {
                                !isInvGenerated &&
                                <Button
                                    className={"btn-secondary mt-2 ms-2"}
                                    icon={"fa-solid fa-plus"}
                                    value={'Generează și salvează'}
                                    disabled={saveLoading}
                                    loading={saveLoading}
                                    type={'submit'}
                                    onClick={() => setDownloadExcel(true)}
                                />
                            }
                        </div>
                    </div>
                </div>
            </form>
            <ModalComp
                title={'Adaugă element'}
                isOpen={modalIsOpen}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalIsOpen(false)}
            >
                <form onSubmit={(e) => {
                    e.preventDefault()
                    setProducts([])
                    if (addTo === 'common') {
                        setRequirementsCommon(requirementsCommon.concat([{
                            "price": product.price,
                            "price_request": category.price,
                            "id": category.id,
                            "value": category.id,
                            "label": category.label,
                            "product": product.value,
                            "guarantee": product.guarantee,
                            "qty": 1
                        },]))
                        setTotalValueCommonTrunk(parseFloat(totalValueCommonTrunk + product.price))
                    } else {
                        setRequirementsDifferentiated(requirementsDifferentiated.concat([{
                            "price": product.price,
                            "price_request": category.price,
                            "id": category.id,
                            "value": category.id,
                            "label": category.label,
                            "product": product.value,
                            "guarantee": product.guarantee,
                            "qty": 1
                        },]))
                        setTotalValueDifferentiatedTrunk(parseFloat(totalValueDifferentiatedTrunk + product.price))
                    }
                    setCategory('')
                    setModalIsOpen(false)
                }}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <div
                            className={"form-label"}>Produs
                        </div>
                        <Selector
                            options={products}
                            value={product}
                            required={true}
                            onChange={(option) => setProduct(option)}
                            isSearchable={false}
                            isOptionDisabled={(option) => option.disabled}
                        />
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={'Salvează'}
                                    type={'submit'}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>


            <ModalComp
                title={'Editare detalii achiziție'}
                isOpen={modalEditIsOpen}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalEditIsOpen(false)}
            >
                <form onSubmit={(e) => save(e)}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <p>Dacă vei modifica detaliile achiziției vor se vor șterge toate
                            documentele generate!</p>
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={'Salveză modificările'}
                                    type={'submit'}
                                    disabled={saveLoading}
                                    loading={saveLoading}
                                />

                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
        </div>
    );
}

