import { toast } from 'react-toastify';

export default function errorParser(data, intl) {

    switch (data.response.status) {
        case 401:
            localStorage.clear();
            window.location.replace("/");
            break
        case 403:
            toast.error("Nu aveți acces în această secțiune")
            break
        case 400:
        case 404:
        default:
            toast.error('S-a produs o eroare! Vă rugăm să reîncercați!')
    }

}
